export interface CallbackResult {
  data?: UniloginAuthMessageData;
  error?: string;
}

export interface UniloginAuthMessageData {
  state?: string;
  code?: string;
  session_state?: string;
  error?: string;
}

export function getUniLoginLink() {
  const hostname = window.location.hostname;

  switch (hostname) {
    case "localhost":
      return "https://sli.emu.dk/unilogin/login.cgi?id=ssoknowlgeid&path=aHR0cDovL2xvY2FsaG9zdDozMDAwL3VuaWxvZ2luLXJlc3VsdA%3D%3D&auth=b50bd94c3264d5c11c7fbc857717f2e0";

    case "app.maneno.co.uk":
      return "https://sli.emu.dk/unilogin/login.cgi?id=ssoknowlgeid&path=aHR0cHM6Ly9hcHAubWFuZW5vLmNvLnVrL3VuaWxvZ2luLXJlc3VsdA%3D%3D&auth=2303a3c9ad5253561db219c668bcf57b";

    case "app.maneno.dk":
      return "https://sli.emu.dk/unilogin/login.cgi?id=ssoknowlgeid&path=aHR0cHM6Ly9hcHAubWFuZW5vLmRrL3VuaWxvZ2luLXJlc3VsdA%3D%3D&auth=d8792f517f669b11e52a275dc6e1953a";

    case "maneno-dev.azurewebsites.net":
      return "https://sli.emu.dk/unilogin/login.cgi?id=ssoknowlgeid&path=aHR0cHM6Ly9tYW5lbm8tZGV2LmF6dXJld2Vic2l0ZXMubmV0L3VuaWxvZ2luLXJlc3VsdA%3D%3D&auth=db0e94b98e5b3a257a6fa1ffc65816a4";

    default:
      // Default case if none of the known hosts match
      console.error("Unknown host:", hostname);
      return ""; // Return an empty string or a default URL if you have one
  }
}

export const openLoginPopup = async () => {
  // Open a blank popup window first, synchronously
  const popupWidth = 600;
  const popupHeight = 700;
  const windowLeft = window.screenLeft || window.screenX;
  const windowTop = window.screenTop || window.screenY;
  const windowWidth = window.innerWidth || document.documentElement.clientWidth || screen.width;
  const windowHeight = window.innerHeight || document.documentElement.clientHeight || screen.height;
  const popupLeft = windowLeft + (windowWidth - popupWidth) / 2;
  const popupTop = windowTop + (windowHeight - popupHeight) / 2;

  const authWindow = window.open(
    "",
    "_blank",
    `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, resizable=yes, copyhistory=no, width=${popupWidth}, height=${popupHeight}, top=${popupTop}, left=${popupLeft}`
  );

  if (!authWindow) {
    alert("Popup is blocked");
    return;
  }

  try {
    // Define the URL
    const uniloginLinkFetcherUrl = "https://api.maneno.dk/unilogin?fromWeb=true";

    // Fetch the actual URL
    const response = await fetch(uniloginLinkFetcherUrl);
    const data = await response.json();

    const link = data;

    // Navigate the popup to the fetched URL
    authWindow.location.href = link;

    // Optionally, save some data to localStorage if needed
    localStorage.setItem("uniloginInitiatorOrigin", window.location.origin);

    return authWindow;
  } catch (error) {
    console.error("Error:", error);
    authWindow.close(); // Close the popup if there's an error
  }
};

export const monitorPopup = (authWindow: Window | null, callback: (result: CallbackResult) => void) => {
  const messageListener = (event: MessageEvent<UniloginAuthMessageData>) => {
    if (event.data.state) {
      callback({ data: event.data });
    } else if (event.data.error) {
      console.error(event.data.error);
      callback({ error: event.data.error });
    }

    window.removeEventListener("message", messageListener);
    if (authWindow) {
      authWindow.close();
    }
  };

  window.addEventListener("message", messageListener);

  const checkInterval = setInterval(() => {
    if (!authWindow || authWindow.closed) {
      clearInterval(checkInterval);
      window.removeEventListener("message", messageListener);
      callback({ error: "Popup closed by user" });
    }
  }, 100);
};
