export const BOOK_ITEMS_LIMIT = 18;

export const TAG_TYPE_CATEGORY = 1;
export const TAG_TYPE_LENGTH = 4;
export const TAG_TYPE_SERIES = 7;
export const TAG_TYPE_AUTHOR = 17;

export const AGE_FILTER_OPTIONS = [
  {
    ageFrom: 2,
    ageTo: 4
  },
  {
    ageFrom: 5,
    ageTo: 7
  },
  {
    ageFrom: 8,
    ageTo: 10
  },
  {
    ageFrom: 11,
    ageTo: 13
  }
];

export const LEADER_BOARD_OPTIONS = {
  all: 1,
  school: 2,
  class: 3,
  age: 4,
  city: 5,
  friends: 6
};

export const LEADERBOARD_USERS_LIMIT = 25;

// 0 - All levels,
// 1 - Easy and Appropriate levels
export const SIMPLIFIED_READING_LEVEL_FILTTER = 1;

export const MAX_DRAGON_LEVEL = 10;
