import { initializeApp } from "firebase/app";
import "firebase/analytics";
import { getAnalytics, setUserId } from "@firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyBo1Y6zpop3xawO1HAPDXw_KYQbHnoefRQ",
  authDomain: "maneno-b34cf.firebaseapp.com",
  databaseURL: "https://maneno-b34cf.firebaseio.com",
  projectId: "maneno-b34cf",
  storageBucket: "maneno-b34cf.appspot.com",
  messagingSenderId: "143844353821",
  appId: "1:143844353821:web:4da54d24a2026d22f2e3f4",
  measurementId: "G-GW1DZEF6ZY"
};

const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

const setAnalyticsUserId = (userId: string | null) => {
  if (userId) {
    setUserId(analytics, userId, { global: true });
  }
};

export { analytics, setAnalyticsUserId };
