import { requestTypeCreator } from "src/helpers/index";
import {
  LoginRequestTypes,
  LoginResponseTypes,
  UniloginRequestTypes,
  MasqueradeRequestTypes,
  NonceRequestTypes,
  ResetPasswordRequestTypes,
  SSORequestTypes,
  NotaSignupNewParentParams,
  GetNonceTokenRequestTypes,
  NotaSignupExistingParentParams,
  OtpCodeRequestTypes
} from "./types";

export const LOGIN = requestTypeCreator("LOGIN");
export const UNILOGIN = requestTypeCreator("UNILOGIN");
export const MASQUERADE = requestTypeCreator("MASQUERADE");
export const SSO = requestTypeCreator("SSO");
export const NONCE = requestTypeCreator("NONCE");
export const RESET_PASSWORD = requestTypeCreator("RESET_PASSWORD");
export const OTP_CODE = requestTypeCreator("OTP_CODE");
export const CREATE_NOTA_USER_WITH_NEW_PARENT = requestTypeCreator("CREATE_NOTA_USER_WITH_NEW_PARENT");
export const CREATE_NOTA_USER_WITH_EXISTING_PARENT = requestTypeCreator("CREATE_NOTA_USER_WITH_EXISTING_PARENT");
export const GET_NONCE_TOKEN = requestTypeCreator("GET_NONCE_TOKEN");

export const loginActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: LOGIN.ERROR
  }),
  request: (payload: LoginRequestTypes): IActionType<LoginRequestTypes> => ({
    payload,
    type: LOGIN.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: LOGIN.SUCCESS
  })
};

export const uniloginAuthActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: UNILOGIN.ERROR
  }),
  request: (payload: UniloginRequestTypes): IActionType<UniloginRequestTypes> => ({
    payload,
    type: UNILOGIN.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: UNILOGIN.SUCCESS
  })
};

export const masqueradeAuthActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: MASQUERADE.ERROR
  }),
  request: (payload: MasqueradeRequestTypes): IActionType<MasqueradeRequestTypes> => ({
    payload,
    type: MASQUERADE.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: MASQUERADE.SUCCESS
  })
};

export const resetPasswordActions = {
  error: (payload: IErrors): IActionType<IErrors> => ({
    payload,
    type: RESET_PASSWORD.ERROR
  }),
  request: (payload: ResetPasswordRequestTypes): IActionType<ResetPasswordRequestTypes> => ({
    payload,
    type: RESET_PASSWORD.REQUEST
  }),
  success: () => ({
    type: RESET_PASSWORD.SUCCESS
  })
};

export const authUsingOtpActions = {
  error: (payload: IErrors): IActionType<IErrors> => ({
    payload,
    type: OTP_CODE.ERROR
  }),
  request: (payload: OtpCodeRequestTypes): IActionType<OtpCodeRequestTypes> => ({
    payload,
    type: OTP_CODE.REQUEST
  }),
  success: () => ({
    type: OTP_CODE.SUCCESS
  })
};

export const ssoActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: SSO.ERROR
  }),
  request: (payload: SSORequestTypes): IActionType<SSORequestTypes> => ({
    payload,
    type: SSO.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: SSO.SUCCESS
  })
};

export const nonceActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: NONCE.ERROR
  }),
  request: (payload: NonceRequestTypes): IActionType<NonceRequestTypes> => ({
    payload,
    type: NONCE.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: NONCE.SUCCESS
  })
};

export const notaSignupWithNewParentActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_NEW_PARENT.ERROR
  }),
  request: (payload: NotaSignupNewParentParams): IActionType<NotaSignupNewParentParams> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_NEW_PARENT.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_NEW_PARENT.SUCCESS
  })
};

export const notaSignupWithExistingParentActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_EXISTING_PARENT.ERROR
  }),
  request: (payload: NotaSignupExistingParentParams): IActionType<NotaSignupExistingParentParams> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_EXISTING_PARENT.REQUEST
  }),
  success: (payload: LoginResponseTypes): IActionType<LoginResponseTypes> => ({
    payload,
    type: CREATE_NOTA_USER_WITH_EXISTING_PARENT.SUCCESS
  })
};

export const getNonceTokenActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: GET_NONCE_TOKEN.ERROR
  }),
  request: (payload: GetNonceTokenRequestTypes): IActionType<GetNonceTokenRequestTypes> => ({
    payload,
    type: GET_NONCE_TOKEN.REQUEST
  }),
  success: () => ({
    type: GET_NONCE_TOKEN.SUCCESS
  })
};
