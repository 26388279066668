import React from "react";
import { IconPropsType } from "./IconTypes";

const LineHeightIcon = ({ color = "#01324B", width = 25, height = 26 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.5344 6.06667L15.6677 8.91111C16.1072 9.4971 15.6891 10.3333 14.9566 10.3333C14.4657 10.3333 14.0677 10.7313 14.0677 11.2222V14.7778C14.0677 15.2687 14.4657 15.6667 14.9566 15.6667C15.6891 15.6667 16.1072 16.5029 15.6677 17.0889L13.5344 19.9333C13.1344 20.4667 12.3344 20.4667 11.9344 19.9333L9.80104 17.0889C9.36155 16.5029 9.77967 15.6667 10.5122 15.6667C11.0031 15.6667 11.401 15.2687 11.401 14.7778V11.2222C11.401 10.7313 11.0031 10.3333 10.5122 10.3333C9.77967 10.3333 9.36155 9.4971 9.80104 8.91111L11.9344 6.06667C12.3344 5.53333 13.1344 5.53333 13.5344 6.06667Z"
        fill={color}
      />
      <rect x="2.73438" y="1" width="20" height="2" rx="1" fill={color} />
      <rect x="2.73438" y="23" width="20" height="2" rx="1" fill={color} />
    </svg>
  );
};

export default LineHeightIcon;
