import styled from "styled-components";

export const StyledButton = styled.span`
  align-items: center;
  appearance: none;
  background: ${({ theme }) => theme.bg.blueLight};
  border: none;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.fontFamilyBase};
  font-size: 13px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightSemiBold};
  padding: 9px 15px;
  width: 201px;
  margin-bottom: 20px;

  &.pages {
    .icon-wrapper {
      margin-right: 10px;
    }

    .icon-wrapper img {
      width: 40px;
    }
  }

  &.remaining {
    .icon-wrapper {
      margin-bottom: -20px;
      margin-right: 6px;
    }

    .icon-wrapper img {
      width: 59px;
    }

    .btn-text {
      width: min-content;
    }
  }

  &.finish {
    .icon-wrapper {
      margin: -18px 24px -28px -11px;
    }

    .icon-wrapper img {
      width: 83px;
    }
  }

  .icon-wrapper {
    position: relative;
  }

  .icon-wrapper img {
    max-width: fit-content;
  }

  .count {
    position: absolute;
    right: 3px;
    top: -1px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${({ theme }) => theme.bg.white};
    border-radius: 50%;
    color: #d75a1a;
    height: 22px;
    min-width: 22px;
  }

  .btn-text {
    text-align: left;
  }
`;
