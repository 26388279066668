import { take, put } from "redux-saga/effects";
import { OTP_CODE, authUsingOtpActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { authUsingOtp } from "src/services/user";

// types
import { OtpCodeRequestTypes, LoginResponseTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchAuthUsingOtpRequest() {
  while (true) {
    const { payload }: IActionType<OtpCodeRequestTypes> = yield take(OTP_CODE.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as OtpCodeRequestTypes;

    try {
      const result: LoginResponseTypes = yield call(authUsingOtp, { ...restPayload, deviceId: uuidv4().toUpperCase() });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(authUsingOtpActions.success());
      onSuccess(result);
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(authUsingOtpActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchAuthUsingOtpRequest;
