import React from "react";
import { IconPropsType } from "./IconTypes";

const FontIcon = ({ color = "#9AB3C2", width = 21, height = 23 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 21 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.6381 17.6115H6.65238C6.39094 18.4797 6.10574 19.3832 5.79676 20.3219C5.51156 21.2605 5.27389 22.0349 5.08375 22.6451C4.84608 22.7389 4.58464 22.8211 4.29944 22.8915C4.038 22.9619 3.70526 22.9971 3.30122 22.9971C2.49314 22.9971 1.86331 22.8445 1.41174 22.5395C0.960162 22.2109 0.734375 21.7533 0.734375 21.1667C0.734375 20.8851 0.770026 20.6152 0.841327 20.3571C0.936395 20.0755 1.04335 19.7587 1.16218 19.4067C1.35232 18.7496 1.60187 17.9635 1.91085 17.0483C2.24358 16.1096 2.58821 15.1005 2.94471 14.0211C3.32499 12.9181 3.72903 11.7917 4.15684 10.6419C4.58464 9.46854 4.98868 8.34214 5.36896 7.26267C5.773 6.15974 6.15327 5.13894 6.50978 4.20027C6.89005 3.23814 7.21091 2.4168 7.47234 1.73627C7.78132 1.572 8.23289 1.40774 8.82707 1.24347C9.44501 1.0792 10.063 0.99707 10.6809 0.99707C11.5127 0.99707 12.2733 1.12614 12.9625 1.38427C13.6518 1.61894 14.0915 2.00614 14.2816 2.54587C14.757 3.83654 15.2798 5.32667 15.8502 7.01627C16.4207 8.6824 16.9911 10.4072 17.5615 12.1907C18.1556 13.9741 18.7261 15.7224 19.2727 17.4355C19.8193 19.1485 20.3066 20.6856 20.7344 22.0467C20.4967 22.3048 20.164 22.516 19.7362 22.6803C19.3321 22.868 18.8449 22.9619 18.2745 22.9619C17.4426 22.9619 16.8485 22.8211 16.492 22.5395C16.1592 22.2344 15.8978 21.7651 15.7076 21.1315L14.6381 17.6115ZM10.6452 5.00987C10.4313 5.59654 10.1937 6.2536 9.93224 6.98107C9.69457 7.70854 9.43313 8.4712 9.14792 9.26907C8.88648 10.0435 8.61316 10.8413 8.32796 11.6627C8.04275 12.4605 7.76943 13.2467 7.50799 14.0211H13.7112L10.8235 5.00987H10.6452Z"
        fill={color}
      />
    </svg>
  );
};

export default FontIcon;
