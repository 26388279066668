import { take, put } from "redux-saga/effects";
import { GET_SHOP_CATEGORIES, getShopCategoriesActions } from "src/redux/actions/shop/index";

// services
import { getShopCategories } from "src/services/shop";

// types
import { GetShopCategoriesResponseTypes } from "src/redux/actions/shop/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetShopCategoriesRequest() {
  while (true) {
    yield take(GET_SHOP_CATEGORIES.REQUEST);

    try {
      const result: GetShopCategoriesResponseTypes = yield call(getShopCategories);
      yield put(getShopCategoriesActions.success(result));
    } catch (e) {
      yield put(getShopCategoriesActions.error(e as any));
    }
  }
}

export default watchGetShopCategoriesRequest;
