import React from "react";
import { IconPropsType } from "./IconTypes";

const QuestionIcon = ({ color = "#FFF", width = 10, height = 14 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.74201 9.08977C4.18973 9.08977 3.71643 8.63166 3.87094 8.10142C3.94196 7.85773 4.04231 7.63758 4.172 7.44097C4.47112 7.0064 4.9011 6.5271 5.46195 6.00306C5.87324 5.61962 6.17236 5.30009 6.35932 5.04446C6.55873 4.77605 6.65843 4.47568 6.65843 4.14337C6.65843 3.67046 6.47148 3.2998 6.09758 3.03139C5.73615 2.75019 5.25008 2.6096 4.63937 2.6096C4.0536 2.6096 3.53014 2.73741 3.06899 2.99304C2.88698 3.09155 2.71625 3.20689 2.5568 3.33906C2.15151 3.675 1.57813 3.81744 1.12707 3.54602L0.54727 3.19712C0.0648109 2.9068 -0.0893876 2.26647 0.299098 1.85887C0.718034 1.41933 1.21135 1.05607 1.77904 0.769076C2.6764 0.321727 3.73578 0.098053 4.95719 0.098053C6.39047 0.098053 7.5371 0.411197 8.39707 1.03749C9.2695 1.66378 9.70572 2.53291 9.70572 3.64489C9.70572 4.16893 9.61848 4.62906 9.44399 5.02529C9.28197 5.42151 9.07632 5.76022 8.82706 6.04141C8.59025 6.30982 8.27867 6.62296 7.89231 6.98084C7.43116 7.40263 7.09465 7.76051 6.88278 8.05448C6.86212 8.08189 6.84248 8.1099 6.82384 8.13853C6.52246 8.60133 6.11725 9.08977 5.56496 9.08977H4.74201ZM5.16283 13.902C4.65184 13.902 4.22185 13.7358 3.87288 13.4035C3.53637 13.0584 3.36811 12.643 3.36811 12.1573C3.36811 11.6716 3.53637 11.269 3.87288 10.9495C4.20939 10.6172 4.63937 10.451 5.16283 10.451C5.68629 10.451 6.11628 10.6172 6.45279 10.9495C6.7893 11.269 6.95755 11.6716 6.95755 12.1573C6.95755 12.643 6.78307 13.0584 6.4341 13.4035C6.09759 13.7358 5.67383 13.902 5.16283 13.902Z"
        fill={color}
      />
    </svg>
  );
};

export default QuestionIcon;
