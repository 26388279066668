import React, { useState } from "react";
import { ButtonPrimary, HeadingH1 } from "src/components";

import { StyledForm } from "../../../AuthForm.styles";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { RootReducer } from "../../../../../redux/reducers";
import { getNonceTokenActions } from "../../../../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

const BRANCH_DEEP_LINK_DK = "https://maneno.app.link/ZN5pOpSfAHb";

interface Props {
  onNext: () => void;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const FinalNotaSignupMobileStep: React.FC<Props> = ({ onNext }) => {
  const [t] = useTranslation();
  const [formError, setFormError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const {
    nota: { finalSignupResult }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const onSuccess = (token: string) => {
    window.location.href = BRANCH_DEEP_LINK_DK + "?nonce=" + encodeURIComponent(token ?? "");
  };

  const onError = () => {
    setFormError(t("Uh-oh. It seems to be an error during the signup process. Something went terribly wrong."));
  };

  const handleSubmit = () => {
    if (!finalSignupResult || !finalSignupResult.authResult?.access_token) {
      setFormError(t("Uh-oh. It seems to be an error during the signup process. Something went terribly wrong."));
      return;
    }
    dispatch(
      getNonceTokenActions.request({
        userToken: finalSignupResult.authResult.access_token,
        onSuccess: onSuccess,
        onError: onError
      })
    );
  };

  return (
    <Formik initialValues={{}} onSubmit={handleSubmit}>
      <StyledForm>
        <Form onChange={() => setFormError(null)}>
          <div className="form-row-center">
            <HeadingH1 align="center" title={t("web_nota_signup_final_step_header")} />
          </div>
          <div className="form-action">
            <div className="buttons-wrapper">
              <div className="login-buttons-wrapper">
                <ButtonPrimary type="submit" title={t("open_mobile_app")} />
              </div>
              <ErrorMessage name="cpr" component={StyledError} />
              {formError && <StyledError>{formError}</StyledError>}
            </div>
          </div>
          <div className="form-row-center">
            <p>{t("web_nota_signup_final_step_bullet1")}</p>
            <p>{t("web_nota_signup_final_step_bullet2")}</p>
            <p>{t("web_nota_signup_final_step_bullet3")}</p>
          </div>
        </Form>
      </StyledForm>
    </Formik>
  );
};

export default FinalNotaSignupMobileStep;
