import React from "react";

// types
import { HeadingH1PropsType } from "./HeadingH1Types";

// styles
import { StyledHeading } from "./HeadingH1.styles";

const HeadingH1 = ({ title, align = "left", color = "#165B95" }: HeadingH1PropsType) => {
  return (
    <StyledHeading color={color} align={align}>
      {title}
    </StyledHeading>
  );
};

export default HeadingH1;
