import { GET_SHOP_ITEMS, GET_SHOP_CATEGORIES, PURCHASE_ITEM } from "src/redux/actions/shop/index";
import { GetShopItemsResponseTypes, GetShopCategoriesResponseTypes } from "src/redux/actions/shop/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: ShopState = {
  isLoading: false,
  shopItems: undefined,
  shopCategories: undefined,
  error: undefined
};

export interface ShopState {
  isLoading: boolean;
  shopItems: GetShopItemsResponseTypes | undefined;
  shopCategories: GetShopCategoriesResponseTypes | undefined;
  error: any;
}

const shop = (
  state = initialState,
  action: IActionType<GetShopItemsResponseTypes | GetShopCategoriesResponseTypes>
): ShopState => {
  switch (action.type) {
    case GET_SHOP_ITEMS.REQUEST:
    case GET_SHOP_CATEGORIES.REQUEST:
    case PURCHASE_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case GET_SHOP_ITEMS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        shopItems: action.payload as GetShopItemsResponseTypes,
        error: undefined
      };

    case GET_SHOP_CATEGORIES.SUCCESS:
      return {
        ...state,
        isLoading: false,
        shopCategories: action.payload as GetShopCategoriesResponseTypes,
        error: undefined
      };

    case PURCHASE_ITEM.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case GET_SHOP_ITEMS.ERROR:
    case GET_SHOP_CATEGORIES.ERROR:
    case PURCHASE_ITEM.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default shop;
