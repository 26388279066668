import React from "react";
import { IconPropsType } from "./IconTypes";

const FontArialIcon = ({ color = "#01324B", width = 25, height = 25 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.73438 6.49854C5.73438 7.05082 6.18209 7.49854 6.73438 7.49854C7.28666 7.49854 7.73438 7.05082 7.73438 6.49854C7.73438 5.94625 8.18209 5.49854 8.73438 5.49854H9.74933C10.386 5.49854 10.8606 6.08558 10.7271 6.70811L8.58379 16.7081C8.48497 17.1692 8.07752 17.4985 7.606 17.4985H6.73437C6.18209 17.4985 5.73438 17.9462 5.73438 18.4985C5.73438 19.0508 6.18209 19.4985 6.73438 19.4985H12.7344C13.2867 19.4985 13.7344 19.0508 13.7344 18.4985C13.7344 17.9462 13.28 17.4985 12.7277 17.4985C12.091 17.4985 11.6082 16.9115 11.7416 16.289L13.885 6.28896C13.9838 5.82791 14.3912 5.49854 14.8628 5.49854H16.7344C17.2867 5.49854 17.7344 5.94625 17.7344 6.49854C17.7344 7.05082 18.1821 7.49854 18.7344 7.49854C19.2867 7.49854 19.7344 7.05082 19.7344 6.49854V4.49854C19.7344 3.94625 19.2867 3.49854 18.7344 3.49854H6.73438C6.18209 3.49854 5.73438 3.94625 5.73438 4.49854V6.49854Z"
        fill={color}
      />
    </svg>
  );
};

export default FontArialIcon;
