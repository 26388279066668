import styled from "styled-components";

export const StyledLabel = styled.div`
  border-radius: 50px;
  display: inline-flex;
  flex-direction: column;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  height: fit-content;
  text-align: center;
  padding: 7px 9px;
  max-width: 228px;
  width: 100%;

  .type {
    font-size: 14px;
  }

  .value {
    font-size: 20px;
    line-height: 1.2;
  }
`;
