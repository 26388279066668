import { requestTypeCreator } from "src/helpers/index";
import { GetUsersRequestTypes, GetUsersResponseTypes } from "./types";

export const GET_USERS = requestTypeCreator("GET_USERS");
export const UPDATE_INVITE_STATUS = requestTypeCreator("UPDATE_INVITE_STATUS");

export const getUsersActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_USERS.ERROR
  }),
  request: (payload?: GetUsersRequestTypes) => {
    return {
      payload,
      type: GET_USERS.REQUEST
    };
  },
  success: (payload: GetUsersResponseTypes): IActionType<GetUsersResponseTypes> => ({
    payload,
    type: GET_USERS.SUCCESS
  })
};

export const updateInviteStatus = {
  success: (payload: any) => ({
    payload,
    type: UPDATE_INVITE_STATUS.SUCCESS
  })
};
