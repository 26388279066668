import React from "react";
import { IconPropsType } from "./IconTypes";

const PlayIcon = ({ color = "#165B95", width = 16, height = 18 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 16 18">
      <path
        fill={color}
        d="M14.548 7.655c1.274.78 1.274 2.631 0 3.411L3.878 17.6c-1.333.816-3.045-.143-3.045-1.705V2.827c0-1.563 1.712-2.522 3.045-1.706l10.67 6.534z"
      ></path>
    </svg>
  );
};

export default PlayIcon;
