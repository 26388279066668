// useAppFlags.js
import { useFlags } from "flagsmith/react";

export function useAppFlags() {
  const flags = useFlags(["quiz_enabled_student", "quiz_feedback_enabled"]);
  const quizStudentEnabled = flags.quiz_enabled_student.enabled;
  const quizFeedbackEnabled = flags.quiz_feedback_enabled.enabled;

  return { quizStudentEnabled, quizFeedbackEnabled };
}
