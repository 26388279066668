import React from "react";
import { useTranslation } from "react-i18next";
import dragonReadingImg from "src/assets/images/dragon-reading.png";
import cloudImg from "src/assets/images/cloud.svg";
import logoImg from "src/assets/images/logo.svg";
import cloudSeparatorImg from "src/assets/images/cloud-separator-login.svg";
import cloudSeparatorMobileImg from "src/assets/images/cloud-separator-mobile-img.svg";
import { Img } from "react-image";

// components
import { HeadingH1, HeadingH3 } from "src/components";

// types
import { AuthLayoutPropsType } from "./AuthLayoutTypes";

// styles
import { StyledLayout } from "./AuthLayout.styles";
import { useScreenWidth } from "../../helpers";

const AuthLayout = ({ children, title }: AuthLayoutPropsType) => {
  const [t] = useTranslation();
  const [screenWidth] = useScreenWidth();

  return (
    <StyledLayout>
      <div className="layout-left">
        <div className="logo-wrapper">
          <Img src={logoImg} alt="logo" />
          <HeadingH3 title={t("Read books in Maneno and see the dragon grow!")} color="#FFFFFF" align="center" />
        </div>
        {screenWidth > 992 ? (
          <div className="dragon-wrapper">
            <Img className="dragon-img" src={dragonReadingImg} alt="dragon reading image" />
            <Img className="cloud-img" src={cloudImg} alt="cloud image" />
          </div>
        ) : (
          <></>
        )}
        <div className="cloud-separator">
          <Img className="cloud-separator-img" src={cloudSeparatorImg} alt="cloud separator" />
        </div>
        <div className="cloud-separator-mobile">
          <Img
            className={`cloud-separator-mobile-img ${screenWidth <= 992 ? "mobile" : ""}`}
            src={cloudSeparatorMobileImg}
            alt="cloud separator"
          />
        </div>
      </div>
      <div className="layout-right">
        <div className="title-wrapper">{title ? <HeadingH1 align="center" title={title} /> : null}</div>

        {children}
      </div>
    </StyledLayout>
  );
};

export default AuthLayout;
