import React from "react";
import { IconPropsType } from "./IconTypes";

const UserIcon = ({ color = "#38A6DE", width = 16, height = 18 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" width={width} height={height} fill={color}>
      <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3 0 498.7 13.3 512 29.7 512h388.6c16.4 0 29.7-13.3 29.7-29.7 0-98.5-79.8-178.3-178.3-178.3h-91.4z"></path>
    </svg>
  );
};

export default UserIcon;
