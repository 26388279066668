import { take, put } from "redux-saga/effects";
import { GET_SERIES_LIST, getSeriesListActions } from "src/redux/actions/book";

// services
import { getTagList } from "src/services/book";

// types
import { GetTagListRequestTypes, GetTagListResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetSeriesListRequest() {
  while (true) {
    const { payload }: IActionType<GetTagListRequestTypes> = yield take(GET_SERIES_LIST.REQUEST);

    try {
      const result: GetTagListResponseTypes = yield call(getTagList, payload);
      yield put(getSeriesListActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getSeriesListActions.error(e as any));
    }
  }
}

export default watchGetSeriesListRequest;
