import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

import coinImg from "src/assets/images/money.svg";

// components
import { ButtonBlue, ConfirmModal, HeadingH4 } from "src/components";

// types
import { HeaderPropTypes } from "./HeaderTypes";

// styles
import { StyledHeader } from "./HeaderQuiz.styles";

const HeaderQuiz = ({ children, rewardAmount, setIsPlaying }: HeaderPropTypes) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState<boolean>(false);

  const handleToggleModal = (show: boolean) => {
    setShowModal(show);
    setIsPlaying && setIsPlaying(!show);
  };

  return (
    <>
      <StyledHeader>
        <div className="header-left">
          <ButtonBlue title={t("quiz_back_button")} backButton onClick={() => handleToggleModal(true)} />
        </div>
        <div className="header-center">{children}</div>
        <div className="header-right">
          <div className="earning-item">
            <span className="earning-image">
              <Img src={coinImg} alt="coins" />
            </span>
            <span className="earning-value">
              {rewardAmount}{" "}
              <span style={{ display: "none" }} className="earning-coefficient">
                x 0.2
              </span>
            </span>
          </div>
        </div>
      </StyledHeader>
      <ConfirmModal
        showCancelButton
        isVisible={showModal}
        closeModal={() => handleToggleModal(false)}
        confirmAction={() => navigate(-1)}
      >
        <HeadingH4 align="center" title={t("quiz_popup_back_title")} />
      </ConfirmModal>
    </>
  );
};

export default HeaderQuiz;
