import React from "react";
import { IconPropsType } from "./IconTypes";

const BookIcon = ({ color = "#165B95", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.11437 7.81443C8.30194 6.69161 9.92415 6.05165 11.6266 6.05165C13.329 6.05165 14.9512 6.69161 16.1388 7.81443C17.3244 8.93542 17.9785 10.4427 17.9785 12.0007V12.718H16.3652C15.762 12.718 15.1731 12.944 14.7303 13.3625C14.2857 13.783 14.0239 14.3663 14.0239 14.9882V17.2298C14.0239 17.8517 14.2857 18.435 14.7303 18.8554C15.1731 19.274 15.762 19.5 16.3652 19.5H17.1555C17.7586 19.5 18.3476 19.274 18.7903 18.8554C19.235 18.435 19.4968 17.8517 19.4968 17.2298V16.5555C19.4989 16.5319 19.5 16.5081 19.5 16.484V12.0007C19.5 9.99163 18.6553 8.07789 17.1732 6.67656C15.693 5.27706 13.6969 4.5 11.6266 4.5C9.55624 4.5 7.56018 5.27706 6.07998 6.67656C4.59784 8.07789 3.75319 9.99163 3.75319 12.0007V13.4223C3.75108 13.4458 3.75 13.4697 3.75 13.4938V17.2298C3.75 17.8517 4.01181 18.435 4.45648 18.8554C4.89922 19.274 5.48817 19.5 6.09133 19.5H6.88162C7.48478 19.5 8.07373 19.274 8.51647 18.8554C8.96114 18.435 9.22295 17.8517 9.22295 17.2298V14.9882C9.22295 14.3663 8.96114 13.783 8.51647 13.3625C8.07373 12.944 7.48478 12.718 6.88162 12.718H5.27466V12.0007C5.27466 10.4427 5.92874 8.93542 7.11437 7.81443ZM5.27147 16.5555C5.27358 16.5319 5.27466 16.5081 5.27466 16.484V14.2696H6.88162C7.11687 14.2696 7.33197 14.3585 7.48208 14.5004C7.63024 14.6405 7.70148 14.8174 7.70148 14.9882V17.2298C7.70148 17.4006 7.63024 17.5775 7.48208 17.7175C7.33197 17.8595 7.11687 17.9484 6.88162 17.9484H6.09133C5.85608 17.9484 5.64098 17.8595 5.49087 17.7175C5.34271 17.5775 5.27147 17.4006 5.27147 17.2298V16.5555ZM15.7647 14.5004C15.9148 14.3585 16.1299 14.2696 16.3652 14.2696H17.9753V17.2298C17.9753 17.4006 17.9041 17.5775 17.7559 17.7175C17.6058 17.8595 17.3907 17.9484 17.1555 17.9484H16.3652C16.1299 17.9484 15.9148 17.8595 15.7647 17.7175C15.6166 17.5775 15.5453 17.4006 15.5453 17.2298V14.9882C15.5453 14.8174 15.6166 14.6405 15.7647 14.5004Z"
        fill={color}
      />
    </svg>
  );
};

export default BookIcon;
