import { requestTypeCreator } from "src/helpers/index";
import {
  GetShopItemsRequestTypes,
  GetShopItemsResponseTypes,
  GetShopCategoriesResponseTypes,
  PurchaseItemRequestTypes
} from "./types";

export const GET_SHOP_ITEMS = requestTypeCreator("GET_SHOP_ITEMS");
export const GET_SHOP_CATEGORIES = requestTypeCreator("GET_SHOP_CATEGORIES");
export const PURCHASE_ITEM = requestTypeCreator("PURCHASE_ITEM");

export const getShopItemsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SHOP_ITEMS.ERROR
  }),
  request: (payload: string): IActionType<GetShopItemsRequestTypes> => ({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    payload,
    type: GET_SHOP_ITEMS.REQUEST
  }),
  success: (payload: GetShopItemsResponseTypes): IActionType<GetShopItemsResponseTypes> => ({
    payload,
    type: GET_SHOP_ITEMS.SUCCESS
  })
};

export const purchaseItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: PURCHASE_ITEM.ERROR
  }),
  request: (payload: PurchaseItemRequestTypes): IActionType<PurchaseItemRequestTypes> => ({
    payload,
    type: PURCHASE_ITEM.REQUEST
  }),
  success: () => ({
    type: PURCHASE_ITEM.SUCCESS
  })
};

export const getShopCategoriesActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SHOP_CATEGORIES.ERROR
  }),
  request: () => ({
    type: GET_SHOP_CATEGORIES.REQUEST
  }),
  success: (payload: GetShopCategoriesResponseTypes): IActionType<GetShopCategoriesResponseTypes> => ({
    payload,
    type: GET_SHOP_CATEGORIES.SUCCESS
  })
};
