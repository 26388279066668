import { GET_LEADERBOARD_TOP_USERS, RESET_LEADERBOARD_TOP_USERS } from "src/redux/actions/leaderboard";
import {
  GetLeaderboardUsersResponseTypes,
  LeaderboardUserTypes,
  ResetLeaderboardTopUsersTypes
} from "src/redux/actions/leaderboard/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: LeaderboardState = {
  isFetchingPedestals: false,
  pedestalUsers: [],
  error: undefined
};

export interface LeaderboardState {
  isFetchingPedestals: boolean;
  pedestalUsers: LeaderboardUserTypes[];
  error: any;
}

const leaderboard = (
  state = initialState,
  action: IActionType<GetLeaderboardUsersResponseTypes | ResetLeaderboardTopUsersTypes | any>
): LeaderboardState => {
  switch (action.type) {
    case RESET_LEADERBOARD_TOP_USERS:
      return {
        ...state,
        pedestalUsers: []
      };
    case GET_LEADERBOARD_TOP_USERS.REQUEST:
      return {
        ...state,
        isFetchingPedestals: true,
        error: undefined
      };

    case GET_LEADERBOARD_TOP_USERS.SUCCESS:
      return {
        ...state,
        isFetchingPedestals: false,
        pedestalUsers: action.payload.users,
        error: undefined
      };

    case GET_LEADERBOARD_TOP_USERS.ERROR:
      return {
        ...state,
        isFetchingPedestals: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default leaderboard;
