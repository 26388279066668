import React, { useState, useEffect } from "react";
import { ButtonPrimary, LoaderSmall, HeadingH1 } from "src/components";

import { StyledForm, StyledInput } from "../../../AuthForm.styles";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { RootReducer } from "../../../../../redux/reducers";
import { parentProfileUpdate } from "../../../../../redux/actions/nota";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface Props {
  onNext: () => void;
}

const ParentProfileStep: React.FC<Props> = ({ onNext }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const emailIsNotValid = t("email_validation_error");
  const passwordIsTooShort = t("Password is too short");
  const {
    nota: { emailAvilibilityResult, parentProfileResult, isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;
  // validation schema
  const parentFormSchema = Yup.object().shape({
    email: Yup.string().email(emailIsNotValid).required(t("This field is required.")),
    password: Yup.string().min(4, passwordIsTooShort).required(t("This field is required."))
  });

  const [formError, setFormError] = useState<string | null>(null);

  const validateForm = async (email: string, password: string) => {
    setFormError("");
    dispatch(parentProfileUpdate.request({ email: email, password: password }));
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    await validateForm(values.email, values.password);
    setSubmitting(false);
  };

  useEffect(() => {
    if (parentProfileResult && parentProfileResult.email && parentProfileResult.isAvailabile) {
      onNext();
    }
  }, [parentProfileResult]);

  useEffect(() => {
    if (parentProfileResult && parentProfileResult?.isAvailabile === false) {
      setFormError(t("email_validation_error"));
    }
  }, [parentProfileResult?.isAvailabile]);

  return (
    <Formik
      initialValues={{ email: emailAvilibilityResult?.email ?? "" }}
      validationSchema={parentFormSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <StyledForm>
            <>
              {(isSubmitting || isLoading) && (
                <div className="loader-wrapper">
                  <LoaderSmall />
                </div>
              )}
              <Form onChange={() => setFormError(null)}>
                <div className="form-row-center">
                  <HeadingH1 align="center" title={"Parent profile"} />
                </div>
                <div className="form-row">
                  <StyledInput>
                    <label htmlFor="email" className="form-label">
                      {t("Email")}
                    </label>
                    <Field type="email" name="email" className="form-input" />
                  </StyledInput>
                  <ErrorMessage name="email" component={StyledError} />
                  {formError && <StyledError>{formError}</StyledError>}
                </div>
                <div className="form-row">
                  <StyledInput>
                    <label htmlFor="password" className="form-label">
                      {t("Password")}
                    </label>
                    <Field type="password" name="password" className="form-input" />
                  </StyledInput>
                  <ErrorMessage name="password" component={StyledError} />
                  {formError && <StyledError>{formError}</StyledError>}
                </div>
                <div className="form-action">
                  <div className="buttons-wrapper">
                    <div className="login-buttons-wrapper">
                      <ButtonPrimary type="submit" title="Continue" disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </Form>
            </>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ParentProfileStep;
