import React from "react";
import { IconPropsType } from "./IconTypes";

const FontsIcon = ({ color = "#9AB3C2", width = 24, height = 13 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.95187 10.7968H2.959C2.82828 11.152 2.68568 11.5216 2.53119 11.9056C2.38859 12.2896 2.26976 12.6064 2.17469 12.856C2.05585 12.8944 1.92513 12.928 1.78253 12.9568C1.65181 12.9856 1.48544 13 1.28342 13C0.879382 13 0.564468 12.9376 0.338681 12.8128C0.112894 12.6784 0 12.4912 0 12.2512C0 12.136 0.0178253 12.0256 0.0534759 11.92C0.10101 11.8048 0.154486 11.6752 0.213904 11.5312C0.308972 11.2624 0.433749 10.9408 0.588235 10.5664C0.754605 10.1824 0.926916 9.7696 1.10517 9.328C1.29531 8.8768 1.49733 8.416 1.71123 7.9456C1.92513 7.4656 2.12715 7.0048 2.31729 6.5632C2.51931 6.112 2.70945 5.6944 2.8877 5.3104C3.07784 4.9168 3.23827 4.5808 3.36898 4.3024C3.52347 4.2352 3.74926 4.168 4.04635 4.1008C4.35532 4.0336 4.66429 4 4.97326 4C5.38919 4 5.76946 4.0528 6.11408 4.1584C6.4587 4.2544 6.67855 4.4128 6.77362 4.6336C7.01129 5.1616 7.27273 5.7712 7.55793 6.4624C7.84314 7.144 8.12834 7.8496 8.41355 8.5792C8.71064 9.3088 8.99584 10.024 9.26916 10.7248C9.54248 11.4256 9.7861 12.0544 10 12.6112C9.88117 12.7168 9.7148 12.8032 9.50089 12.8704C9.29887 12.9472 9.05526 12.9856 8.77005 12.9856C8.35413 12.9856 8.05704 12.928 7.87879 12.8128C7.71242 12.688 7.5817 12.496 7.48663 12.2368L6.95187 10.7968ZM4.95544 5.6416C4.84848 5.8816 4.72965 6.1504 4.59893 6.448C4.4801 6.7456 4.34938 7.0576 4.20677 7.384C4.07606 7.7008 3.93939 8.0272 3.79679 8.3632C3.65419 8.6896 3.51753 9.0112 3.38681 9.328H6.48841L5.04456 5.6416H4.95544Z"
        fill={color}
      />
      <path
        d="M20.0374 9.8176H14.8467C14.6768 10.3307 14.4914 10.8645 14.2906 11.4192C14.1052 11.9739 13.9507 12.4315 13.8271 12.792C13.6726 12.8475 13.5027 12.896 13.3173 12.9376C13.1474 12.9792 12.9311 13 12.6684 13C12.1432 13 11.7338 12.9099 11.4403 12.7296C11.1468 12.5355 11 12.2651 11 11.9184C11 11.752 11.0232 11.5925 11.0695 11.44C11.1313 11.2736 11.2008 11.0864 11.2781 10.8784C11.4017 10.4901 11.5639 10.0256 11.7647 9.4848C11.981 8.93013 12.205 8.33387 12.4367 7.696C12.6839 7.04427 12.9465 6.37867 13.2246 5.6992C13.5027 5.00587 13.7653 4.34027 14.0125 3.7024C14.2751 3.05067 14.5223 2.44747 14.754 1.8928C15.0012 1.32427 15.2097 0.838933 15.3797 0.436799C15.5805 0.339733 15.874 0.242666 16.2602 0.145599C16.6619 0.0485331 17.0636 0 17.4652 0C18.0059 0 18.5003 0.0762665 18.9483 0.2288C19.3963 0.367466 19.6821 0.596266 19.8057 0.915199C20.1147 1.67787 20.4545 2.5584 20.8253 3.5568C21.1961 4.54133 21.5668 5.56053 21.9376 6.6144C22.3238 7.66827 22.6946 8.70133 23.0499 9.7136C23.4052 10.7259 23.7219 11.6341 24 12.4384C23.8455 12.5909 23.6292 12.7157 23.3512 12.8128C23.0885 12.9237 22.7718 12.9792 22.4011 12.9792C21.8604 12.9792 21.4742 12.896 21.2424 12.7296C21.0261 12.5493 20.8562 12.272 20.7326 11.8976L20.0374 9.8176ZM17.4421 2.3712C17.303 2.71787 17.1485 3.10613 16.9786 3.536C16.8241 3.96587 16.6542 4.41653 16.4688 4.888C16.2989 5.3456 16.1212 5.81707 15.9358 6.3024C15.7504 6.77387 15.5728 7.2384 15.4029 7.696H19.4349L17.5579 2.3712H17.4421Z"
        fill={color}
      />
    </svg>
  );
};

export default FontsIcon;
