import { put, take } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { GET_INVITES, getInvitesActions } from "src/redux/actions/friends";

// services
import { getInvites } from "src/services/friends";

// types
import {
  GetInvitesRequestTypes,
  GetInvitesResponseTypes,
  GetPendingInvitesRequestTypes
} from "src/redux/actions/friends/types";

const call: any = Effects.call;

function* watchGetInvitesRequest(params?: GetInvitesRequestTypes) {
  while (true) {
    const { payload }: IActionType<GetPendingInvitesRequestTypes> = yield take(GET_INVITES.REQUEST);

    try {
      const result: GetInvitesResponseTypes = yield call(getInvites, payload || params);
      yield put(getInvitesActions.success(result));
    } catch (e) {
      yield put(getInvitesActions.error(e as any));
    }
  }
}

export default watchGetInvitesRequest;
