import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

// assets
import { LogoutIcon } from "src/assets/icons";

// types
import { ButtonLogoutPropsType } from "./ButtonLogoutTypes";

// styles
import { StyledButton } from "./ButtonLogout.styles";

const ButtonLogout = ({ hideTitle, onClick }: ButtonLogoutPropsType) => {
  const [t] = useTranslation();
  const [titleVisible, setTitleVisible] = useState<boolean>(hideTitle);

  useEffect(() => {
    setTitleVisible(hideTitle);
  }, [hideTitle]);

  return (
    <StyledButton className="btn-logout" onClick={onClick}>
      <span className="icon-wrapper">
        <LogoutIcon />
      </span>
      {titleVisible && <span className="btn-title">{t("Logout")}</span>}
    </StyledButton>
  );
};

export default ButtonLogout;
