import React from "react";
import { IconPropsType } from "./IconTypes";

const FaceIcon = ({ color = "#01324B", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.3333 4C7.73833 4 4 7.73833 4 12.3333C4 16.9283 7.73833 20.6667 12.3333 20.6667C16.9283 20.6667 20.6667 16.9283 20.6667 12.3333C20.6667 7.73833 16.9283 4 12.3333 4ZM12.3333 5.66667C15.0108 5.66667 17.3183 7.25667 18.3783 9.54C18.1267 9.42417 17.8942 9.28917 17.6325 9.125C17.2917 8.91 16.905 8.66583 16.4158 8.48167C15.8583 8.26917 15.2492 8.16667 14.5542 8.16667C13.8592 8.16667 13.25 8.26917 12.6925 8.48083C12.2033 8.66667 11.8167 8.91083 11.4658 9.13167C11.1508 9.32917 10.88 9.50083 10.5467 9.62667C10.175 9.7675 9.77167 9.83333 9.275 9.83333C8.77917 9.83333 8.37583 9.7675 8.005 9.62667C7.67167 9.50083 7.39833 9.32833 7.08333 9.12833C6.94917 9.04417 6.8 8.955 6.65 8.8675C7.8225 6.95167 9.9275 5.66667 12.3333 5.66667ZM12.3333 19C8.6575 19 5.66667 16.0092 5.66667 12.3333C5.66667 11.6583 5.76917 11.0083 5.95667 10.3942C6.035 10.4425 6.11083 10.4858 6.1925 10.5383C6.535 10.755 6.9225 11 7.41417 11.185C7.97167 11.3975 8.58 11.5 9.275 11.5C9.96917 11.5 10.5783 11.3975 11.1375 11.1858C11.6267 11 12.0133 10.7558 12.3642 10.535C12.6792 10.3375 12.95 10.1658 13.2833 10.0392C13.6542 9.89917 14.0575 9.83333 14.5542 9.83333C15.0508 9.83333 15.4542 9.89917 15.825 10.04C16.1575 10.165 16.4292 10.3367 16.7533 10.5417C17.0942 10.7567 17.4808 11.0008 17.97 11.1858C18.2725 11.3008 18.5933 11.3767 18.9308 11.4283C18.9717 11.7258 19 12.0258 19 12.3333C19 16.0092 16.0092 19 12.3333 19Z"
        fill={color}
      />
      <path
        d="M9.41406 14.8334C10.1044 14.8334 10.6641 14.2737 10.6641 13.5834C10.6641 12.893 10.1044 12.3334 9.41406 12.3334C8.72371 12.3334 8.16406 12.893 8.16406 13.5834C8.16406 14.2737 8.72371 14.8334 9.41406 14.8334Z"
        fill={color}
      />
      <path
        d="M15.25 14.8334C15.9404 14.8334 16.5 14.2737 16.5 13.5834C16.5 12.893 15.9404 12.3334 15.25 12.3334C14.5596 12.3334 14 12.893 14 13.5834C14 14.2737 14.5596 14.8334 15.25 14.8334Z"
        fill={color}
      />
    </svg>
  );
};

export default FaceIcon;
