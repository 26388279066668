import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledLink = styled.div`
  &.collapsed {
    .link-title-wrapper {
      width: 0;
    }

    .icon-wrapper {
      min-width: unset;
    }

    .nav-link {
      display: flex;
      justify-content: center;
      padding: 14px 10px;

      @media ${device.tablet} {
        padding: 12px 10px;
      }
    }
  }

  .link-title-wrapper {
    overflow: hidden;
    width: auto;
    transition: width 300ms;
  }

  &:not(.assignment-link) {
    .nav-link {
      &.active {
        svg,
        path {
          fill: ${({ theme }) => theme.colors.blueDark};
        }
      }
    }
  }

  &.assignment-link {
    .icon-wrapper {
      margin: 0 0 0 5px;
    }
  }

  .nav-link {
    align-items: center;
    display: flex;
    color: ${({ theme }) => theme.colors.greyMuted};
    font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    padding: 14px 24px;
    text-decoration: none;

    &.active {
      color: ${({ theme }) => theme.colors.blueDark};
    }

    &:hover {
      filter: brightness(0.92);
    }

    .icon-wrapper {
      align-items: center;
      display: flex;
      justify-content: center;
      min-width: 51px;
    }
  }

  .icon-wrapper {
    position: relative;

    .badge {
      position: absolute;
      background-color: ${({ theme }) => theme.colors.blueDark};
      border-radius: 50%;
      color: ${({ theme }) => theme.colors.white};
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      width: max-content;
      min-width: 26px;
      height: 26px;
      top: -6px;
      left: -7px;
      padding: 0 5px;
    }
  }
`;
