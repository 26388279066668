import { all, fork } from "@redux-saga/core/effects";
import { SagaIterator } from "@redux-saga/types";
import watchLoginRequest from "./auth/login";
import watchUniloginRequest from "./auth/unilogin";
import watchSSORequest from "./auth/sso";
import watchNonceRequest from "./auth/nonce";
import watchValidateCprRequest from "./nota/validateCpr";
import watchValidateParentEmail from "./nota/checkEmailAvailability";
import watchGetBookListRequest from "./book/getBookList";
import watchGetBookItemRequest from "./book/getBookItem";
import watchGetSimilarBooksRequest from "./book/getSimilarBooks";
import watchGetRelatedBooksRequest from "./book/getRelatedBooks";
import watchGetShopItemsRequest from "./shop/getShopItems";
import watchGetShopCategoriesRequest from "./shop/getShopCategories";
import watchGetDragonsListRequest from "./dragon/getDragonsList";
import updateDragonRequest from "./dragon/updateDragon";
import purchaseItemRequest from "./shop/purchaseItem";
import watchGetSkinsListRequest from "./dragon/getSkinsList";
import watchGetBagItemsRequest from "./bag/getBagItems";
import watchConsumeFoodRequest from "./bag/consumeFood";
import watchGetBookURLRequest from "./book/getBookURL";
import watchGetCategoryListRequest from "./book/getCategoryList";
import watchGetLengthListRequest from "./book/getLengthList";
import watchGetSeriesListRequest from "./book/getSeriesList";
import watchGetAuthorListRequest from "./book/getAuthorList";
import watchUpdateBookStatisticsRequest from "./book/updateBookStatistic";
import watchDressItemRequest from "./bag/dressItem";
import watchUndressItemRequest from "./bag/undressItem";
import watchGetUserInfoRequest from "./user/getUserInfo";
import watchGetUsersRequest from "./users/getUsers";
import watchGetFriendsRequest from "./friends/getFriends";
import watchInviteFriendRequest from "./friends/inviteFriend";
import watchAcceptFriendRequest from "./friends/acceptFriend";
import watchDeclineFriendRequest from "./friends/declineFriend";
import watchRemoveFriendRequest from "./friends/removeFriend";
import watchGetLanguageListRequest from "./user/getLanguageList";
import watchGetBookQuizRequest from "./quiz/getBookQuiz";
import watchSaveBookQuizRequest from "./quiz/saveBookQuiz";
import watchStudentAssignmentsRequest from "./quiz/getStudentAssignments";
import watchAcceptAssignmentRequest from "./assignments/acceptAssignment";
import watchQuizDetailsRequest from "./quiz/getQuizDetails";
import watchGetLeaderboardTopUsersRequest from "./leaderboard/getLeaderboardTopUsers";
import watchGetBookAdditionalDataRequest from "./book/getBookAdditionalData";
import watchGetPendingInvitesRequest from "./friends/getPendingInvites";
import watchGetInvitesRequest from "./friends/getInvites";
import watchGetBookGamesRequest from "./quiz/getBookGames";
import watchGetReadingRewardsRequest from "./book/getReadingRewards";
import watchPendingAssignmentsRequest from "./quiz/getPendingAssignmentsCount";
import watchNewOverdueAssignmentsRequest from "./quiz/getOverdueAssignments";
import watchPostGameFeedback from "./quiz/postGameFeedback";
import watchGetUniloginUserRequest from "./nota/getUniloginUser";
import watchCreateUniloginUserWithParentRequest from "./nota/createNotaUser";
import watchGetNonceTokenRequest from "./auth/getNonceToken";
import watchUpdateParentProfile from "./nota/updateParentProfile";
import watchGetParentAuthRequest from "./nota/getParentAuth";
import watchCreateUniloginUserWithExistingParentRequest from "./nota/createNotaUserWithExistingParent";
import watchRequestOtpRequest from "./auth/requestOtp";
import watchAuthUsingOtpRequest from "./auth/authUsingOTP";
import watchMasqueradeRequest from "./auth/masquerade";

export default function* rootSaga(): SagaIterator {
  yield all([
    fork(watchPostGameFeedback),
    fork(watchLoginRequest),
    fork(watchUniloginRequest),
    fork(watchMasqueradeRequest),
    fork(watchGetParentAuthRequest),
    fork(watchSSORequest),
    fork(watchRequestOtpRequest),
    fork(watchAuthUsingOtpRequest),
    fork(watchNonceRequest),
    fork(watchGetNonceTokenRequest),
    fork(watchValidateCprRequest),
    fork(watchValidateParentEmail),
    fork(watchUpdateParentProfile),
    fork(watchGetUniloginUserRequest),
    fork(watchGetBookListRequest),
    fork(watchGetBookItemRequest),
    fork(watchGetSimilarBooksRequest),
    fork(watchGetRelatedBooksRequest),
    fork(watchGetCategoryListRequest),
    fork(watchGetLengthListRequest),
    fork(watchGetSeriesListRequest),
    fork(watchGetAuthorListRequest),
    fork(watchGetBookURLRequest),
    fork(watchGetShopItemsRequest),
    fork(watchGetShopCategoriesRequest),
    fork(watchGetDragonsListRequest),
    fork(updateDragonRequest),
    fork(purchaseItemRequest),
    fork(watchGetSkinsListRequest),
    fork(watchGetBagItemsRequest),
    fork(watchConsumeFoodRequest),
    fork(watchDressItemRequest),
    fork(watchUndressItemRequest),
    fork(watchGetUserInfoRequest),
    fork(watchGetUsersRequest),
    fork(watchGetFriendsRequest),
    fork(watchInviteFriendRequest),
    fork(watchAcceptFriendRequest),
    fork(watchDeclineFriendRequest),
    fork(watchRemoveFriendRequest),
    fork(watchGetLanguageListRequest),
    fork(watchGetBookQuizRequest),
    fork(watchSaveBookQuizRequest),
    fork(watchUpdateBookStatisticsRequest),
    fork(watchStudentAssignmentsRequest),
    fork(watchAcceptAssignmentRequest),
    fork(watchQuizDetailsRequest),
    fork(watchGetLeaderboardTopUsersRequest),
    fork(watchGetBookAdditionalDataRequest),
    fork(watchGetPendingInvitesRequest),
    fork(watchGetInvitesRequest),
    fork(watchGetBookGamesRequest),
    fork(watchGetReadingRewardsRequest),
    fork(watchPendingAssignmentsRequest),
    fork(watchNewOverdueAssignmentsRequest),
    fork(watchCreateUniloginUserWithParentRequest),
    fork(watchCreateUniloginUserWithExistingParentRequest)
  ]);
}
