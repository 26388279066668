import React from "react";
import { IconPropsType } from "./IconTypes";

const PauseIcon = ({ color = "#165B95", width = 16, height = 18 }: IconPropsType) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      version="1"
      viewBox="0 0 1140 1280"
      fill={color}
    >
      <path
        d="M2157 12789c-476-46-922-218-1240-476-240-196-437-478-527-754-139-425-242-1194-310-2304-5-93-12-206-15-250-11-165-35-775-47-1180-16-545-16-2305 0-2850 12-405 36-1015 47-1180 3-44 10-156 15-250 68-1110 171-1879 310-2304 110-337 353-646 680-866 108-72 334-183 470-230 544-188 1152-192 1695-10 588 196 1017 600 1185 1115 241 743 382 2636 382 5150s-141 4407-382 5150c-204 626-792 1082-1562 1209-245 41-483 51-701 30zM8750 12789c-47-5-140-18-208-30-772-129-1358-583-1562-1209-241-743-382-2636-382-5150s141-4407 382-5150c168-515 597-919 1185-1115 543-182 1151-178 1695 10 136 47 362 158 470 230 327 220 570 529 680 866 139 425 242 1194 310 2304 5 94 12 206 15 250 11 165 35 775 47 1180 16 545 16 2305 0 2850-12 405-36 1015-47 1180-3 44-10 157-15 250-68 1110-171 1879-310 2304-70 214-196 422-360 594-172 180-318 286-545 397-225 110-456 181-718 221-153 23-502 33-637 18z"
        transform="matrix(.1 0 0 -.1 0 1280)"
      ></path>
    </svg>
  );
};

export default PauseIcon;
