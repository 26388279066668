import { take, put } from "redux-saga/effects";
import { NONCE, loginActions } from "src/redux/actions/auth";

// services
import { setToken, setRefreshToken, setTokenExpDate } from "src/services/localStorage";
import { authWithNonce } from "src/services/user";

// types
import { LoginResponseTypes, NonceRequestTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchNonceRequest() {
  while (true) {
    const { payload }: IActionType<NonceRequestTypes> = yield take(NONCE.REQUEST);

    const { nonce, onError, onSuccess } = payload as NonceRequestTypes;

    try {
      const result: LoginResponseTypes = yield call(authWithNonce, { nonceToken: nonce });

      setToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setTokenExpDate(result[".expires"]);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(loginActions.success({ access_token: result.access_token, userId: result.UserId }));
      onSuccess(parseInt(result.UserId));
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(ssoActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchNonceRequest;
