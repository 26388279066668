import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledCheckbox = styled.div`
  .checkbox-container {
    display: block;
    font-size: ${({ theme }) => theme.fonts.fontSizeBase};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    @media ${device.tabletXL} {
      font-size: 16px;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      height: 0;
      width: 0;
    }
  }

  .checkmark {
    background-image: url("data:image/svg+xml,%3Csvg width='22' height='21' viewBox='0 0 22 21' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M2.98636 2.18739C4.60931 0.212753 17.2819 1.0501 19.1756 2.18735C21.0693 3.32459 20.9617 16.8013 18.6627 18.0284C16.3638 19.2555 6.69182 21.149 3.72953 19.0779C0.400381 16.7502 1.36342 4.16203 2.98636 2.18739Z' stroke='%23165B95' stroke-width='2'/%3E%3C/svg%3E%0A");
    background-position: center;
    background-repeat: no-repeat;
    position: absolute;
    top: -2px;
    left: 0;
    height: 24px;
    width: 24px;
  }

  .checkmark:after {
    bottom: 0;
    content: "";
    left: 4px;
    position: absolute;
    display: none;
  }

  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }

  .checkbox-container .checkmark:after {
    content: url("data:image/svg+xml,%3Csvg width='21' height='19' viewBox='0 0 21 19' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M17.3046 0.0854392C17.413 0.0474664 17.5393 0.0189863 17.6838 -1.23102e-07C17.8282 -1.16789e-07 17.9636 -1.10871e-07 18.0899 -1.05347e-07C18.4149 -9.11429e-08 18.7218 0.0379731 19.0106 0.113919C19.2995 0.189865 19.5522 0.313276 19.7689 0.484154C19.9855 0.655032 20.157 0.873376 20.2834 1.13919C20.4278 1.42398 20.5 1.77523 20.5 2.19293C20.5 2.59165 20.4188 2.98087 20.2563 3.3606C20.0938 3.75931 19.8591 4.24347 19.5522 4.81306L10.1711 17.9015C9.55731 18.3951 8.57866 19 7.5677 19C6.80948 19 6.22276 18.8861 5.80754 18.6582C5.39233 18.4304 5.06738 18.0981 4.83269 17.6615L0.5 9.82955C0.734687 8.89921 1.05061 8.26317 1.44777 7.92141C1.86299 7.59864 2.30529 7.43726 2.77466 7.43726C3.40651 7.43726 3.89394 7.59864 4.23694 7.92141C4.598 8.26317 4.93198 8.75682 5.23888 9.40235L7.64893 14.4148L17.3046 0.0854392Z' fill='%2338A6DE'/%3E%3C/svg%3E%0A");
  }
`;
