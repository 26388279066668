import { requestTypeCreator } from "src/helpers/index";
import { GetBagItemsResponseTypes, ConsumeFoodRequestTypes, DressItemRequestTypes } from "./types";

export const GET_BAG_ITEMS = requestTypeCreator("GET_BAG_ITEMS");
export const CONSUME_FOOD = requestTypeCreator("CONSUME_FOOD");
export const DRESS_ITEM = requestTypeCreator("DRESS_ITEM");
export const UNDRESS_ITEM = requestTypeCreator("UNDRESS_ITEM");

export const getBagItemsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BAG_ITEMS.ERROR
  }),
  request: (payload: { userId: string | undefined }) => ({
    payload,
    type: GET_BAG_ITEMS.REQUEST
  }),
  success: (payload: GetBagItemsResponseTypes): IActionType<GetBagItemsResponseTypes> => ({
    payload,
    type: GET_BAG_ITEMS.SUCCESS
  })
};

export const consumeFoodActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: CONSUME_FOOD.ERROR
  }),
  request: (payload: ConsumeFoodRequestTypes): IActionType<ConsumeFoodRequestTypes> => ({
    payload,
    type: CONSUME_FOOD.REQUEST
  }),
  success: () => ({
    type: CONSUME_FOOD.SUCCESS
  })
};

export const dressItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: DRESS_ITEM.ERROR
  }),
  request: (payload: DressItemRequestTypes): IActionType<DressItemRequestTypes> => ({
    payload,
    type: DRESS_ITEM.REQUEST
  }),
  success: () => ({
    type: DRESS_ITEM.SUCCESS
  })
};

export const undressItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UNDRESS_ITEM.ERROR
  }),
  request: (payload: DressItemRequestTypes): IActionType<DressItemRequestTypes> => ({
    payload,
    type: UNDRESS_ITEM.REQUEST
  }),
  success: () => ({
    type: UNDRESS_ITEM.SUCCESS
  })
};
