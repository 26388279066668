import { take, put } from "redux-saga/effects";
import { CONSUME_FOOD, consumeFoodActions, getBagItemsActions } from "src/redux/actions/bag";
import i18n from "i18next";

// services
import { consumeFood, getBagItems } from "src/services/bag";
import { getDragonsList } from "src/services/dragon";

// utils
import { getDragonsListActions } from "src/redux/actions/dragon";

// types
import { BagListResponseTypes, ConsumeFoodRequestTypes } from "src/redux/actions/bag/types";
import { GetDragonsListResponseTypes } from "src/redux/actions/dragon/types";
import { GetShopItemsResponseTypes, ShopItemTypes } from "src/redux/actions/shop/types";

import * as Effects from "redux-saga/effects";
import { getShopItems } from "../../../services/shop";
const call: any = Effects.call;

function* watchConsumeFoodRequest() {
  while (true) {
    const { payload }: IActionType<ConsumeFoodRequestTypes> = yield take(CONSUME_FOOD.REQUEST);

    const { userId, action, ...restPayload } = payload as ConsumeFoodRequestTypes;

    try {
      // consume food
      yield call(consumeFood, { ...restPayload, userId });

      const shopItems: GetShopItemsResponseTypes = yield call(getShopItems, {
        userId
      });

      // get dragons list to check updated dragon level
      const result: GetDragonsListResponseTypes = yield call(getDragonsList, {
        userId: Number(userId)
      });
      yield put(getDragonsListActions.success(result));

      yield put(consumeFoodActions.success());

      // get updated bag list
      const bagItemsArr: any = [];
      const bagItems: BagListResponseTypes = yield call(getBagItems, { userId: userId });
      const getFullBagItems = () => {
        bagItems &&
          Array.isArray(bagItems.items) &&
          bagItems.items.map(({ itemId, transactionId }) => {
            shopItems?.map(function (shopItem: ShopItemTypes) {
              if (shopItem.id === itemId) {
                bagItemsArr.push({ transactionId, ...shopItem });
              }
            });
          });
      };
      getFullBagItems();

      yield put(getBagItemsActions.success(bagItemsArr));
      action(i18n.t("Success"), "success");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      action(e.message || "Something went wrong", "error");
      yield put(consumeFoodActions.error({ error: e }));
    }
  }
}

export default watchConsumeFoodRequest;
