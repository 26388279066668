import {
  GET_FRIENDS,
  GET_INVITES,
  GET_PENDING_INVITES,
  INVITE_FRIEND,
  ACCEPT_FRIEND,
  DECLINE_FRIEND,
  REMOVE_FRIEND
} from "src/redux/actions/friends";
import {
  GetFriendsResponseTypes,
  GetInvitesResponseTypes,
  GetPendingInvitesResponseTypes,
  InviteFriendResponseTypes,
  DeclineFriendResponseTypes,
  RemoveFriendResponseTypes
} from "src/redux/actions/friends/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: FriendsState = {
  isLoading: false,
  friendsList: {
    rows: [],
    totalRows: 0
  },
  invites: [],
  pendingInvites: [],
  error: undefined
};

export interface FriendsState {
  isLoading: boolean;
  friendsList: GetFriendsResponseTypes;
  invites: GetInvitesResponseTypes;
  pendingInvites: GetPendingInvitesResponseTypes;
  error: any;
}

const friends = (
  state = initialState,
  action: IActionType<
    | GetFriendsResponseTypes
    | GetInvitesResponseTypes
    | GetPendingInvitesResponseTypes
    | InviteFriendResponseTypes
    | DeclineFriendResponseTypes
    | RemoveFriendResponseTypes
    | any
  >
): FriendsState => {
  let updatedInvites = state.invites;
  let updatedFriendsList = state.friendsList;
  switch (action.type) {
    case GET_FRIENDS.REQUEST:
    case GET_INVITES.REQUEST:
    case GET_PENDING_INVITES.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case INVITE_FRIEND.REQUEST:
    case ACCEPT_FRIEND.REQUEST:
    case DECLINE_FRIEND.REQUEST:
    case REMOVE_FRIEND.REQUEST:
      return {
        ...state,
        error: undefined
      };

    case GET_FRIENDS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        friendsList: action.payload as GetFriendsResponseTypes,
        error: undefined
      };
    }

    case GET_INVITES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        invites: action.payload as GetInvitesResponseTypes,
        error: undefined
      };
    }

    case GET_PENDING_INVITES.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        pendingInvites: action.payload as GetPendingInvitesResponseTypes,
        error: undefined
      };
    }
    case INVITE_FRIEND.SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case REMOVE_FRIEND.SUCCESS:
      updatedFriendsList = {
        ...state.friendsList,
        rows: state.friendsList.rows.filter((friend) => friend.id !== action.payload.userId)
      };
      updatedInvites = state.pendingInvites.filter((invite) => invite.id !== action.payload.userId);
      return {
        ...state,
        friendsList: updatedFriendsList,
        pendingInvites: updatedInvites,
        isLoading: false
      };

    case ACCEPT_FRIEND.SUCCESS: {
      updatedInvites = state.invites.filter((invite) => invite.id !== action.payload.userId);
      const updatedFriend = state.invites.find((invite) => invite.id === action.payload.userId);

      updatedFriendsList = updatedFriend
        ? {
            ...state.friendsList,
            rows: [...state.friendsList.rows, { ...updatedFriend, isFriend: true, hasInvitation: false }]
          }
        : updatedFriendsList;

      return {
        ...state,
        invites: updatedInvites,
        friendsList: updatedFriendsList,
        isLoading: false
      };
    }

    case DECLINE_FRIEND.SUCCESS:
      updatedInvites = state.invites.filter((invite) => invite.id !== action.payload.userId);
      return {
        ...state,
        invites: updatedInvites,
        isLoading: false
      };

    case GET_FRIENDS.ERROR:
    case GET_INVITES.ERROR:
    case GET_PENDING_INVITES.ERROR:
    case INVITE_FRIEND.ERROR:
    case ACCEPT_FRIEND.ERROR:
    case DECLINE_FRIEND.ERROR:
    case REMOVE_FRIEND.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default friends;
