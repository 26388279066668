import { take, put } from "redux-saga/effects";
import { GET_USERS, getUsersActions } from "src/redux/actions/users";

// services
import { getUsers } from "src/services/users";

// types
import { GetUsersRequestTypes, GetUsersResponseTypes } from "src/redux/actions/users/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetUsersRequest() {
  while (true) {
    const { payload }: IActionType<GetUsersRequestTypes> = yield take(GET_USERS.REQUEST);

    try {
      const result: GetUsersResponseTypes = yield call(getUsers, payload);
      yield put(getUsersActions.success(result));
    } catch (e) {
      yield put(getUsersActions.error(e as any));
    }
  }
}

export default watchGetUsersRequest;
