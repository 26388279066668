import { take, put } from "redux-saga/effects";
import { GET_BOOK_ADDITIONAL_DATA, getBookAdditionalDataActions } from "src/redux/actions/book";

// services
import { getBookAdditionalData } from "src/services/book";

// types
import { GetBookAdditionalDataRequestTypes, GetBookAdditionalDataResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookAdditionalDataRequest() {
  while (true) {
    const { payload }: IActionType<GetBookAdditionalDataRequestTypes> = yield take(GET_BOOK_ADDITIONAL_DATA.REQUEST);

    try {
      const result: GetBookAdditionalDataResponseTypes = yield call(getBookAdditionalData, payload);
      yield put(getBookAdditionalDataActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookAdditionalDataActions.error(e as any));
    }
  }
}

export default watchGetBookAdditionalDataRequest;
