import React from "react";
import { IconPropsType } from "./IconTypes";

const MinusIcon = ({ color = "#FFF", width = 19, height = 5 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 19 5" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M1.05671 4.48479H6.66083H12.3763H16.3103C16.9536 4.48479 17.4732 4.30629 17.8691 3.94929C18.265 3.56998 18.4629 2.98986 18.4629 2.20892C18.4629 1.78499 18.3887 1.38337 18.2402 1.00406C18.1165 0.602434 17.9928 0.267748 17.8691 0L12.3763 0L6.66083 0L2.65258 0C1.98454 0 1.45258 0.189656 1.05671 0.568966C0.660829 0.925964 0.462891 1.50608 0.462891 2.30933C0.462891 2.71095 0.512375 3.11258 0.611344 3.5142C0.735056 3.91582 0.883509 4.23935 1.05671 4.48479Z"
        fill={color}
      />
    </svg>
  );
};

export default MinusIcon;
