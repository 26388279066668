import { take, put } from "redux-saga/effects";
import { LOGIN, loginActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { setToken, setRefreshToken, setTokenExpDate } from "src/services/localStorage";
import { login, getUserInfoFromToken, getChildren } from "src/services/user";

// types
import { LoginRequestTypes, LoginResponseTypes, ChildrenResponseType } from "src/redux/actions/auth/types";
import { GetUserInfoResponseTypes } from "src/redux/actions/user/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchLoginRequest() {
  while (true) {
    const { payload }: IActionType<LoginRequestTypes> = yield take(LOGIN.REQUEST);

    const { onError, onSuccess, onParentSuccess, ...restPayload } = payload as LoginRequestTypes;

    try {
      const result: LoginResponseTypes = yield call(login, { ...restPayload, deviceId: uuidv4().toUpperCase() });
      let children: ChildrenResponseType[] | undefined;
      if (onParentSuccess) {
        const userInfoResponse: GetUserInfoResponseTypes = yield call(getUserInfoFromToken, {
          token: result.access_token,
          userId: result.UserId
        });
        const isParent = userInfoResponse.userTypeId === 3;
        if (isParent) {
          children = yield call(getChildren, result.access_token);
        }
      }
      setToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setTokenExpDate(result[".expires"]);
      if (children && onParentSuccess) {
        onParentSuccess(children, result.refresh_token);
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        yield put(loginActions.success({ access_token: result.access_token, userId: result.UserId }));
        onSuccess(parseInt(result.UserId));
      }
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(loginActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchLoginRequest;
