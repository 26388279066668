import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";

// assets
import { CheckIcon, FalseIcon, QuestionIcon } from "src/assets/icons";

// types
import { ProgressTypes } from "./ProgressTypes";

// styles
import { StyledProgress } from "./Progress.styles";

const Progress = ({ total, current, answers }: ProgressTypes) => {
  const [t] = useTranslation();

  const getIcon = (index: number): ReactNode => {
    if (index == current - 1 && !answers[index]) {
      return <QuestionIcon />;
    }
    return answers[index].correct ? <CheckIcon /> : <FalseIcon />;
  };

  return (
    <StyledProgress>
      <div className="title">
        {t("label_question")}{" "}
        <span className="value">
          {current} / {total}
        </span>
      </div>
      <ul className="progress">
        {total &&
          Array.from({ length: total }, (item, index) => (
            <li
              key={index}
              className={`step ${index < current ? "active" : ""}`}
              style={{ width: `calc((100% - 16px * (${total} - 1))/ ${total} )` }}
            >
              {index < current && <span className="icon-wrapper">{getIcon(index)}</span>}
            </li>
          ))}
      </ul>
    </StyledProgress>
  );
};

export default Progress;
