import { reduce } from "lodash";
import http from "../http";
import {
  GetBookGamesRequestTypes,
  GetBookQuizRequestTypes,
  GetStudentAssignmentRequestTypes,
  SaveBookQuizRequestTypes,
  GameFeedbackType
} from "src/redux/actions/quiz/types";

export const getBookQuiz = ({ limit, charEnd, charStart, bookId }: GetBookQuizRequestTypes) => {
  return http(`api/books/${bookId}/quiz`, {
    params: { limit, charEnd, charStart },
    method: "GET"
  });
};

export const saveBookQuiz = ({ quizId, userId, reward, responseLog, readingHistoryId }: SaveBookQuizRequestTypes) => {
  return http(`api/game/${quizId}/submit`, {
    body: JSON.stringify({ reward, userId, responseLog, readingHistoryId }),
    method: "POST"
  });
};

export const postGameFeedback = (payload: GameFeedbackType) => {
  return http(`api/game/${payload.gameId}/feedback`, {
    body: JSON.stringify(payload.request),
    method: "POST"
  });
};

export const getQuizResultStatistic = ({ responseLog }: SaveBookQuizRequestTypes) => {
  return reduce(
    responseLog,
    (result, answer) => {
      return {
        reward: result.reward + answer.reward,
        correctQty: answer.correct ? result.correctQty + 1 : result.correctQty,
        wrongQty: answer.correct ? result.wrongQty : +result.wrongQty + 1
      };
    },
    { reward: 0, correctQty: 0, wrongQty: 0 }
  );
};

export const getStudentAssignments = (values: GetStudentAssignmentRequestTypes) => {
  return http("api/Challenges", {
    method: "GET",
    params: values
  });
};

export const getQuizDetails = ({ quizId, limit = 10 }: { quizId: number; limit: number }) => {
  return http(`api/Games/${quizId}`, {
    params: { limit },
    method: "GET"
  });
};

export const getBookGames = ({ bookId }: GetBookGamesRequestTypes) => {
  return http(`api/books/${bookId}/games`, {
    method: "GET"
  });
};

export const getPendingAssignmentCount = () => {
  return http("api/AssignmentCount", {
    method: "GET"
  });
};
