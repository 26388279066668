import { requestTypeCreator } from "src/helpers/index";
import {
  GetBookQuizRequestTypes,
  GetBookQuizResponseTypes,
  SaveBookQuizRequestTypes,
  SaveBookQuizResponseTypes,
  GetStudentAssignmentResponseTypes,
  GetQuizDetailsResponseTypes,
  GetStudentAssignmentRequestTypes,
  GetBookGamesRequestTypes,
  GetBookGamesResponseTypes,
  GetPendingAssignmentsCountResponseTypes,
  GameFeedbackType
} from "./types";

export const GET_BOOK_QUIZ = requestTypeCreator("GET_BOOK_QUIZ");
export const SAVE_BOOK_QUIZ = requestTypeCreator("SAVE_BOOK_QUIZ");
export const POST_GAME_FEEDBACK = requestTypeCreator("POST_GAME_FEEDBACK");
export const GET_STUDENT_ASSIGNMENTS = requestTypeCreator("GET_STUDENT_ASSIGNMENTS");
export const GET_QUIZ_DETAILS = requestTypeCreator("GET_QUIZ_DETAILS");
export const GET_BOOK_GAMES = requestTypeCreator("GET_BOOK_GAMES");
export const GET_PENDING_ASSIGNMENTS_COUNT = requestTypeCreator("GET_PENDING_ASSIGNMENTS_COUNT");
export const GET_NEW_OVERDUE_ASSIGNMENTS = "GET_NEW_OVERDUE_ASSIGNMENTS";
export const SET_OVERDUE_ASSIGNMENTS = "SET_OVERDUE_ASSIGNMENTS";
export const SET_NEW_ASSIGNMENTS = "SET_NEW_ASSIGNMENTS";
export const RESET_BOOK_GAMES = "RESET_BOOK_GAMES";

export const getBookQuizActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_QUIZ.ERROR
  }),
  request: (payload: GetBookQuizRequestTypes): IActionType<GetBookQuizRequestTypes> => ({
    payload,
    type: GET_BOOK_QUIZ.REQUEST
  }),
  success: (payload: GetBookQuizResponseTypes): IActionType<GetBookQuizResponseTypes> => ({
    payload,
    type: GET_BOOK_QUIZ.SUCCESS
  })
};

export const saveBookQuizActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: SAVE_BOOK_QUIZ.ERROR
  }),
  request: (payload: SaveBookQuizRequestTypes): IActionType<SaveBookQuizRequestTypes> => ({
    payload,
    type: SAVE_BOOK_QUIZ.REQUEST
  }),
  success: (payload: SaveBookQuizResponseTypes): IActionType<SaveBookQuizResponseTypes> => ({
    payload,
    type: SAVE_BOOK_QUIZ.SUCCESS
  })
};

export const postGameFeedbackActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: POST_GAME_FEEDBACK.ERROR
  }),
  request: (payload: GameFeedbackType): IActionType<GameFeedbackType> => ({
    payload,
    type: POST_GAME_FEEDBACK.REQUEST
  }),
  success: () => ({
    type: POST_GAME_FEEDBACK.SUCCESS
  })
};

export const getStudentAssignmentsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_STUDENT_ASSIGNMENTS.ERROR
  }),
  request: (payload: any): IActionType<GetStudentAssignmentRequestTypes> => {
    return {
      payload,
      type: GET_STUDENT_ASSIGNMENTS.REQUEST
    };
  },
  success: (payload: GetStudentAssignmentResponseTypes): IActionType<GetStudentAssignmentResponseTypes> => ({
    payload,
    type: GET_STUDENT_ASSIGNMENTS.SUCCESS
  })
};

export const getQuizDetailsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_QUIZ_DETAILS.ERROR
  }),
  request: (payload: number) => {
    return {
      payload,
      type: GET_QUIZ_DETAILS.REQUEST
    };
  },
  success: (payload: GetQuizDetailsResponseTypes): IActionType<GetQuizDetailsResponseTypes> => ({
    payload,
    type: GET_QUIZ_DETAILS.SUCCESS
  })
};

export const getBookGamesActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_GAMES.ERROR
  }),
  request: (payload: GetBookGamesRequestTypes): IActionType<GetBookGamesRequestTypes> => ({
    payload,
    type: GET_BOOK_GAMES.REQUEST
  }),
  success: (payload: GetBookGamesResponseTypes): IActionType<GetBookGamesResponseTypes> => ({
    payload,
    type: GET_BOOK_GAMES.SUCCESS
  })
};

export const getPendingAssignmentsCountActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_PENDING_ASSIGNMENTS_COUNT.ERROR
  }),
  request: (payload: any): IActionType<GetPendingAssignmentsCountResponseTypes> => {
    return {
      payload,
      type: GET_PENDING_ASSIGNMENTS_COUNT.REQUEST
    };
  },
  success: (
    payload: GetPendingAssignmentsCountResponseTypes
  ): IActionType<GetPendingAssignmentsCountResponseTypes> => ({
    payload,
    type: GET_PENDING_ASSIGNMENTS_COUNT.SUCCESS
  })
};

export const setNewAssignmentsAction = (
  payload: GetStudentAssignmentResponseTypes
): IActionType<GetStudentAssignmentResponseTypes> => ({
  payload,
  type: SET_NEW_ASSIGNMENTS
});

export const setOverdueAssignmentsAction = (
  payload: GetStudentAssignmentResponseTypes
): IActionType<GetStudentAssignmentResponseTypes> => ({
  payload,
  type: SET_OVERDUE_ASSIGNMENTS
});

export const getNewOverdueAssignmentsAction = (payload: any): IActionType<GetStudentAssignmentRequestTypes> => {
  return {
    payload,
    type: GET_NEW_OVERDUE_ASSIGNMENTS
  };
};

export const resetBookGamesAction = {
  type: RESET_BOOK_GAMES
};
