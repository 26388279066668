import { take, put } from "redux-saga/effects";
import { PARENT_PROFILE_UPDATE, parentProfileUpdate } from "src/redux/actions/nota";

// services
import { getEmailAvailability } from "src/services/nota";

// types
import { UpdateParentProfileRequest } from "src/redux/actions/nota/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchUpdateParentProfile() {
  while (true) {
    const { payload }: IActionType<UpdateParentProfileRequest> = yield take(PARENT_PROFILE_UPDATE.REQUEST);

    try {
      const result: boolean = yield call(getEmailAvailability, { email: payload.email });
      yield put(parentProfileUpdate.success({ email: payload.email, isAvailible: result, password: payload.password }));
    } catch (e) {
      yield put(parentProfileUpdate.error(e as any));
    }
  }
}

export default watchUpdateParentProfile;
