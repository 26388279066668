import React from "react";

// types
import { HeadingH2PropsType } from "./HeadingH2Types";

// styles
import { StyledHeading } from "./HeadingH2.styles";

const HeadingH2 = ({ title, align = "left", color = "#01324B" }: HeadingH2PropsType) => {
  return (
    <StyledHeading color={color} align={align}>
      {title}
    </StyledHeading>
  );
};

export default HeadingH2;
