import { take, put } from "redux-saga/effects";
import { loginActions, MASQUERADE, masqueradeAuthActions } from "src/redux/actions/auth";

// services
import { setToken, setRefreshToken, setTokenExpDate } from "src/services/localStorage";
import { masquerade } from "src/services/user";

// types
import { LoginResponseTypes, MasqueradeRequestTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchMasqueradeRequest() {
  while (true) {
    const { payload }: IActionType<MasqueradeRequestTypes> = yield take(MASQUERADE.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as MasqueradeRequestTypes;

    try {
      // Constructing the URL and calling fetch inside a saga
      const { childId, parentToken } = restPayload;

      const result: LoginResponseTypes = yield call(masquerade, {
        childId: childId,
        parentToken: parentToken
      });

      setToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setTokenExpDate(result[".expires"]);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(loginActions.success({ access_token: result.access_token, userId: result.UserId }));
      onSuccess(parseInt(result.UserId));
    } catch (e) {
      console.error("Error in watchUniloginRequest saga:", e);
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(masqueradeAuthActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchMasqueradeRequest;
