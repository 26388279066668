import { requestTypeCreator } from "src/helpers";

export const ACCEPT_ASSIGNMENT = requestTypeCreator("ACCEPT_ASSIGNMENT");

export const acceptAssignmentActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: ACCEPT_ASSIGNMENT.ERROR
  }),
  request: (payload: { challengeId: number; onSuccess?: () => void }) => ({
    payload,
    type: ACCEPT_ASSIGNMENT.REQUEST
  }),
  success: () => ({
    type: ACCEPT_ASSIGNMENT.SUCCESS
  })
};
