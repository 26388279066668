import React from "react";
import { IconPropsType } from "./IconTypes";

const StarIcon = ({ color = "#EFD43D", width = 13, height = 12 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.66444 0.757248C6.51569 0.46749 6.1015 0.46749 5.95275 0.757249L4.39683 3.78804L0.616311 4.40172C0.287676 4.45507 0.164245 4.86311 0.408211 5.08966L3.18735 7.67047L2.32249 11.1074C2.23788 11.4436 2.59189 11.7209 2.89806 11.5582L6.07402 9.871C6.22069 9.79307 6.39649 9.79307 6.54317 9.871L9.71913 11.5582C10.0253 11.7209 10.3793 11.4436 10.2947 11.1074L9.50206 7.95748C9.45753 7.78048 9.51297 7.59328 9.64671 7.46908L12.209 5.08966C12.4529 4.86311 12.3295 4.45507 12.0009 4.40172L8.46969 3.82852C8.31291 3.80307 8.17754 3.70464 8.105 3.56333L6.66444 0.757248Z"
        fill={color}
      />
    </svg>
  );
};

export default StarIcon;
