import { take, put } from "redux-saga/effects";
import { GET_LENGTH_LIST, getLengthListActions } from "src/redux/actions/book";

// services
import { getTagList } from "src/services/book";

// types
import { GetDataLengthListResponseTypes, GetTagListRequestTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetLengthListRequest() {
  while (true) {
    const { payload }: IActionType<GetTagListRequestTypes> = yield take(GET_LENGTH_LIST.REQUEST);

    try {
      const result: GetDataLengthListResponseTypes = yield call(getTagList, payload);
      yield put(getLengthListActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getLengthListActions.error(e as any));
    }
  }
}

export default watchGetLengthListRequest;
