import React, { useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import {
  getRefreshToken,
  getTokenExpDate,
  getToken,
  setToken,
  setRefreshToken,
  setTokenExpDate,
  signOutUser
} from "src/services/localStorage";
import { updateRefreshToken } from "src/services/user";
import { useSelector } from "react-redux";

// types
import { PrivateRouteProps } from "./PrivateRouteTypes";
import { RootReducer } from "src/redux/reducers";

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const navigate = useNavigate();

  useEffect(() => {
    const minute = 60000;

    const refreshUserToken = () => {
      if (getToken() && getRefreshToken()) {
        const expDate = getTokenExpDate();
        if (expDate) {
          const tokenDate = new Date(expDate);
          const currentDate = new Date();

          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          const dateDifferenceInMinutes = Math.round((tokenDate - currentDate) / 60000);

          if (dateDifferenceInMinutes < 60) {
            updateRefreshToken({ refreshToken: getRefreshToken() })
              .then((response: any) => {
                if (response.refresh_token) {
                  setToken(response.access_token);
                  setRefreshToken(response.refresh_token);
                  setTokenExpDate(response[".expires"]);
                } else {
                  signOutUser();
                  navigate("/login");
                }
              })
              .catch(() => {
                signOutUser();
                navigate("/login");
              });
          }
        }
      }
    };

    setTimeout(() => {
      refreshUserToken();
    }, 1000);

    setInterval(refreshUserToken, minute * 5);
  }, []);

  const {
    auth: { authData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  if (!authData?.userId || !getToken()) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
