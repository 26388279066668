export const size = {
  desktop: "1920px",
  desktopS: "1400px",
  tabletXL: "1200px",
  tabletL: "1024px",
  tablet: "768px",
  mobileL: "600px",
  mobileM: "375px"
};

export const device = {
  desktop: `(max-width: ${size.desktop})`,
  desktopS: `(max-width: ${size.desktopS})`,
  tabletL: `(max-width: ${size.tabletL})`,
  tabletXL: `(max-width: ${size.tabletXL})`,
  tablet: `(max-width: ${size.tablet})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileM: `(max-width: ${size.mobileM})`
};
