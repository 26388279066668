import { take, put } from "redux-saga/effects";
import { CREATE_NOTA_USER_WITH_EXISTING_PARENT, notaSignupWithExistingParentActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { login, createChildUser, assignChildToParent } from "src/services/user";

// types
import { LoginResponseTypes, NotaSignupExistingParentParams, ChilUserRequestTypes } from "src/redux/actions/auth/types";

// hepers
import { mapNotaSignupChildParamsToRequest } from "../../helpers/mapNotaSignupParamsToRequestTypes";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchCreateUniloginUserWithExistingParentRequest() {
  while (true) {
    const { payload }: IActionType<NotaSignupExistingParentParams> = yield take(
      CREATE_NOTA_USER_WITH_EXISTING_PARENT.REQUEST
    );

    try {
      const childPayload: ChilUserRequestTypes = mapNotaSignupChildParamsToRequest(payload);

      const signupResult: { user: any } = yield call(createChildUser, childPayload);
      if (!signupResult.user || !signupResult.user.id) {
        throw new Error("Signup failed");
      }
      const assignChildResult: string = yield call(assignChildToParent, {
        parentId: payload.parentAuth.UserId,
        parentToken: payload.parentAuth.access_token,
        childId: signupResult.user.id
      });
      if (!assignChildResult) {
        throw new Error("Assign child to parent failed");
      }
      const loginResult: LoginResponseTypes = yield call(login, {
        username: childPayload.userName,
        password: childPayload.password,
        deviceId: uuidv4().toUpperCase()
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(notaSignupWithExistingParentActions.success(loginResult));
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(notaSignupWithExistingParentActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchCreateUniloginUserWithExistingParentRequest;
