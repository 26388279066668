import React from "react";
import { useTranslation } from "react-i18next";

// types
import { TimeLabelPropsType } from "./TimeLabelTypes";

// styles
import { StyledLabel } from "./TimeLabel.styles";

const TimeLabel = ({ type, value, textLabel }: TimeLabelPropsType) => {
  const [t] = useTranslation();

  return (
    <StyledLabel style={{ backgroundColor: type === "time-left" ? "#FFB400" : "#9A52BB" }}>
      <span className="type">
        {textLabel ? textLabel : type === "time-left" ? t("assignment_card_timeleft") : t("assignments_submit_date")}
      </span>
      <span className="value">{value}</span>
    </StyledLabel>
  );
};

export default TimeLabel;
