import { combineReducers } from "redux";

import auth, { AuthState } from "./auth";
import nota, { NotaState } from "./nota";
import shop, { ShopState } from "./shop";
import dragon, { DragonState } from "./dragon";
import book, { BookState } from "./book";
import bag, { BagState } from "./bag";
import user, { UserState } from "./user";
import users, { UsersState } from "./users";
import friends, { FriendsState } from "./friends";
import quiz, { QuizState } from "./quiz";
import leaderboard, { LeaderboardState } from "./leaderboard";

export interface RootReducer {
  auth: AuthState;
  nota: NotaState;
  shop: ShopState;
  dragon: DragonState;
  book: BookState;
  bag: BagState;
  user: UserState;
  users: UsersState;
  friends: FriendsState;
  quiz: QuizState;
  leaderboard: LeaderboardState;
}

const reducers = {
  auth,
  nota,
  book,
  shop,
  dragon,
  bag,
  user,
  users,
  friends,
  quiz,
  leaderboard
};

export default combineReducers<RootReducer>(reducers);
