import * as React from "react";
import { StyledRange } from "./Range.styles";
import { RangePropsType } from "./RangeTypes";
import { getTrackBackground } from "react-range";

const Range = ({ min = 0, max = 100, step = 1, values, onChange, renderMark, ...props }: RangePropsType) => {
  return (
    <StyledRange
      step={step}
      min={min}
      max={max}
      values={values}
      onChange={(values) => onChange(values[0])}
      renderTrack={({ props, children }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "6px",
            width: "100%",
            background: getTrackBackground({
              values: values,
              colors: ["#165B95", "#AED5F1"],
              min,
              max
            })
          }}
        >
          {children}
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: "20px",
            width: "20px",
            backgroundColor: "#165B95",
            borderRadius: "50%",
            border: "2px solid #F2F9FF",
            boxShadow: "none",
            outline: "none"
          }}
        />
      )}
      renderMark={(params) => (renderMark ? renderMark(params) : null)}
      {...props}
    />
  );
};

export default Range;
