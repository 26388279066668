import React, { useCallback, useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

// assets
import { BarsIcon, CrossIcon } from "src/assets/icons";

// components
import HeaderProfile from "./headerProfile";

// helpers
import { useScreenWidth } from "src/helpers";
import flagSmithIdentification from "src/helpers/flagSmithIdentification";

// utils
import { RootReducer } from "src/redux/reducers";
import { getUserInfoActions } from "src/redux/actions/user";

// assets
import coinImg from "src/assets/images/money.svg";
import diamondImg from "src/assets/images/diamond.png";

// types
import { HeaderPropTypes } from "./HeaderTypes";

// styles
import { StyledHeader } from "./Header.styles";

const Header = ({ children }: HeaderPropTypes) => {
  const [screenWidth] = useScreenWidth();
  const [navIsOpened, setNavIsOpened] = useState<boolean>(false);
  const dispatch = useDispatch();
  const flagSmithHelper = flagSmithIdentification();

  const {
    auth: {
      authData: { userId }
    },
    user: { userData },
    book: { bookStatistics }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getUserInfo = useCallback(() => dispatch(getUserInfoActions.request({ userId: userId || "" })), [dispatch]);

  useEffect(() => {
    if (!userData) {
      getUserInfo();
    } else {
      flagSmithHelper.identify(userData.id, userData.schoolId);
    }
  }, []);

  useEffect(() => {
    getUserInfo();
  }, [bookStatistics]);

  return (
    <StyledHeader>
      <div className="header-left">
        <HeaderProfile name={userData?.name} userImageUrl={userData?.profileImageUrl} />
      </div>
      {children && <div className={`${navIsOpened ? "visible" : ""} header-center`}>{children}</div>}
      <div className="header-right">
        <ul className="earnings-list">
          <li className="earning-item">
            <span className="earning-image">
              <img src={coinImg} alt="coins" />
            </span>
            <span>{userData?.level?.freeStatPoints ? userData?.level?.freeStatPoints : 0}</span>
          </li>
          <li className="earning-item">
            <span className="earning-image">
              <img src={diamondImg} alt="diamonds" />
            </span>
            <span>{userData?.level?.freeEquipPoints ? userData?.level?.freeEquipPoints : 0}</span>
          </li>
        </ul>
        {screenWidth <= 480 && children && (
          <button type="button" className="mobile-nav-toggle" onClick={() => setNavIsOpened(!navIsOpened)}>
            {navIsOpened ? <CrossIcon /> : <BarsIcon />}
          </button>
        )}
      </div>
    </StyledHeader>
  );
};

export default Header;
