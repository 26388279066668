import { GET_BAG_ITEMS, CONSUME_FOOD, DRESS_ITEM, UNDRESS_ITEM } from "src/redux/actions/bag";
import { GetBagItemsResponseTypes } from "src/redux/actions/bag/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: BagState = {
  isLoading: false,
  bagList: undefined,
  error: undefined
};

export interface BagState {
  isLoading: boolean;
  bagList: GetBagItemsResponseTypes | undefined;
  error: any;
}

const bag = (state = initialState, action: IActionType<GetBagItemsResponseTypes>): BagState => {
  switch (action.type) {
    case GET_BAG_ITEMS.REQUEST:
    case CONSUME_FOOD.REQUEST:
    case DRESS_ITEM.REQUEST:
    case UNDRESS_ITEM.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case GET_BAG_ITEMS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        bagList: action.payload as GetBagItemsResponseTypes
      };

    case CONSUME_FOOD.SUCCESS:
    case DRESS_ITEM.SUCCESS:
    case UNDRESS_ITEM.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case GET_BAG_ITEMS.ERROR:
    case CONSUME_FOOD.ERROR:
    case DRESS_ITEM.ERROR:
    case UNDRESS_ITEM.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default bag;
