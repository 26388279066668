import styled from "styled-components";

export const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.blueLight3};
  border: 1px solid ${({ theme }) => theme.colors.blueLight2};
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  height: 41px;
  width: 41px;

  &:hover {
    filter: brightness(0.97);
  }
`;
