import React from "react";
import { IconPropsType } from "./IconTypes";

const UserGroupIcon = ({ color = "#38A6DE", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 24 24">
      <path
        stroke={color}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M17 21v-2a4 4 0 00-4-4H5a4 4 0 00-4 4v2M9 11a4 4 0 100-8 4 4 0 000 8zM23 21.003v-2a4 4 0 00-3-3.87M16 3.133a4 4 0 010 7.75"
      ></path>
    </svg>
  );
};

export default UserGroupIcon;
