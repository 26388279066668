import { take, put } from "redux-saga/effects";
import { GET_READING_REWARDS, getReadingRewardsActions } from "src/redux/actions/book";

// services
import { getReadingRewards } from "src/services/book";

// types
import { GetReadingRewardsRequestTypes, GetReadingRewardsResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetReadingRewardsRequest() {
  while (true) {
    const { payload }: IActionType<GetReadingRewardsRequestTypes> = yield take(GET_READING_REWARDS.REQUEST);

    try {
      const result: GetReadingRewardsResponseTypes = yield call(getReadingRewards, payload);
      yield put(getReadingRewardsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getReadingRewardsActions.error(e as any));
    }
  }
}

export default watchGetReadingRewardsRequest;
