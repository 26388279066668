import { take, put } from "redux-saga/effects";
import { GET_QUIZ_DETAILS, getQuizDetailsActions } from "src/redux/actions/quiz";

// services
import { getQuizDetails } from "src/services/quiz";

// types
import { GetQuizDetailsResponseTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchQuizDetailsRequest() {
  while (true) {
    const { payload } = yield take(GET_QUIZ_DETAILS.REQUEST);

    try {
      const result: GetQuizDetailsResponseTypes = yield call(getQuizDetails, { quizId: payload });
      yield put(getQuizDetailsActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getQuizDetailsActions.error(e as any));
    }
  }
}

export default watchQuizDetailsRequest;
