import { take, put } from "redux-saga/effects";
import { GET_BOOK_QUIZ, getBookQuizActions } from "src/redux/actions/quiz";

// services
import { getBookQuiz } from "src/services/quiz";

// types
import { GetBookQuizResponseTypes, GetBookQuizRequestTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetBookQuizRequest() {
  while (true) {
    const { payload }: IActionType<GetBookQuizRequestTypes> = yield take(GET_BOOK_QUIZ.REQUEST);

    try {
      const result: GetBookQuizResponseTypes = yield call(getBookQuiz, payload);
      yield put(getBookQuizActions.success(result));
    } catch (e) {
      yield put(getBookQuizActions.error(e as any));
    }
  }
}

export default watchGetBookQuizRequest;
