import styled from "styled-components";

export const StyledError = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.colors.pink};
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.red3};
  display: flex;
  justify-content: space-between;
  font-size: 17px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
  margin: 10px 0 0;
  padding: 6px 16px;
  min-width: 300px;
  width: fit-content;

  .icon-wrapper {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.red3};
    border-radius: 50%;
    height: 24px;
    display: inline-flex;
    justify-content: center;
    margin: 0 0 0 20px;
    width: 24px;
  }
`;
