import { take, put } from "redux-saga/effects";
import { GET_SHOP_ITEMS, getShopItemsActions } from "src/redux/actions/shop/index";

// services
import { getShopItems } from "src/services/shop";

// types
import { GetShopItemsRequestTypes, GetShopItemsResponseTypes } from "src/redux/actions/shop/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetShopItemsRequest() {
  while (true) {
    const { payload }: IActionType<GetShopItemsRequestTypes> = yield take(GET_SHOP_ITEMS.REQUEST);

    try {
      const result: GetShopItemsResponseTypes = yield call(getShopItems, { userId: payload });
      yield put(getShopItemsActions.success(result));
    } catch (e) {
      yield put(getShopItemsActions.error(e as any));
    }
  }
}

export default watchGetShopItemsRequest;
