import { take, put } from "redux-saga/effects";
import { ACCEPT_ASSIGNMENT, acceptAssignmentActions } from "src/redux/actions/assignments";

// services
import { acceptAssignment } from "src/services/assignments";
import { getQuizDetails, getStudentAssignments } from "src/services/quiz";
import { getStudentAssignmentsActions } from "src/redux/actions/quiz";

// types
import { GetQuizDetailsResponseTypes, GetStudentAssignmentResponseTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
import { GetBookListResponseTypes } from "../../actions/book/types";
import { getBookList } from "../../../services/book";
const call: any = Effects.call;

function* watchAcceptAssignmentRequest() {
  const assignmentsList: any = [];

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sortByPublishDateDescending = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.sort((a, b) => {
      const dateA = new Date(a.publishDate);
      const dateB = new Date(b.publishDate);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  };

  function* processItem(item: any) {
    const bookData: GetBookListResponseTypes = yield call(getBookList, { limit: 1, offset: 0, ids: [item.bookId] });

    if (item.gameId) {
      const quizData: GetQuizDetailsResponseTypes = yield call(getQuizDetails, { quizId: item.gameId });
      assignmentsList.push({ ...item, quizData: quizData, bookData: bookData.books[0] });
    } else {
      assignmentsList.push({ ...item, bookData: bookData.books[0] });
    }
  }

  while (true) {
    const { payload } = yield take(ACCEPT_ASSIGNMENT.REQUEST);
    const { onSuccess, challengeId } = payload;

    try {
      yield call(acceptAssignment, challengeId);
      yield put(acceptAssignmentActions.success());

      const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments);

      sortByPublishDateDescending(result);
      for (const item of result) {
        yield* processItem(item);
      }

      yield put(getStudentAssignmentsActions.success(assignmentsList));
      onSuccess();
    } catch (e) {
      yield put(acceptAssignmentActions.error(e as any));
    }
  }
}

export default watchAcceptAssignmentRequest;
