import { call, put, take } from "redux-saga/effects";

// services
import { inviteFriend } from "src/services/friends";

// types
import { InviteFriendRequestTypes, InviteFriendResponseTypes } from "src/redux/actions/friends/types";
import { INVITE_FRIEND, inviteFriendActions } from "src/redux/actions/friends";
import { updateInviteStatus } from "src/redux/actions/users";

function* watchInviteFriendRequest() {
  while (true) {
    const { payload }: IActionType<InviteFriendRequestTypes> = yield take(INVITE_FRIEND.REQUEST);
    yield put(updateInviteStatus.success({ id: payload.userId, hasPendingInvite: true }));
    try {
      const result: InviteFriendResponseTypes = yield call(inviteFriend, payload);
      if (result) {
        yield put(inviteFriendActions.success(result));
      }
    } catch (e) {
      yield put(inviteFriendActions.error(e as any));
    }
  }
}

export default watchInviteFriendRequest;
