import http from "../http";
import { ConsumeFoodRequestTypes, DressItemRequestTypes } from "src/redux/actions/bag/types";

export const getBagItems = ({ userId }: { userId: number }) => {
  return http(`api/users/${userId}/bag`, {
    method: "GET",
    params: { userId, refresh: true }
  });
};

export const consumeFood = ({ itemId, dragonId, transactionId, userId }: ConsumeFoodRequestTypes) => {
  return http(`api/dragon/${dragonId}/consume`, {
    method: "POST",
    body: JSON.stringify({ itemId, transactionId }),
    params: { userId }
  });
};

export const dressItem = ({ itemId, dragonId, transactionId, timestamp, userId }: DressItemRequestTypes) => {
  return http(`api/dragon/${dragonId}/dress`, {
    method: "POST",
    body: JSON.stringify({ itemId, transactionId, timestamp }),
    params: { userId }
  });
};

export const undressItem = ({ itemId, dragonId, transactionId, timestamp, userId }: DressItemRequestTypes) => {
  return http(`api/dragon/${dragonId}/undress`, {
    method: "POST",
    body: JSON.stringify({ itemId, transactionId, timestamp }),
    params: { userId }
  });
};
