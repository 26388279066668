import { take, put } from "redux-saga/effects";
import { getReadingRewardsActions, UPDATE_BOOK_STATISTICS, updateBookStatisticsActions } from "src/redux/actions/book";

// services
import { updateBookStatistics } from "src/services/book";

// types
import { UpdateBookStatisticsRequestTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchUpdateBookStatisticsRequest() {
  while (true) {
    const { payload }: IActionType<UpdateBookStatisticsRequestTypes> = yield take(UPDATE_BOOK_STATISTICS.REQUEST);

    const { onSuccess, ...restPayload } = payload as any;

    try {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const result: any = yield call(updateBookStatistics, restPayload);

      if (result.readingSessionId) {
        yield put(updateBookStatisticsActions.success(result));
        onSuccess && onSuccess(result.readingSessionId);

        yield put(getReadingRewardsActions.request({ readingHistoryId: result.userReadingHistoryId }));
      }
    } catch (e) {
      console.log(e, "error");
      yield put(updateBookStatisticsActions.error(e as any));
    }
  }
}

export default watchUpdateBookStatisticsRequest;
