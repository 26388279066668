import React from "react";
import { IconPropsType } from "./IconTypes";

const BackIcon = ({ color = "#fff", width = 16, height = 13 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 16 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.84 2.53738C9.824 2.66538 9.8 2.77738 9.768 2.87338L6.28351 4.62123C9.41229 4.15273 13.8272 3.62699 15.0877 4.16138C16.4953 4.75815 15.9835 7.66129 14.9762 7.66138C14.0322 7.66147 9.59369 7.46631 6.39521 7.31982L8.088 8.08138C8.632 8.35338 9.048 8.64938 9.336 8.96938C9.608 9.27338 9.744 9.70538 9.744 10.2654C9.744 10.6814 9.608 11.0734 9.336 11.4414C9.048 11.7934 8.512 12.0734 7.728 12.2814L1.128 8.44138C0.76 8.23338 0.48 7.94538 0.288 7.57738C0.0960002 7.20938 0 6.68938 0 6.01738C0 5.12138 0.208 4.40138 0.624 3.85738L5.784 0.881382C6.264 0.609382 6.672 0.401382 7.008 0.257382C7.328 0.113382 7.656 0.0413818 7.992 0.0413818C8.344 0.0413818 8.64 0.105382 8.88 0.233381C9.104 0.345382 9.288 0.497382 9.432 0.689382C9.576 0.881382 9.68 1.10538 9.744 1.36138C9.808 1.61738 9.84 1.88938 9.84 2.17738V2.53738Z"
        fill={color}
      />
    </svg>
  );
};

export default BackIcon;
