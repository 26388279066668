import React, { useRef } from "react";
import { RootReducer } from "../../../../redux/reducers";
import { useSelector, useDispatch } from "react-redux";

import { updateNotaFlowStep, getParentAuthUserActions } from "../../../../redux/actions/nota";

import CprValidationStep from "./cprValidationStep/CprValidationStep";
import ParentProfileStep from "./parentProfileStep/ParentProfileStep";
import NotaUniloginStep from "./notaUniloginStep/NotaUniloginStep";
import ChildNameStep from "./childNameStep/ChildNameStep";
import FinalNotaSignupWebStep from "./finalNotaSignupStep/FinalNotaSignupWebStep";
import FinalNotaSignupMobileStep from "./finalNotaSignupStep/FinalNotaSignupMobileStep";
import ParentEmailStep from "./parentEmailStep/ParentEmailStep";
import OTPCodeForm from "../../forgotPassword/otpCodeForm/OTPCodeForm";
import ForgotPasswordForm from "../../forgotPassword/forgotPasswordForm";

import { isIOS, isAndroid } from "react-device-detect";
import { LoginResponseTypes } from "src/redux/actions/auth/types";

const NotaFlowContainer = () => {
  const dispatch = useDispatch();
  const emailToRecover = useRef<string | undefined>(undefined);
  const {
    nota: { currentStepIdx }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const goToNextStep = () => {
    // Update the current step in the Redux store
    dispatch(updateNotaFlowStep(currentStepIdx + 1));
  };

  const goToUnilogin = () => {
    dispatch(updateNotaFlowStep(3));
  };

  const onPasswordRecovery = (email?: string) => {
    emailToRecover.current = email;
    dispatch(updateNotaFlowStep(11));
  };

  const handlePassResetResult = (email: string) => {
    emailToRecover.current = email;
    goToNextStep();
  };

  const handleOtpResult = (authResult: LoginResponseTypes) => {
    dispatch(
      getParentAuthUserActions.success({
        auth: authResult,
        email: emailToRecover.current ?? ""
      })
    );
    dispatch(updateNotaFlowStep(3));
  };

  const renderStep = () => {
    switch (currentStepIdx) {
      case 0:
        return <CprValidationStep onNext={goToNextStep} />;
      case 1:
        return (
          <ParentEmailStep onNext={goToNextStep} onUnilogin={goToUnilogin} onPasswordRecovery={onPasswordRecovery} />
        );
      case 2:
        return <ParentProfileStep onNext={goToNextStep} />;
      case 3:
        return <NotaUniloginStep onNext={goToNextStep} />;
      case 4:
        return <ChildNameStep onNext={goToNextStep} />;
      case 5:
        return isAndroid || isIOS ? (
          <FinalNotaSignupMobileStep onNext={goToNextStep} />
        ) : (
          <FinalNotaSignupWebStep onNext={goToNextStep} />
        );
      case 11:
        return <ForgotPasswordForm email={emailToRecover.current} onNext={handlePassResetResult} />;
      case 12:
        return <OTPCodeForm email={emailToRecover.current ?? ""} onNext={handleOtpResult} />;
      default:
        // Optionally handle an unknown step
        return <div>Unknown step</div>;
    }
  };

  return <div>{renderStep()}</div>;
};

export default NotaFlowContainer;
