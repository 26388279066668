import http from "../http";
import {
  GetFriendsRequestTypes,
  GetInvitesRequestTypes,
  GetPendingInvitesRequestTypes,
  AcceptFriendRequestTypes,
  DeclineFriendRequestTypes,
  InviteFriendRequestTypes,
  RemoveFriendRequestTypes
} from "src/redux/actions/friends/types";

export const getFriends = (values: GetFriendsRequestTypes) => {
  return http(`api/Users/${values.id}/Friends`, {
    params: values,
    method: "GET"
  });
};

export const getInvites = (values: GetInvitesRequestTypes) => {
  return http(`api/Users/${values.id}/Invitations`, {
    params: values,
    method: "GET"
  });
};

export const getPendingInvites = (values: GetPendingInvitesRequestTypes) => {
  return http(`api/Users/${values.id}/PendingInvites`, {
    params: values,
    method: "GET"
  });
};

export const inviteFriend = (values: InviteFriendRequestTypes) => {
  return http(`api/Users/${values.id}/InviteFriend`, {
    params: values,
    method: "POST"
  });
};

export const acceptFriend = (values: AcceptFriendRequestTypes) => {
  return http(`api/Users/${values.id}/AcceptFriend`, {
    params: values,
    method: "POST"
  });
};

export const declineFriend = (values: DeclineFriendRequestTypes) => {
  return http(`api/Users/${values.id}/DeclineFriend`, {
    params: values,
    method: "POST"
  });
};

export const removeFriend = (values: RemoveFriendRequestTypes) => {
  return http(`api/Users/${values.id}/RemoveFriend`, {
    params: values,
    method: "POST"
  });
};
