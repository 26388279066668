import React, { lazy } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { PrivateRoute } from "src/components";

const NotFound = lazy(() => import("src/components/NotFound"));
const SSO = lazy(() => import("src/modules/auth/sso"));
const Login = lazy(() => import("src/modules/auth/login"));
import Nota from "../modules/auth/nota/index";
const UniloginResult = lazy(() => import("src/modules/auth/unilogin"));
const ForgotPassword = lazy(() => import("src/modules/auth/forgotPassword"));
const PostReading = lazy(() => import("src/modules/post"));
const Library = lazy(() => import("src/modules/library"));
const BookDetails = lazy(() => import("src/modules/bookDetails"));
const BookReading = lazy(() => import("src/modules/bookReading"));
const BookReadingPdf = lazy(() => import("src/modules/bookReadingPdf"));
const NonceReading = lazy(() => import("src/modules/nonceReading"));
const NonceAssignments = lazy(() => import("src/modules/nonceAssignments"));
const GrowingPath = lazy(() => import("src/modules/growingPath"));
const Quiz = lazy(() => import("src/modules/quiz"));
const EquipmentShop = lazy(() => import("src/modules/equipmentShop"));
const Dragon = lazy(() => import("src/modules/dragon"));
const Reader = lazy(() => import("src/modules/reader"));
const Assignments = lazy(() => import("src/modules/assignments"));
const Friends = lazy(() => import("src/modules/friends"));
const InviteFriends = lazy(() => import("src/modules/inviteFriends"));
const Leaderboard = lazy(() => import("src/modules/leaderboard"));

const AppRouter: React.FC = () => (
  <Routes>
    <Route path="/" element={<Navigate replace to="/growing-path" />} />
    <Route path="/sso" element={<SSO />} />
    <Route path="/login" element={<Login />} />
    <Route path="/notaSignup" element={<Nota />} />
    <Route path="/unilogin-result" element={<UniloginResult />} />
    <Route path="/forgot-password" element={<ForgotPassword />} />
    <Route path="/lms-book-reading/:id" element={<NonceReading />} />
    <Route path="/mobile-assignments" element={<NonceAssignments />} />
    <Route
      path="/library"
      element={
        <PrivateRoute>
          <Library />
        </PrivateRoute>
      }
    />
    <Route
      path="/book-reading/:id"
      element={
        <PrivateRoute>
          <BookReading />
        </PrivateRoute>
      }
    />
    <Route
      path="/book-reading-pdf/:id"
      element={
        <PrivateRoute>
          <BookReadingPdf />
        </PrivateRoute>
      }
    />
    <Route path="/quiz/:id" element={<Quiz />} />
    <Route
      path="/assignment/:id"
      element={
        <PrivateRoute>
          <Quiz />
        </PrivateRoute>
      }
    />
    <Route
      path="/library"
      element={
        <PrivateRoute>
          <Library />
        </PrivateRoute>
      }
    />
    <Route
      path="/book/:id"
      element={
        <PrivateRoute>
          <BookDetails />
        </PrivateRoute>
      }
    />
    <Route
      path="/quiz"
      element={
        <PrivateRoute>
          <Quiz />
        </PrivateRoute>
      }
    />
    <Route
      path="/post-reading/:id/:stage"
      element={
        <PrivateRoute>
          <PostReading />
        </PrivateRoute>
      }
    />
    <Route
      path="/growing-path"
      element={
        <PrivateRoute>
          <GrowingPath />
        </PrivateRoute>
      }
    />
    <Route
      path="/equipment-shop"
      element={
        <PrivateRoute>
          <EquipmentShop />
        </PrivateRoute>
      }
    />
    <Route
      path="/dragon"
      element={
        <PrivateRoute>
          <Dragon />
        </PrivateRoute>
      }
    />

    <Route
      path="/assignments"
      element={
        <PrivateRoute>
          <Assignments />
        </PrivateRoute>
      }
    />
    <Route
      path="/friends"
      element={
        <PrivateRoute>
          <Friends />
        </PrivateRoute>
      }
    />
    <Route
      path="/invite-friends"
      element={
        <PrivateRoute>
          <InviteFriends />
        </PrivateRoute>
      }
    />
    <Route
      path="/leaderboard"
      element={
        <PrivateRoute>
          <Leaderboard />
        </PrivateRoute>
      }
    />
    <Route path="/reader" element={<Reader />} />
    <Route path="/404" element={<NotFound />} />
    <Route path="*" element={<Navigate replace to="/404" />} />
  </Routes>
);

export default AppRouter;
