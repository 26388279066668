import { take, put } from "redux-saga/effects";
import { GET_UNILOGIN_USER, getUniloginUserActions } from "src/redux/actions/nota";

// services
import { getUniloginUser } from "src/services/user";
import { isUsernameTaken } from "src/services/nota";

// types
import { UniloginDataTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;
type FlexibleResponse = Partial<Record<string, any>>;

function* watchGetUniloginUserRequest() {
  while (true) {
    const { payload }: IActionType<UniloginDataTypes> = yield take(GET_UNILOGIN_USER.REQUEST);

    try {
      // Constructing the URL and calling fetch inside a saga
      const { state, code, session_state } = payload;
      const url = new URL("https://api.maneno.dk/uniauth2");
      url.searchParams.append("state", state);
      url.searchParams.append("session_state", session_state);
      url.searchParams.append("code", code);
      url.searchParams.append("fromWeb", "true");

      const response: Response = yield call(fetch, url.toString(), {
        method: "GET"
      });

      const validationResult: { lToken: string } = yield call([response, "json"]);

      if (!validationResult.lToken) {
        console.error("Validation failed: lToken is missing");
        throw new Error("Something went wrong, try again later");
      }

      const result: FlexibleResponse = yield call(getUniloginUser, { ltoken: validationResult.lToken });

      if (!result.userName) {
        console.error("User data retrieval failed: userName is missing");
        throw new Error("Something went wrong, try again later");
      }

      const isRegistered: boolean = yield call(isUsernameTaken, result.userName);
      if (isRegistered) {
        console.error("Account already exists for userName:", result.userName);
        throw new Error("web_nota_signup_account_exists");
      }
      yield put(getUniloginUserActions.success({ user: result, lToken: validationResult.lToken }));
    } catch (e) {
      console.error("An error occurred during the unilogin process:", e);
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(getUniloginUserActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchGetUniloginUserRequest;
