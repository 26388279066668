import React, { useState } from "react";
import { StyledBugReportButton } from "./QuizFeedback.styles";
import { StyledButton } from "../ButtonBlue/ButtonBlue.styles";
import { StyledModal } from "../ConfirmModal/ConfirmModal.styles";
import { CrossIcon } from "../../assets/icons";
import { ButtonPrimary, ButtonSecondary } from "../index";
import { useTranslation } from "react-i18next";
import HeadingH3 from "../HeadingH3";
import ButtonBlue from "../ButtonBlue";
import { Formik, Field, Form, FormikHelpers } from "formik";
import { LoaderSmall } from "src/components";

interface QuizFeedbackProps {
  handleFeedback: (type: number, feedback: string | null) => void;
}

const QuizFeedback = ({ handleFeedback }: QuizFeedbackProps) => {
  const [t] = useTranslation();
  const [isVisible, setIsVisible] = useState(false);
  const [step, setStep] = useState(1);

  const handleToggleModal = () => {
    setIsVisible((prev) => !prev);
    setStep(1);
  };

  const initialValues = {
    feedback: ""
  };

  interface Values {
    feedback: string;
  }

  const handleSubmit = (values: Values, { setSubmitting }: FormikHelpers<Values>) => {
    handleFeedback(4, values.feedback);
    setSubmitting(false);
    handleIncreaseStep();
  };

  const handleIncreaseStep = () => {
    setStep((prev) => prev + 1);
  };

  const handleFeedbackSubmit = (feedbackId: number) => {
    handleFeedback(feedbackId, null);
    setStep(4);
  };

  const openCustomFeedback = () => {
    handleIncreaseStep();
  };

  return (
    <>
      <StyledModal className={`${isVisible ? "visible" : ""}`}>
        <div className="modal-overlay" onClick={handleToggleModal} />
        <div className="modal">
          <div className="modal-inner">
            <div className="modal-action">
              <button type="button" className="btn-modal-close" onClick={handleToggleModal}>
                <CrossIcon />
              </button>
            </div>

            {step === 1 ? (
              <>
                <div className="modal-content">
                  <HeadingH3 title={t("quiz_feedback_title_step_1_title")} />
                  <div className="text-center">
                    <p>{t("quiz_feedback_title_step_1_body")}</p>
                  </div>
                </div>
                <div className="modal-btn-group">
                  <ButtonPrimary title={t("quiz_feedback_title_step_1_button_yes")} onClick={handleIncreaseStep} />
                  <ButtonSecondary title={t("quiz_feedback_title_step_1_button_no")} onClick={handleToggleModal} />
                </div>
              </>
            ) : step === 2 ? (
              <>
                <div className="modal-content">
                  <HeadingH3 title={t("quiz_feedback_title_step_2_title")} />
                  <div className="text-center">
                    <p>{t("quiz_feedback_title_step_2_body")}</p>
                  </div>
                </div>
                <div className="modal-btn-group">
                  <ButtonBlue title={t("quiz_feedback_reason_1")} onClick={() => handleFeedbackSubmit(1)} />
                  <ButtonBlue title={t("quiz_feedback_reason_2")} onClick={() => handleFeedbackSubmit(2)} />
                  <ButtonBlue title={t("quiz_feedback_reason_3")} onClick={() => handleFeedbackSubmit(3)} />
                  <ButtonBlue title={t("quiz_feedback_reason_4")} onClick={() => openCustomFeedback()} />
                </div>
              </>
            ) : step === 3 ? (
              <>
                <div className="modal-content">
                  <HeadingH3 title={t("quiz_feedback_title_step_3optional_title")} />
                  <div className="text-center">
                    <p>{t("quiz_feedback_title_step_3optional_body")}</p>
                  </div>
                  <Formik initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize>
                    {({ isSubmitting }) => {
                      return (
                        <>
                          {isSubmitting && (
                            <div className="loader-wrapper">
                              <LoaderSmall />
                            </div>
                          )}
                          <Form className="modal-form">
                            <div className="form-row">
                              <div className="input-wrapper">
                                <Field
                                  type="text"
                                  name="feedback"
                                  className="form-input"
                                  placeholder={t("quiz_feedback_title_step_3optional_placeholder")}
                                />
                              </div>
                              <br></br>
                            </div>
                            <div className="form-action">
                              <ButtonPrimary
                                title={t("label_save")}
                                size="medium"
                                type="submit"
                                disabled={isSubmitting}
                              />
                            </div>
                          </Form>
                        </>
                      );
                    }}
                  </Formik>
                </div>
              </>
            ) : step === 4 ? (
              <>
                <div className="modal-content">
                  <HeadingH3 title="Thank you!" />
                  <div className="text-center">
                    <p>{t("quiz_feedback_confirmation")}</p>
                  </div>
                </div>
                <div className="modal-btn-group">
                  <ButtonPrimary title={t("Close")} onClick={handleToggleModal} />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </StyledModal>

      <StyledBugReportButton>
        <StyledButton type="button" onClick={handleToggleModal}>
          <span className="icon-wrapper">
            <svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="XMLID_311_">
                <path
                  xmlns="http://www.w3.org/2000/svg"
                  d="M18.757 23.08V4a2 2 0 0 0-2-2h-6.028c-.946 0-1.712.767-1.712 1.712 0 .946-.767 1.712-1.712 1.712H4a2 2 0 0 0-2 2v5.7a2 2 0 0 0 2 2h6.035a2 2 0 0 0 2-2v-.235a2 2 0 0 1 2-2h5.03"
                />
              </g>
            </svg>
          </span>
          <div className="report-label">{t("quiz_report_problem")}</div>
        </StyledButton>
      </StyledBugReportButton>
    </>
  );
};

export default QuizFeedback;
