import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledLink = styled.div`
  align-items: center;
  display: inline-flex;
  justify-content: center;
  text-align: center;
  white-space: nowrap;

  @media (max-width: 480px) {
    width: 100%;
  }

  a {
    border: 2px solid transparent;
    border-radius: 20px;
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 19px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    padding: 3px 14px;
    text-decoration: none;

    @media ${device.tabletL} {
      display: flex;
    }

    &.disabled {
      cursor: default;
      opacity: 0.5;
      pointer-events: none;
    }

    &.active {
      border-color: ${({ theme }) => theme.colors.white};
      cursor: default;
      pointer-events: none;

      @media (max-width: 480px) {
        text-decoration: underline;
      }
    }

    &:hover {
      filter: brightness(0.9);
    }

    @media ${device.tabletL} {
      padding: 1px 14px;
      font-size: 12px;
    }

    @media (max-width: 480px) {
      border: none;
      display: flex;
      font-size: 19px;
      padding: 10px 14px;
      width: 100%;
    }
  }

  .icon-wrapper {
    display: inline-flex;
    margin: 0 0 0 15px;
    position: relative;
    top: 2px;

    @media ${device.tabletL} {
      margin: 0 0 0 6px;
      top: 0;
    }
  }

  svg {
    @media ${device.tabletL} {
      width: 10px;
    }
  }
`;
