import React from "react";

// styles
import { StyledLoader } from "./LoaderSmall.styles";

const LoaderSmall = ({ color = "#32a3dd" }: { color?: string }) => {
  return (
    <StyledLoader color={color}>
      <div />
      <div />
      <div />
      <div />
    </StyledLoader>
  );
};

export default LoaderSmall;
