import React, { useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";

// types
import { HeaderPropTypes } from "./HeaderTypes";

// styles
import { StyledHeader } from "./HeaderReading.styles";

// components
import { BackIcon } from "../../../../assets/icons";
import { Link } from "react-router-dom";

// utils
import { RootReducer } from "src/redux/reducers";
import { getUserInfoActions } from "src/redux/actions/user";
import flagSmithIdentification from "src/helpers/flagSmithIdentification";

const HeaderReading = ({ children, action }: HeaderPropTypes) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const flagSmithHelper = flagSmithIdentification();

  const {
    auth: {
      authData: { userId }
    },
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const getUserInfo = useCallback(() => dispatch(getUserInfoActions.request({ userId: userId || "" })), [dispatch]);
  useEffect(() => {
    if (!userData) {
      getUserInfo();
    } else {
      flagSmithHelper.identify(userData.id, userData.schoolId);
    }
  }, []);

  return (
    <StyledHeader>
      <div className="header-left">
        <Link to="#" className="back-btn" onClick={action}>
          {" "}
          <BackIcon />
          {t("Close book")}
        </Link>
        {/*<HeaderProfile name={userData?.name} userImageUrl={userData?.profileImageUrl} />*/}
      </div>
      <div className="header-right">{children}</div>
    </StyledHeader>
  );
};

export default HeaderReading;
