import styled from "styled-components";
import bookItemFigureOverlayBg from "src/assets/images/book-item-figure-overlay.svg";
import bookItemDarknessOverlayBg from "src/assets/images/book-item-darkness-overlay.svg";

export const StyledBook = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (max-width: 560px) {
    width: calc(50% - 15px);
  }

  @media (max-width: 390px) {
    width: 80%;
  }

  &:nth-child(2n) {
    .image-wrapper {
      transform: rotate(1.55deg);
    }
  }

  .book-wrapper {
    width: 165px;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .image-wrapper {
    display: flex;
    width: 140px;
    transform: rotate(-1deg);
    position: relative;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .image-wrapper img {
    position: relative;
    z-index: 2;
    width: 100%;
  }

  .book-item-overlay {
    display: block;
    position: absolute;
    bottom: -6px;
    left: 18px;
    //height: 181px;
    height: 100%;
    width: 107px;
    content: "";
    background: ${({ theme }) => theme.colors.blueLight1};
    opacity: 0.34;
    filter: blur(17px);
    z-index: 1;
    border-radius: 10px;

    @media (max-width: 390px) {
      width: 86%;
    }
  }

  .book-item-loader {
    position: relative;
    background-color: ${({ theme }) => theme.colors.blueLight2};
    width: 100%;
    min-height: 180px;
    animation: fadeinout 1s infinite linear;

    &:before {
      position: absolute;
      top: 0;
      background: white none repeat scroll 0 0;
      border-color: red;
      border-radius: 45px;
      border-style: solid;
      border-width: 0;
      box-shadow: 0 0 10px 10px #ffffff;
      content: "";
      filter: blur(20px);
      height: 140%;
      left: -30%;
      margin-left: 0;
      margin-top: 0;
      width: 16px;
      z-index: 1000;
      transform: rotate(25deg);
      transition: all 0.8s;
      opacity: 0.6;
      animation: glare 1s infinite linear;
    }
  }

  @keyframes glare {
    0% {
      left: -15%;
    }
    100% {
      left: 115%;
    }
  }

  @keyframes fadeinout {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  .book-item-unloader {
    background-color: ${({ theme }) => theme.colors.red3};
    width: 100%;
    min-height: 180px;
  }

  .top-book-info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 13px;
    padding-left: 11px;
    padding-right: 13px;
  }

  .rating {
    display: flex;
    align-items: center;
    font-size: 13px;
    font-weight: 700;
  }

  .rating svg {
    margin-right: 5px;
  }

  .main-book-info {
    position: relative;
    padding-left: 5px;
  }

  .book-btn-wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: -5px;
    right: 1px;
  }

  .book-btn-wrapper .btn-overlay {
    background: #345b6f;
    bottom: -4px;
    left: 0;
    display: block;
    content: "";
    height: 33px;
    opacity: 0.2;
    filter: blur(15px);
    position: absolute;
    width: 32px;
    z-index: 1;
  }

  .btn-overlay-wrapper {
    position: relative;
  }

  .book-btn-wrapper button {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background: ${({ theme }) => theme.bg.blueLight3};
    border-radius: 50%;
    width: 36px;
    height: 36px;
    border: none;
    margin-bottom: 8px;
    cursor: pointer;
    z-index: 2;
  }

  .volume-overlay-wrapper {
    position: absolute;
    right: 1px;
    bottom: -3px;
    z-index: 10;
  }

  .volume-wrapper {
    position: relative;
    background: ${({ theme }) => theme.bg.blueLight3};
    font-size: 10px;
    color: #345b6f;
    font-weight: 700;
    border-radius: 40px;
    padding: 9px 18px;
    z-index: 2;
  }

  .volume-overlay {
    position: absolute;
    top: 5px;
    right: -4px;
    display: block;
    content: "";
    width: calc(100% - 4px);
    height: 29px;
    background: #345b6f;
    opacity: 0.2;
    filter: blur(15px);
    z-index: 1;
  }

  .progress {
    width: 55px;
    height: 4px;
    background: #aed5f1;
    border-radius: 20px;
  }

  .progress-bar {
    height: 4px;
    background: #38a6de;
    border-radius: 20px;
  }

  .bottom-book-info {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 13px;

    &.has-level {
      justify-content: space-between;
    }
  }

  .level-wrapper {
    display: flex;
    align-items: center;
    color: #345b6f;
    font-family: ${({ theme }) => theme.fonts.fontFamilyBase};
    font-weight: 700;
    font-size: 10px;
  }

  .level {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 18px;
    min-width: 18px;
    color: ${({ theme }) => theme.colors.white};
    font-weight: 700;
    font-size: 13px;
    background: #165b95;
    border-radius: 50%;
    margin-left: 12px;

    &.easy {
      background: #165b95;
    }

    &.appropriate {
      background: #349635;
    }

    &.difficult {
      background: #e2c51e;
    }

    &.hard {
      background: #e12929;
    }
  }

  .type {
    display: flex;
    align-items: center;
  }

  .type svg {
    margin-left: 11px;
  }

  .book-img {
    width: 137px;
    //height: 189px;
    height: auto;
    //background-size: cover;
    //position: relative;
    z-index: 2;

    @media (max-width: 560px) {
      width: 100%;
    }
  }

  .book-item-clip-path,
  .book-item-pages-bottom-clip-path {
    position: absolute;
    max-width: 100%;
  }

  .book-item-darkness-overlay {
    position: absolute;
    left: -2px;
    top: 1px;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-image: url(${bookItemDarknessOverlayBg});
    background-position: 0 0;
    background-size: 141px 189px;
    background-repeat: no-repeat;
    object-fit: fill;
    transform: rotate(1deg);
    opacity: 0.2;
    display: none;
  }

  .book-item-figure-overlay {
    position: absolute;
    left: -2px;
    top: -3px;
    right: 0;
    bottom: 0;
    z-index: 5;
    background-image: url(${bookItemFigureOverlayBg});
    background-position: 0 0;
    background-size: 141px 189px;
    background-repeat: no-repeat;
    object-fit: fill;
    background-size: cover;
    height: 100%;
    width: 100%;
  }

  .book-item-figure-pages-bottom {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 6;
    width: 100%;
    height: 100%;
    background: #e8f3fe;
  }
`;
