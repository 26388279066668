import { take, put } from "redux-saga/effects";
import { GET_BOOK_GAMES, getBookGamesActions } from "src/redux/actions/quiz";

// services
import { getBookGames } from "src/services/quiz";

// types
import { GetBookGamesRequestTypes, GetBookGamesResponseTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetBookGamesRequest() {
  while (true) {
    const { payload }: IActionType<GetBookGamesRequestTypes> = yield take(GET_BOOK_GAMES.REQUEST);

    try {
      const result: GetBookGamesResponseTypes = yield call(getBookGames, payload);
      yield put(getBookGamesActions.success(result));
    } catch (e) {
      yield put(getBookGamesActions.error(e as any));
    }
  }
}

export default watchGetBookGamesRequest;
