import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

// components
import TimeLabel from "../TimeLabel";
import ButtonPrimary from "../ButtonPrimary";

// utils
import { acceptAssignmentActions } from "src/redux/actions/assignments";
import { getBookStatisticProgress } from "src/helpers/library";

// assets
import quizImage from "src/assets/images/quiz.svg";

// types
import { AssignmentCardPropsType } from "./AssignmentCardTypes";

// styles
import { StyledCard } from "./AssignmentCard.styles";
import { GetBookAdditionalDataItemResponseTypes } from "../../redux/actions/book/types";

const AssignmentCard = ({
  timeType,
  dueDate,
  gameId,
  challengeId,
  accepted,
  bookId,
  completedDate,
  bookData,
  quizData,
  additionalData,
  openQuiz,
  openBook
}: AssignmentCardPropsType) => {
  const dispatch = useDispatch();
  const [t] = useTranslation();

  const getTimeLeft = (dateString: any) => {
    const targetDate = new Date(dateString);
    const currentDate = new Date();
    const timeDiff = targetDate.getTime() - currentDate.getTime();

    const daysLeft = Math.floor(timeDiff / (1000 * 60 * 60 * 24));
    const hoursLeft = Math.floor((timeDiff / (1000 * 60 * 60)) % 24);
    const minutesLeft = Math.floor((timeDiff / (1000 * 60)) % 60);

    let timeLeftStr = "";

    if (daysLeft > 0) {
      const monthsLeft = Math.floor(daysLeft / 30);
      const days = daysLeft % 30;

      if (monthsLeft == 1) {
        const metricsSuffix = t("month");
        timeLeftStr += `${monthsLeft} ${metricsSuffix} `;
      } else if (monthsLeft > 1) {
        const metricsSuffix = t("months");
        timeLeftStr += `${monthsLeft} ${metricsSuffix} `;
      }

      if (days == 1) {
        const metricsSuffix = t("day");
        timeLeftStr += `${days} ${metricsSuffix} `;
      } else if (days > 1) {
        const metricsSuffix = t("day_plural");
        timeLeftStr += `${days} ${metricsSuffix} `;
      }
    } else {
      if (hoursLeft > 0) {
        timeLeftStr += `${hoursLeft}h `;
      }
      timeLeftStr += `${minutesLeft}m`;
    }

    if (timeLeftStr.includes("-")) {
      return timeLeftStr.replace("-", " ") + " " + t("ago");
    }

    return timeLeftStr;
  };

  const acceptAssignment = useCallback(
    (challengeId: number) => dispatch(acceptAssignmentActions.request({ challengeId })),
    [dispatch]
  );

  const handleClick = () => {
    if (accepted) {
      if (gameId) {
        openQuiz(gameId, challengeId, quizData?.gameTitle);
      } else {
        openBook(bookId, challengeId, bookData?.name);
      }
    }
    if (!accepted || !completedDate) {
      acceptAssignment(challengeId);
    } else if (gameId) {
      openQuiz(gameId, challengeId, quizData?.gameTitle);
    } else {
      openBook(bookId, challengeId, bookData?.name);
    }
  };

  const getAdditionalBookData = (bookId: number, additionalBooks: GetBookAdditionalDataItemResponseTypes[]) => {
    if (additionalBooks?.length && bookId) {
      const bookData = additionalBooks.find((item) => item.bookId === bookId);

      if (bookData) {
        return bookData;
      }
    }

    return;
  };

  const [additionalDataProgressPercent, setAdditionalDataProgressPercent] = useState(0);
  const [statisticProgressPercent, setStatisticProgressPercent] = useState(0);
  const [bookProgress, setBookProgress] = useState(0);

  useEffect(() => {
    if (additionalData?.length) {
      const currentBookAdditionalData = getAdditionalBookData(bookId, additionalData);
      setAdditionalDataProgressPercent(currentBookAdditionalData?.progressInPercent || 0);
      setStatisticProgressPercent(getBookStatisticProgress(bookId));
    }
  }, [additionalData]);

  useEffect(() => {
    setBookProgress(completedDate ? 100 : Math.max(additionalDataProgressPercent, statisticProgressPercent));
  }, [additionalDataProgressPercent, statisticProgressPercent]);

  return (
    <StyledCard>
      {completedDate ? (
        <div className="label-wrapper">
          <TimeLabel type={"submit-date"} value={moment(completedDate).tz("America/New_York").format("MMM DD, YYYY")} />
        </div>
      ) : (
        timeType === "time-left" && (
          <div className="label-wrapper">
            <TimeLabel
              type={timeType}
              textLabel={`${getTimeLeft(dueDate).includes(t("ago")) ? t("assignments_expired") : ""}`}
              value={dueDate ? getTimeLeft(dueDate) : "--"}
            />
          </div>
        )
      )}
      {gameId && (
        <>
          <div className="quiz-image-wrapper">
            <Img src={quizImage} alt="quiz icon" />
          </div>
          {quizData?.lmsQuizLevel ? (
            <h5 className="title">
              {quizData?.lmsQuizLevel === 1 && t("quiz_comprehension_level1_title")}
              {quizData?.lmsQuizLevel === 2 && t("quiz_comprehension_level2_title")}
              {quizData?.lmsQuizLevel === 3 && t("quiz_comprehension_level3_title")}
            </h5>
          ) : (
            <h5 className="title">{bookData?.name}</h5>
          )}
          <p className="subtitle">
            {quizData?.lmsQuizLevel === 1 && t("quiz_comprehension_level1_description")}
            {quizData?.lmsQuizLevel === 2 && t("quiz_comprehension_level2_description")}
            {quizData?.lmsQuizLevel === 3 && t("quiz_comprehension_level3_description")}
          </p>
          <span className="progress-amount">
            0/{quizData?.questions.length} {t("assignment_questions")}
          </span>
          <div className="progress-wrapper">
            <div className="progress-left">
              <div className="progress">
                <span className="progress-bar" style={{ width: "0%" }}></span>
              </div>
            </div>
            <div className="progress-right">0%</div>
          </div>
        </>
      )}

      {!gameId && (
        <>
          <h5 className="title">{bookData?.name}</h5>
          <div className="subtitle">
            <div className="image-wrapper">
              <Img src={bookData?.thumbnailSmallUrl || bookData?.thumbnailLargeUrl || ""} alt="" />
            </div>
          </div>
          <div className="progress-wrapper">
            <div className="progress-left">
              <div className="progress">
                <span className="progress-bar" style={{ width: `${bookProgress}%` }}></span>
              </div>
            </div>
            <div className="progress-right">{bookProgress}%</div>
          </div>
        </>
      )}
      <div className="action">
        <ButtonPrimary
          size="medium"
          title={!accepted ? t("label_accept") : t("assignment_start")}
          onClick={handleClick}
        />
      </div>
    </StyledCard>
  );
};

export default AssignmentCard;
