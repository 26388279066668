import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledProgress = styled.div`
  position: relative;
  margin-bottom: 66px;

  @media ${device.tabletXL} {
    margin-bottom: 52px;
  }

  .title {
    font-size: ${({ theme }) => theme.fonts.fontSizeBase};
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    letter-spacing: 0.02em;

    @media ${device.tablet} {
      font-size: 12px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightMedium};
      letter-spacing: 0;
    }
  }

  .value {
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeM};
    margin-left: 3px;

    @media ${device.tablet} {
      font-size: 19px;
      font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    }
  }

  .progress {
    margin-top: 29px;
    padding-left: 0;

    @media ${device.tablet} {
      margin-top: 20px;
    }
  }

  .progress {
    display: flex;
    width: 100%;
    gap: 16px;
  }

  .step {
    position: relative;
    display: flex;
    height: 8px;
    background: #aed5f1;
    border-radius: 30px;

    &.active {
      background: ${({ theme }) => theme.bg.blueLight};
    }
  }

  .icon-wrapper {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    border-radius: 50%;
    background: ${({ theme }) => theme.bg.blueLight};
  }
`;
