import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledAccordionItem = styled.div`
  position: relative;
  border-bottom: 1px solid #aed5f1;
  margin-bottom: 0;

  .heading {
    font-size: 23px;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  .accordion-btn {
    display: flex;
    align-items: center;
    width: 100%;
    border: none;
    background: none;
    cursor: pointer;
    position: relative;
    min-height: 71px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: 19px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    justify-content: space-between;

    @media ${device.tabletXL} {
      padding: 11px 40px 1px 0;
      min-height: 63px;
    }
  }

  .arrow {
    position: absolute;
    width: 32px;
    height: 32px;
    right: 0;
    top: 22px;
    background: #d6ecf8;
    display: flex;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    transition: transform 300ms;
  }

  &.active .arrow {
    transform: rotateZ(180deg);
  }

  .control {
    font-size: 20px;
  }

  .answer {
    background: none;
    padding: 20px;
  }

  .accordion-content {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
  }
`;
