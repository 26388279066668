import { requestTypeCreator } from "src/helpers/index";
import { GetUserInfoResponseTypes, GetLanguageListRequestTypes, GetLanguageListResponseTypes } from "./types";

export const GET_USER_INFO = requestTypeCreator("GET_USER_INFO");
export const GET_LANGUAGE_LIST = requestTypeCreator("GET_LANGUAGE_LIST");

export const getUserInfoActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_USER_INFO.ERROR
  }),
  request: (payload: { userId: string | undefined }) => ({
    payload,
    type: GET_USER_INFO.REQUEST
  }),
  success: (payload: GetUserInfoResponseTypes): IActionType<GetUserInfoResponseTypes> => ({
    payload,
    type: GET_USER_INFO.SUCCESS
  })
};

export const getLanguageListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_LANGUAGE_LIST.ERROR
  }),
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  request: (payload: any): IActionType<GetLanguageListRequestTypes> => {
    return {
      payload,
      type: GET_LANGUAGE_LIST.REQUEST
    };
  },
  success: (payload: GetLanguageListResponseTypes): IActionType<GetLanguageListResponseTypes> => ({
    payload,
    type: GET_LANGUAGE_LIST.SUCCESS
  })
};
