// LANGUAGE_MAP with integer keys and string values
export const LANGUAGE_MAP: { [key: number]: string } = {
  1: "da",
  2: "en",
  3: "de",
  4: "sv",
  6: "nb",
  7: "nb" // nn is not supported so i am using nb instead
};

// Function to get language string from number
const getLanguageString = (num: number): string | null => {
  return LANGUAGE_MAP[num] || null;
};

export default getLanguageString;
