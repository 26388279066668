import React from "react";

// assets
import { ArrowBackIcon } from "src/assets/icons";

// types
import { ButtonBluePropsType } from "./ButtonBlueTypes";

// styles
import { StyledButton } from "./ButtonBlue.styles";

const ButtonBlue = ({ title, onClick, backButton }: ButtonBluePropsType) => {
  return (
    <StyledButton type="button" onClick={onClick}>
      {backButton && (
        <span className="icon-wrapper">
          <ArrowBackIcon />
        </span>
      )}
      <span className="btn-title">{title}</span>
    </StyledButton>
  );
};

export default ButtonBlue;
