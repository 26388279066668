import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledForm = styled.div`
  padding: 33px 20px 0 9px;
  position: relative;
  margin: 0 auto;
  max-width: 504px;
  width: 100%;

  @media ${device.tabletXL} {
    max-width: unset;
    padding: 16px 32px;
  }

  .loader-wrapper {
    display: flex;
    justify-content: center;
    background-color: rgba(256, 256, 256, 0.7);
    z-index: 9;
    align-items: center;
    height: 100%;
    position: absolute;
    width: 100%;
  }

  .form-description {
    text-align: center;

    p {
      &.secondary {
        opacity: 0.5;
      }
    }
  }

  .form-action {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .form-link {
    color: ${({ theme }) => theme.colors.textPrimary};
    display: inline-flex;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeBase};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    margin: 5px 0 0;
    text-decoration: underline;

    @media ${device.tabletXL} {
      margin: 16px 0 0;
    }

    &:hover {
      filter: brightness(1.7);
    }
  }

  .form-row {
    margin: 0 0 17px;

    @media ${device.tabletXL} {
      margin: 0 0 24px;
    }
  }

  .form-row-center {
    margin: 0 0 17px;
    text-align: center;

    @media ${device.tabletXL} {
      margin: 0 0 24px;
    }
  }

  .form-action {
    margin: 46px 0 0;

    @media ${device.tabletXL} {
      margin: 27px 0 0;
    }
  }

  .checkbox-container {
    margin: 30px 0;

    @media ${device.tabletXL} {
      margin: 40px 0;
    }
  }

  .btn-sign-up {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .buttons-wrapper {
    @media ${device.tabletXL} {
      align-items: center;
      display: flex;
      gap: 20px;
      justify-content: center;
    }

    @media (max-width: 599px) {
      flex-direction: column;
      align-items: center;
    }
  }

  .login-buttons-wrapper {
    align-items: center;
    display: flex;
    gap: 0px;
    justify-content: center;
  }
`;

export const StyledInput = styled.div`
  display: flex;
  flex-direction: column;

  .form-label {
    font-size: 16px;
    display: block;
    margin: 0 0 8px;
    padding: 0 0 0 10px;
  }

  .form-input {
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.blueLight};
    border-radius: 16px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: ${({ theme }) => theme.fonts.fontSizeS};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    height: 60px;
    padding: 0 24px;

    &:focus,
    &:focus-visible {
      border-color: ${({ theme }) => theme.colors.blueLight};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 2px solid ${({ theme }) => theme.colors.blueLight};
      font-size: ${({ theme }) => theme.fonts.fontSizeS};
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      -webkit-text-fill-color: ${({ theme }) => theme.colors.textPrimary};
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }
`;
