import { take, put, select } from "redux-saga/effects";
import { DRESS_ITEM, dressItemActions, getBagItemsActions } from "src/redux/actions/bag";
import i18n from "i18next";

// services
import { dressItem, getBagItems } from "src/services/bag";

// utils
import { RootReducer } from "src/redux/reducers";
import { getDragonsList } from "src/services/dragon";
import { getDragonsListActions } from "src/redux/actions/dragon";

// types
import { BagItemTypes, BagListResponseTypes, DressItemRequestTypes } from "src/redux/actions/bag/types";
import { GetDragonsListResponseTypes } from "src/redux/actions/dragon/types";

import * as Effects from "redux-saga/effects";
import { ShopItemTypes } from "../../actions/shop/types";
const call: any = Effects.call;

function* watchDressItemRequest() {
  while (true) {
    const { payload }: IActionType<DressItemRequestTypes> = yield take(DRESS_ITEM.REQUEST);
    const { shopItems } = yield select((state: RootReducer) => state?.shop);

    const { userId, action, ...restPayload } = payload as DressItemRequestTypes;

    try {
      // wear item
      yield call(dressItem, { ...restPayload, userId });

      // get dragons list to check dressed items
      const result: GetDragonsListResponseTypes = yield call(getDragonsList, {
        userId: Number(userId)
      });
      yield put(getDragonsListActions.success(result));

      const dressedArr: { id: number; transactionId: string }[] = [];
      result?.map(({ dressedItems }) => {
        if (dressedItems.length) {
          dressedArr.push(...dressedItems);
        }
      });

      // update bagList with isDressed key
      const bagItems: BagListResponseTypes = yield call(getBagItems, payload);
      const newResult: BagItemTypes[] = [];

      const getFullBagItems = () => {
        bagItems &&
          Array.isArray(bagItems.items) &&
          bagItems.items.map(({ itemId, transactionId }) => {
            shopItems?.map(function (shopItem: ShopItemTypes) {
              if (shopItem.id === itemId) {
                newResult.push({ ...shopItem, transactionId });
              }
            });
          });
      };
      getFullBagItems();

      yield put(getBagItemsActions.success(newResult));

      const updateBagList = () => {
        for (const obj of newResult) {
          const matchingObj = dressedArr.find((o: { id: number; transactionId: string }) => o.id === obj.id);

          if (matchingObj && obj.itemTypeId === 2) {
            obj.isDressed = true;
          }
          if (!matchingObj && obj.itemTypeId === 2) {
            obj.isDressed = false;
          }
        }
      };
      updateBagList();

      yield put(dressItemActions.success());

      action(i18n.t("Success"), "success");
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      action(e.message || "Something went wrong", "error");
      yield put(dressItemActions.error({ error: e }));
    }
  }
}

export default watchDressItemRequest;
