import React from "react";
import { useTranslation } from "react-i18next";

// assets
import { CrossIcon } from "src/assets/icons";
import CheckIcon from "src/assets/images/check_icon.svg";

// components
import { ButtonPrimary, ButtonSecondary } from "src/components";

// types
import { ConfirmModalPropsType } from "./ConfirmModalTypes";

// styles
import { StyledModal } from "./ConfirmModal.styles";

const ConfirmModal = ({
  isVisible,
  closeModal,
  children,
  confirmAction,
  showCancelButton,
  disableConfirmButton,
  isSuccess
}: ConfirmModalPropsType) => {
  const [t] = useTranslation();

  return (
    <StyledModal className={`${isVisible ? "visible" : ""} ${isSuccess ? "successModal" : ""}`}>
      <div className="modal-overlay" onClick={closeModal} />
      <div className="modal">
        {isSuccess && (
          <div className="circleCheck">
            <div className="circleCheckImageWrapper">
              <img src={CheckIcon} alt="check" />
            </div>
          </div>
        )}
        <div className="modal-inner">
          <div className="modal-action">
            <button type="button" className="btn-modal-close" onClick={closeModal}>
              <CrossIcon />
            </button>
          </div>
          <div className="modal-content">{children}</div>
          <div className="modal-btn-group">
            <ButtonPrimary title={t("btn_ok")} onClick={confirmAction} disabled={disableConfirmButton} />
            {showCancelButton && !isSuccess && <ButtonSecondary title={t("btn_cancel")} onClick={closeModal} />}
          </div>
        </div>
      </div>
    </StyledModal>
  );
};

export default ConfirmModal;
