import React from "react";

// assets
import { ArrowLeftIcon } from "src/assets/icons";

// types
import { ButtonTogglePropsType } from "./ButtonToggleTypes";

// styles
import { StyledButton } from "./ButtonToggle.styles";

const ButtonToggle = ({ onClick }: ButtonTogglePropsType) => {
  return (
    <StyledButton type="button" onClick={onClick}>
      <span className="icon-wrapper">
        <ArrowLeftIcon />
      </span>
    </StyledButton>
  );
};

export default ButtonToggle;
