import { LOGIN, RESET_PASSWORD, UNILOGIN, OTP_CODE } from "src/redux/actions/auth";
import { LoginResponseTypes } from "src/redux/actions/auth/types";

export const AUTH_RESET_ACTION_TYPE = "@auth/AUTH_RESET";

export const initialState: AuthState = {
  isLoading: false,
  authData: {
    userId: undefined,
    access_token: ""
  },
  error: undefined
};

export interface AuthState {
  isLoading: boolean;
  authData: {
    userId: string | undefined;
    access_token: string;
  };
  error: string | undefined;
}

const auth = (state = initialState, action: IActionType<LoginResponseTypes | string>): AuthState => {
  switch (action.type) {
    case LOGIN.REQUEST:
    case UNILOGIN.REQUEST:
    case RESET_PASSWORD.REQUEST:
    case OTP_CODE.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case UNILOGIN.SUCCESS:
    case LOGIN.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        authData: action.payload as LoginResponseTypes,
        error: undefined
      };
    }

    case RESET_PASSWORD.SUCCESS:
    case OTP_CODE.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        error: undefined
      };
    }

    case LOGIN.ERROR:
    case RESET_PASSWORD.ERROR:
    case OTP_CODE.ERROR: {
      return <AuthState>{
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default auth;
