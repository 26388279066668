import React from "react";
import { NavLink } from "react-router-dom";

// assets
import { LockIcon } from "src/assets/icons";

// types
import { NavigationLinkPropsType } from "./NavigationLinkTypes";

// styles
import { StyledLink } from "./NavigationLink.styles";

const NavigationLink = ({ title, url, disabled }: NavigationLinkPropsType) => {
  return (
    <StyledLink>
      <NavLink className={`${disabled ? "disabled" : ""}`} to={url}>
        {title}
        {disabled && (
          <span className="icon-wrapper">
            <LockIcon color="#ffffff" />
          </span>
        )}
      </NavLink>
    </StyledLink>
  );
};

export default NavigationLink;
