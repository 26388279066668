import { take, put } from "redux-saga/effects";
import { GET_PARENT_AUTH, getParentAuthUserActions } from "src/redux/actions/nota";
import { v4 as uuidv4 } from "uuid";

// services
import { login } from "src/services/user";

// types
import { GetParentTokenRequestTypes, LoginResponseTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetParentAuthRequest() {
  while (true) {
    const { payload }: IActionType<GetParentTokenRequestTypes> = yield take(GET_PARENT_AUTH.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as GetParentTokenRequestTypes;

    try {
      const result: LoginResponseTypes = yield call(login, { ...restPayload, deviceId: uuidv4().toUpperCase() });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(getParentAuthUserActions.success({ auth: result, email: payload.username }));
      onSuccess();
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(getParentAuthUserActions.error(e || { message: "Something went wrong, try again later" }));
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
    }
  }
}

export default watchGetParentAuthRequest;
