import React from "react";
import { IconPropsType } from "./IconTypes";

const CloseIcon = ({ color = "#01324B", width = 12, height = 12 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.192 0.344116L5.94897 4.58612L1.70697 0.344116L0.292969 1.75812L4.53497 6.00012L0.292969 10.2421L1.70697 11.6561L5.94897 7.41412L10.192 11.6561L11.606 10.2421L7.36397 6.00012L11.606 1.75812L10.192 0.344116Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
