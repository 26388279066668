import { take, put } from "redux-saga/effects";
import { GET_BOOK_ITEM, getBookItemActions } from "src/redux/actions/book";

// services
import { getBookList } from "src/services/book";

// types
import { GetBookListResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookItemRequest() {
  while (true) {
    const { payload }: IActionType<GetBookListResponseTypes> = yield take(GET_BOOK_ITEM.REQUEST);

    try {
      const result: GetBookListResponseTypes = yield call(getBookList, payload);

      yield put(getBookItemActions.success(result?.books && result.books.length > 0 ? result.books[0] : undefined));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookItemActions.error(e as any));
    }
  }
}

export default watchGetBookItemRequest;
