import { take, put } from "redux-saga/effects";
import { PURCHASE_ITEM, purchaseItemActions } from "src/redux/actions/shop/index";

// services
import { purchaseItem } from "src/services/shop";

// types
import { PurchaseItemRequestTypes } from "src/redux/actions/shop/types";

import * as Effects from "redux-saga/effects";
import { GetUserInfoResponseTypes } from "../../actions/user/types";
import { getUserInfo } from "../../../services/user";
import { getUserInfoActions } from "../../actions/user";
const call: any = Effects.call;

function* purchaseItemRequest() {
  while (true) {
    const { payload }: IActionType<PurchaseItemRequestTypes> = yield take(PURCHASE_ITEM.REQUEST);

    const { onSuccess, onError, ...restPayload } = payload as PurchaseItemRequestTypes;

    try {
      yield call(purchaseItem, restPayload);
      const result: GetUserInfoResponseTypes = yield call(getUserInfo, {
        ...payload,
        refresh: true
      });
      yield put(getUserInfoActions.success(result));
      yield put(purchaseItemActions.success());
      onSuccess();
    } catch (e) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onError(e.message || "Something went wrong");
      yield put(purchaseItemActions.error(e as any));
    }
  }
}

export default purchaseItemRequest;
