import styled from "styled-components";
import bgImg from "src/assets/images/question-bg.png";
import bgMobileImg from "src/assets/images/question-bg-mobile.png";
import { device } from "src/theme/mediaQueries";

export const StyledQuestion = styled.div`
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 32px;
  padding: 40px 32px 34px 40px;

  @media ${device.tabletXL} {
    flex-direction: column;
  }

  @media ${device.tabletL} {
    padding: 16px;
    border-radius: 16px;
  }

  @media ${device.mobileL} {
    padding-bottom: 25px;
  }

  .left {
    width: 42%;
    padding: 18px 42px 0 0;

    @media ${device.tabletXL} {
      width: 100%;
      order: 2;
      padding: 32px 0 0 0;
    }
  }

  .title-wrapper {
    display: flex;
    justify-content: space-between;
  }

  h2 {
    margin-bottom: 25px;
    font-size: ${({ theme }) => theme.fonts.fontSizeM};
    line-height: 35px;

    @media ${device.tabletL} {
      font-size: ${({ theme }) => theme.fonts.fontSizeS};
      line-height: 30px;
      margin-top: 7px;
      text-transform: capitalize;
      padding-right: 30px;
    }

    @media ${device.mobileL} {
      margin-bottom: 14px;
    }
  }

  .tooltip {
    font-size: ${({ theme }) => theme.fonts.fontSizeBase};
    letter-spacing: 0.02em;

    @media ${device.mobileL} {
      font-size: 14px;
    }
  }

  .answers-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    gap: 22px 24px;
    margin-top: 38px;
    margin-bottom: 50px;

    @media ${device.tabletXL} {
      margin-bottom: 30px;
    }

    @media ${device.mobileL} {
      margin-top: 21px;
      margin-bottom: 17px;
    }
  }

  .answer-item {
    display: flex;
    width: calc((100% - 24px) / 2);

    &.disabled {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  .answer-btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: ${({ theme }) => theme.bg.white};
    border: 1.5px solid #e2f3ff;
    border-radius: 16px;
    padding: 32px 15px;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
    cursor: pointer;

    .icon-wrapper {
      position: absolute;
      left: 50%;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      transform: translate(-50%, -50%);
    }
    &.success {
      border: 3px solid #38a6de;
      padding: 30px 13px;

      .icon-wrapper {
        background: #27ae60;
      }
    }

    &.error {
      border: 3px dashed #38a6de;
      padding: 30px 13px;

      .icon-wrapper {
        background: linear-gradient(241.2deg, rgba(255, 255, 255, 0.3) 4.27%, rgba(0, 0, 0, 0.3) 96.43%), #cb1e5c;
        background-blend-mode: soft-light, normal;
      }
    }
  }

  .btn-wrapper {
    display: flex;
    justify-content: center;
    padding: 18px 0;
  }

  .right {
    display: flex;
    justify-content: flex-end;
    padding: 22px 16px;
    padding: 0px 16px 22px 16px;
    flex-direction: column;
    width: 58%;
    background: url(${bgImg}) no-repeat;
    background-size: cover;
    border-radius: 16px;

    @media ${device.tabletXL} {
      order: 1;
      width: 100%;
      min-height: auto;
      flex-direction: row;
      justify-content: center;
      padding: 20px;
    }

    @media ${device.mobileL} {
      border-radius: 16px;
      background: url(${bgMobileImg}) no-repeat;
      background-size: cover;
    }

    @media (max-width: 510px) {
      flex-direction: column;
    }
  }

  .comment-container {
    display: flex;
    justify-content: center;
  }

  .comment-wrapper {
    position: relative;
    transform: translate(-69px, 14px);
    min-height: 240px;
    min-width: 286px;
    width: max-content;

    @media ${device.tabletXL} {
      transform: none;
      height: fit-content;
    }

    @media ${device.tabletL} {
      margin-top: -38px;
    }
  }

  .comment {
    position: absolute;
    display: flex;
    padding: 0 40px 0 45px;
    top: 50%;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 16px;
    line-height: 20px;
    transform: rotate(-2.8deg) translateY(-50%);
    margin-top: 5px;
  }

  .money-icon {
    width: 38px;
    height: 49px;
    margin-top: -4px;

    .count {
      top: -11px;
      right: -12px;
      min-width: 33px;
      height: 33px;
    }
  }

  .image-container {
    display: flex;
    justify-content: center;
  }

  .image-wrapper {
    position: relative;
    margin-left: 18px;
    margin-bottom: 38px;
    position: relative;

    @media ${device.tabletXL} {
      margin-left: 0px;
      margin-bottom: 0px;
    }

    @media ${device.tabletL} {
      max-width: 200px;
    }

    @media ${device.mobileL} {
      max-width: 255px;
      padding: 35px 18px 17px 0;
    }

    @media (max-width: 510px) {
      padding: 0 0 17px 0;
      max-width: 165px;
    }
  }

  .image-overlay {
    position: absolute;
    bottom: -8px;
    left: 13px;
    width: 226px;
    height: 47px;
    background: #6bb8df;
    opacity: 0.2;
    filter: blur(6px);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    border-radius: 50%;

    @media ${device.tabletL} {
      bottom: 0px;
      left: -4px;
      width: 202px;
      height: 39px;
    }
  }

  .timer-wrapper {
    min-height: 72px;
    min-width: 72px;
  }
`;
