import React from "react";
import { IconPropsType } from "./IconTypes";

const ArrowRightIcon = ({ color = "#fff", width = 21, height = 17 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 21 17">
      <path
        fill={color}
        fillRule="evenodd"
        d="M8.005 3.935c.021.167.052.313.094.438l4.547 2.28c-4.072-.61-9.828-1.296-11.47-.6-1.831.776-1.165 4.554.146 4.554 1.227 0 6.994-.253 11.155-.444l-2.192.986c-.708.354-1.249.74-1.624 1.156-.354.396-.53.958-.53 1.686 0 .542.176 1.052.53 1.53.375.459 1.072.823 2.093 1.094l8.588-4.997c.479-.27.843-.646 1.093-1.124.25-.48.375-1.156.375-2.03 0-1.166-.271-2.103-.812-2.811l-6.715-3.872a16.675 16.675 0 00-1.592-.812 3.09 3.09 0 00-1.28-.281c-.459 0-.844.083-1.156.25-.292.145-.531.343-.719.593-.187.25-.322.541-.406.874a4.365 4.365 0 00-.125 1.062v.468z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ArrowRightIcon;
