import { BookStatisticPropTypes } from "../modules/bookReading/BookReadingTypes";
import { SIMPLIFIED_READING_LEVEL_FILTTER } from "../constants";

export const getBookStatisticProgress = (id: number | undefined) => {
  const book = getBookStatistic(id);

  const result = Math.round(
    book && book.currentPage && book.totalPages ? (book.currentPage * 100) / book.totalPages : 0
  );
  return result;
};

export const getBookStatistic = (id: number | undefined) => {
  const booksStatistic = JSON.parse(localStorage.getItem("booksStatistic") || "[]");
  const book = booksStatistic.find((book: BookStatisticPropTypes) => Number(book.id) === id);
  return book;
};

export const getReadingLevelItems = (t: (trans: string) => string) => {
  const levels = [
    {
      title: t("library_filter_item_reading_lvl_easy"),
      color: "#38A6DE",
      value: -1,
      name: "easy"
    },
    {
      title: t("library_filter_item_reading_lvl_appropriate"),
      color: "#27AE60",
      value: 0,
      name: "appropriate"
    },
    {
      title: t("library_filter_item_reading_lvl_difficult"),
      color: "#FFA500",
      value: 1,
      name: "difficult"
    },
    {
      title: t("library_filter_item_reading_lvl_hard"),
      color: "#E44070",
      value: 2,
      name: "hard"
    }
  ];

  const simplifiedLevels = ["easy", "appropriate"];

  return SIMPLIFIED_READING_LEVEL_FILTTER ? levels.filter((level) => simplifiedLevels.includes(level.name)) : levels;
};
