import React from "react";
import { useTranslation } from "react-i18next";

// assets
import { CrossIcon, DragPointerIcon } from "src/assets/icons";

// helpers
import { useScreenWidth } from "src/helpers";

// types
import { SidebarSecondaryPropsType } from "./SidebarSecondaryTypes";

// styles
import { StyledSidebar } from "./SidebarSecondary.styles";

const SidebarSecondary = ({ children, isCollapsed, closeSidebar }: SidebarSecondaryPropsType) => {
  const [t] = useTranslation();
  const [screenWidth] = useScreenWidth();

  return (
    <StyledSidebar className={`${isCollapsed ? "collapsed" : ""}`}>
      <div className="sidebar-overlay" onClick={closeSidebar} />
      {screenWidth <= 768 && (
        <div className="sidebar-pointer">
          <DragPointerIcon />
          <span>{t("dragon_bag_description_equip_dragon")}</span>
        </div>
      )}
      <div className="sidebar">
        <div className="sidebar-action">
          <button type="button" className="btn-sidebar-close" onClick={closeSidebar}>
            <CrossIcon />
          </button>
        </div>
        <div className="sidebar-content">{children}</div>
      </div>
    </StyledSidebar>
  );
};

export default SidebarSecondary;
