import React from "react";

// types
import { HeadingH3PropsType } from "./HeadingH3Types";

// styles
import { StyledHeading } from "./HeadingH3.styles";

const HeadingH3 = ({ title, align = "left", color = "#165B95" }: HeadingH3PropsType) => {
  return (
    <StyledHeading color={color} align={align}>
      {title}
    </StyledHeading>
  );
};

export default HeadingH3;
