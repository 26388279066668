import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  color: ${({ theme }) => theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  display: inline-flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 22px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  position: relative;

  &:disabled {
    opacity: 0.6;
  }

  @media ${device.tabletXL} {
    font-size: 16px;
  }

  .btn-title {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 100%;
    position: relative;
    width: 100%;
    z-index: 2;
  }

  .btn-background {
    position: absolute;
  }

  &.small {
    height: 63px;
    width: 261px;

    @media ${device.tabletXL} {
      height: 34px;
      width: 159px;
    }

    .btn-background {
      background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22261%22%20height%3D%2263%22%20viewBox%3D%220%200%20261%2063%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M21.8647%205.77726C37.277%202.72505%2070.876%201.62094%20111.429%202.112C152.065%202.60408%20199.908%204.70327%20243.949%208.1246C252.768%208.80972%20259.853%2016.6822%20258.741%2025.6523C258.068%2031.0843%20256.8%2036.7557%20253.825%2041.591C250.806%2046.4974%20246.104%2050.4204%20238.839%2052.434C236.179%2053.1715%20231.482%2053.8677%20225.379%2054.5287C219.227%2055.195%20211.512%2055.8387%20202.729%2056.4449C185.161%2057.6575%20163.269%2058.7237%20140.974%2059.5191C96.4468%2061.1075%2050.0869%2061.6242%2033.3795%2060.0364C15.9427%2058.3792%205.15247%2046.5829%202.66425%2034.2617C1.42206%2028.1106%202.24254%2021.7703%205.42572%2016.5398C8.6286%2011.2769%2014.1197%207.31108%2021.8647%205.77726Z%22%20stroke%3D%22white%22%20stroke-width%3D%224%22%2F%3E%0A%3C%2Fsvg%3E%0A");
      background-repeat: no-repeat;
      height: 63px;
      position: absolute;
      width: 261px;

      @media ${device.tabletXL} {
        background-image: url("data:image/svg+xml,%3Csvg%20width%3D%22159%22%20height%3D%2234%22%20viewBox%3D%220%200%20159%2034%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%3Cpath%20d%3D%22M146.584%205.66393C102.213%20-0.000974476%2030.3997%201.97901%2013.693%203.97774C9.49495%204.47998%206.52794%206.08561%204.61496%208.19522C2.69878%2010.3084%201.77965%2012.9929%201.79182%2015.7631C1.81624%2021.3166%205.58951%2027.1696%2012.5077%2029.1218C17.201%2030.4462%2027.2132%2031.2757%2039.9166%2031.7003C52.571%2032.1232%2067.768%2032.1425%2082.7417%2031.8874C97.7147%2031.6324%20112.454%2031.1033%20124.19%2030.4308C130.059%2030.0945%20135.171%2029.7227%20139.182%2029.332C143.224%2028.9385%20146.072%2028.5325%20147.465%2028.1441C150.378%2027.3327%20152.414%2026.0535%20153.868%2024.5079C155.327%2022.9576%20156.248%2021.0875%20156.849%2019.0246C157.755%2015.916%20156.893%2012.8171%20154.928%2010.3498C152.956%207.87367%20149.908%206.08839%20146.584%205.66393Z%22%20stroke%3D%22white%22%20stroke-width%3D%222%22%2F%3E%0A%3C%2Fsvg%3E%0A");
        height: 34px;
        width: 159px;
      }
    }
  }
`;
