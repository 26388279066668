import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledTimer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.collapsed {
    .count {
      display: none;
    }

    .timer {
      font-size: 12px;
    }

    .image-wrapper {
      height: 72px;
      width: 72px;
    }
  }

  .image-wrapper {
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid ${({ theme }) => theme.colors.blueDark};
    border-radius: 50%;
    height: 95px;
    overflow: hidden;
    width: 95px;

    @media ${device.tablet} {
      border-width: 1px;
      height: 58px;
      margin: 0 auto;
      width: 58px;
    }

    img {
      bottom: -5px;
      position: relative;
      max-width: unset;
      left: -6px;
      width: 112%;

      @media ${device.tablet} {
        transform: scale(-1, 1);
        bottom: -2px;
        left: -2px;
        width: 114%;
      }
    }
  }

  .timer {
    background-color: ${({ theme }) => theme.colors.blueDark};
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 22px;
    color: ${({ theme }) => theme.colors.white};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    padding: 0 8px;
    position: relative;
    top: -12px;
    white-space: nowrap;

    @media ${device.tablet} {
      font-size: 12px;
    }
  }

  .count {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.blueDark};
    border: 2px solid ${({ theme }) => theme.colors.white};
    border-radius: 50%;
    color: ${({ theme }) => theme.colors.white};
    display: inline-flex;
    justify-content: center;
    line-height: 1;
    font-size: 18px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    height: 43px;
    position: absolute;
    right: -7px;
    top: -7px;
    width: 43px;

    @media ${device.tablet} {
      font-size: 12px;
      left: 2px;
      right: initial;
      height: 26px;
      width: 26px;
    }
  }
`;
