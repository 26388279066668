import http from "../http";

export const getShopItems = ({ userId }: { userId: string }) => {
  return http("api/dragon/items", {
    params: { userId },
    method: "GET"
  });
};

export const getShopCategories = () => {
  return http("api/dragon/itemtypes", {
    method: "GET"
  });
};

export const purchaseItem = ({
  userId,
  transactionId,
  itemId
}: {
  userId: string;
  transactionId: string;
  itemId: number;
}) => {
  return http("api/dragon/items/purchase", {
    params: { userId },
    body: JSON.stringify({ itemId, transactionId }),
    method: "POST"
  });
};
