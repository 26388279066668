import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 10px 30px 10px 30px;
  position: relative;
  min-height: 86px;

  @media ${device.tabletL} {
    padding: 10px 30px 10px 30px;
    min-height: 52px;
  }

  @media ${device.mobileL} {
    padding: 5px 30px;
    min-height: 42px;
  }

  .header-left {
    align-items: center;
    display: flex;
    min-width: fit-content;
  }

  .header-right {
    display: flex;
    flex: 1 1 auto;
    @media ${device.mobileL} {
      display: flex;
      align-items: center;
    }
  }

  .mobile-nav-toggle {
    align-items: center;
    ${({ theme }) => theme.colors.blueLight2};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 0 0 0 20px;
    width: 30px;
  }

  .back-btn {
    align-items: center;
    display: flex;
    background: none;
    border: 3px solid #f0f8ff;
    border-radius: 22px;
    color: #f0f8ff;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 19px;
    padding: 2px 21px;
    text-decoration: none;
    width: max-content;

    svg {
      margin-right: 12px;
    }
  }
`;
