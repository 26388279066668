import {
  NotaSignupNewParentParams,
  NotaSignupExistingParentParams,
  NotaSignupRequestTypes,
  ParentUserRequestTypes,
  ChilUserRequestTypes
} from "src/redux/actions/auth/types";

export function mapNotaSignupChildParamsToRequest(params: NotaSignupExistingParentParams): ChilUserRequestTypes {
  const childUser: ChilUserRequestTypes = {
    userTypeId: 6, // Unilogin user is type 6
    name: params.name, // Assuming the userName from params should be used as name
    countryId: params.uniloginUser.countryId ?? 1,
    languageId: params.uniloginUser.languageId ?? 1,
    userName: params.uniloginUser.userName,
    password: generateRandomPassword(),
    class: params.uniloginUser.class ?? "",
    notaMembershipId: params.cprNumber,
    city: "none", // Hardcoded stub to make signup work correctly
    readingLevel: params.uniloginUser.readingLevel,
    features: [{ Id: 1675859303, Name: "Onboarding" }] // Hardcoded feature of onboarding to select an egg
  };
  return childUser;
}

export function mapNotaSignupNewParentParamsToRequest(params: NotaSignupNewParentParams): NotaSignupRequestTypes {
  const parentUser: ParentUserRequestTypes = {
    userTypeId: 3, // Parent user is type 3
    name: "_", // Hardcoded stub to make signup work correctly
    email: params.email,
    userName: generateRandomUserName(),
    password: params.password,
    countryId: params.uniloginUser.countryId ?? 1,
    languageId: params.uniloginUser.languageId ?? 1,
    city: "none", // Hardcoded stub to make signup work correctly
    phone: "0", // Hardcoded stub to make signup work correctly
    numberOfLogins: 1 // Hardcoded stub to make signup work correctly
  };

  const childUser: ChilUserRequestTypes = {
    userTypeId: 6, // Unilogin user is type 6
    name: params.name, // Assuming the userName from params should be used as name
    countryId: params.uniloginUser.countryId ?? 1,
    languageId: params.uniloginUser.languageId ?? 1,
    userName: params.uniloginUser.userName,
    password: generateRandomPassword(),
    class: params.uniloginUser.class ?? "",
    notaMembershipId: params.cprNumber,
    city: "none", // Hardcoded stub to make signup work correctly
    readingLevel: params.uniloginUser.readingLevel,
    features: [{ Id: 1675859303, Name: "Onboarding" }] // Hardcoded feature of onboarding to select an egg
  };

  const lToken = params.lToken;

  return {
    parentUser,
    subUser: childUser,
    isSubUserUnilogin: true,
    lToken
  };
}

// Helper functions for generating random userName and password (placeholders)
function generateRandomUserName(): string {
  const timestamp = Date.now();
  const randomThreeDigit = Math.floor(Math.random() * 900) + 100;
  const randomUserName = `user${timestamp}:${randomThreeDigit}`;

  return randomUserName;
}

function generateRandomPassword(): string {
  return randomPasswordWithLength(9);
}

function randomPasswordWithLength(len: number): string {
  const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomString = "";

  for (let i = 0; i < len; i++) {
    const randomIndex = Math.floor(Math.random() * letters.length);
    randomString += letters.charAt(randomIndex);
  }

  return randomString;
}
