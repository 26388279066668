import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import en from "./en/common.json";
import da from "./da/common.json";
import nb from "./nb/common.json";
import nn from "./nn/common.json";
import ru from "./ru/common.json";
import sv from "./sv/common.json";

const resources = {
  en: { translation: { ...en } },
  da: { translation: { ...da } },
  nb: { translation: { ...nb } },
  nn: { translation: { ...nn } },
  ru: { translation: { ...ru } },
  sv: { translation: { ...sv } }
};

i18n
  .use(LanguageDetector)
  .init({
    resources,
    // lng: "nn",
    fallbackLng: "en",

    interpolation: { escapeValue: false }
  })
  // eslint-disable-next-line
  .catch((e) => console.log("=============translations error============", e));
export default i18n;
