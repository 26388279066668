import { take, put } from "redux-saga/effects";
import { GET_SKINS_LIST, getSkinsListActions } from "src/redux/actions/dragon/index";

// services
import { getSkinsList } from "src/services/dragon";

// types
import { GetSkinsListResponseTypes } from "src/redux/actions/dragon/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetSkinsListRequest() {
  while (true) {
    yield take(GET_SKINS_LIST.REQUEST);

    try {
      const result: GetSkinsListResponseTypes = yield call(getSkinsList);
      yield put(getSkinsListActions.success(result));
    } catch (e) {
      yield put(getSkinsListActions.error(e as any));
    }
  }
}

export default watchGetSkinsListRequest;
