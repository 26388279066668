import { call, put, take } from "redux-saga/effects";
import { ACCEPT_FRIEND, acceptFriendActions } from "src/redux/actions/friends";

// services
import { acceptFriend } from "src/services/friends";

// types
import { AcceptFriendRequestTypes, AcceptFriendResponseTypes } from "src/redux/actions/friends/types";
import { updateInviteStatus } from "../../actions/users";

function* watchAcceptFriendRequest() {
  while (true) {
    const { payload }: IActionType<AcceptFriendRequestTypes> = yield take(ACCEPT_FRIEND.REQUEST);
    yield put(
      updateInviteStatus.success({ id: payload.userId, isFriend: true, hasInvitation: false, hasPendingInvite: false })
    );
    try {
      const result: AcceptFriendResponseTypes = yield call(acceptFriend, payload);
      if (result) yield put(acceptFriendActions.success(payload));
    } catch (e) {
      yield put(acceptFriendActions.error(e as any));
    }
  }
}

export default watchAcceptFriendRequest;
