import { take, put } from "redux-saga/effects";
import { UNILOGIN, uniloginAuthActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { setToken, setRefreshToken, setTokenExpDate } from "src/services/localStorage";
import { unilogin } from "src/services/user";

// types
import { UniloginRequestTypes, LoginResponseTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchUniloginRequest() {
  while (true) {
    const { payload }: IActionType<UniloginRequestTypes> = yield take(UNILOGIN.REQUEST);

    const { onError, onSuccess, ...restPayload } = payload as UniloginRequestTypes;

    try {
      // Constructing the URL and calling fetch inside a saga
      const { state, code, session_state } = restPayload;
      const url = new URL("https://api.maneno.dk/uniauth2");
      url.searchParams.append("state", state);
      url.searchParams.append("session_state", session_state);
      url.searchParams.append("code", code);
      url.searchParams.append("fromWeb", "true");

      const response: Response = yield call(fetch, url.toString(), {
        method: "GET"
      });

      const validationResult: { lToken: string } = yield call([response, "json"]);

      if (!validationResult.lToken) {
        throw new Error("Something went wrong, try again later");
      }

      const result: LoginResponseTypes = yield call(unilogin, {
        ltoken: validationResult.lToken,
        deviceId: uuidv4().toUpperCase()
      });

      setToken(result.access_token);
      setRefreshToken(result.refresh_token);
      setTokenExpDate(result[".expires"]);

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(uniloginAuthActions.success({ access_token: result.access_token, userId: result.UserId }));
      onSuccess(parseInt(result.UserId));
    } catch (e) {
      console.error("Error in watchUniloginRequest saga:", e);
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(uniloginAuthActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchUniloginRequest;
