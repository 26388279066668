import React from "react";
import { IconPropsType } from "./IconTypes";

const CheckIcon = ({ color = "#FFF", width = 14, height = 11 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M2.19758 4.74454L2.19758 4.74454C2.05323 4.68886 1.89935 4.66052 1.74458 4.66052C1.58982 4.66052 1.43593 4.68886 1.29159 4.74454L1.42705 5.09571L1.29158 4.74454C1.14797 4.79994 1.01564 4.88173 0.902938 4.98663C0.78933 5.0907 0.697207 5.2162 0.633556 5.35689C0.569527 5.49841 0.536035 5.65155 0.536035 5.80713C0.536035 5.9627 0.569527 6.11584 0.633556 6.25737C0.697357 6.39839 0.789765 6.52415 0.903745 6.62837C0.904065 6.62866 0.904386 6.62895 0.904706 6.62924L4.31654 9.77971C4.78752 10.2146 5.53703 10.2146 6.008 9.77971L6.01809 9.7704L6.02751 9.76042L12.901 2.47909C13.0099 2.37694 13.0984 2.25481 13.1601 2.11841C13.2241 1.97689 13.2576 1.82375 13.2576 1.66817C13.2576 1.5126 13.2241 1.35946 13.1601 1.21793C13.0964 1.07727 13.0043 0.951775 12.8907 0.847719C12.778 0.742797 12.6457 0.660995 12.5021 0.605586C12.3577 0.549903 12.2038 0.52157 12.0491 0.52157C11.8943 0.52157 11.7404 0.549902 11.5961 0.605586C11.4517 0.661281 11.3187 0.74364 11.2056 0.849342L11.1956 0.85875L11.1862 0.868829L5.14521 7.34958L2.58801 4.9883C2.58769 4.98799 2.58736 4.98769 2.58704 4.98739C2.47416 4.88212 2.34154 4.80008 2.19758 4.74454Z"
        fill={color}
        stroke="white"
        strokeWidth="0.8"
      />
    </svg>
  );
};

export default CheckIcon;
