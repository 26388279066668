import { take, put } from "redux-saga/effects";
import { VALIDATE_CPR, validateCprAction } from "src/redux/actions/nota";

// services
import { validateCpr } from "src/services/nota";

// types
import { NotaCPRValidationRequest } from "src/redux/actions/nota/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchValidateCprRequest() {
  while (true) {
    const { payload }: IActionType<NotaCPRValidationRequest> = yield take(VALIDATE_CPR.REQUEST);

    try {
      const result: boolean = yield call(validateCpr, payload);
      if (!result) {
        throw new Error("Invalid CPR");
      }
      yield put(validateCprAction.success({ cprNumber: payload.cprNumber, isValid: result }));
    } catch (e) {
      yield put(validateCprAction.error(e as any));
    }
  }
}

export default watchValidateCprRequest;
