import React from "react";
import { IconPropsType } from "./IconTypes";

const ArrowLeftIcon = ({ color = "#165B95", width = 9, height = 10 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 9 10">
      <path
        fill={color}
        d="M8.331 7.682a.842.842 0 01.038.266c.013.101.02.196.02.285 0 .228-.026.443-.077.646a1.47 1.47 0 01-.247.532 1.356 1.356 0 01-.456.38c-.177.089-.405.133-.684.133-.266 0-.532-.057-.798-.171-.253-.114-.57-.279-.95-.494L1.092 6.903c-.329-.43-.494-1-.494-1.71 0-.532.076-.944.228-1.235.152-.291.374-.52.665-.684L6.716.234c.621.165 1.039.393 1.254.684.228.279.342.583.342.912 0 .443-.114.792-.342 1.045-.215.24-.538.469-.969.684L3.657 5.25l4.674 2.432z"
      ></path>
    </svg>
  );
};

export default ArrowLeftIcon;
