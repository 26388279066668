import http from "../http";
import { GetLanguageListRequestTypes } from "src/redux/actions/user/types";
import { NotaSignupRequestTypes, ChilUserRequestTypes } from "src/redux/actions/auth/types";

export const login = ({ username, password, deviceId }: { username: string; password: string; deviceId: string }) => {
  return http("token", {
    body: `username=${encodeURIComponent(username)}&password=${password}&grant_type=password`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    params: { DeviceId: deviceId },
    method: "POST"
  });
};

export const unilogin = ({ ltoken, deviceId }: { ltoken: string; deviceId: string }) => {
  const timeStamp = Math.floor(Date.now() / 1000);
  return http("token", {
    body: `token=${timeStamp}:${ltoken}&grant_type=unilogin_v2`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    params: { DeviceId: deviceId },
    method: "POST"
  });
};

export const masquerade = ({ childId, parentToken }: { childId: number; parentToken: string }) => {
  const timeStamp = Math.floor(Date.now() / 1000);
  return http("token", {
    body: `token=${timeStamp}:${encodeURIComponent(parentToken)}&grant_type=masquerade&userId=${childId}`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    method: "POST"
  });
};

export const getUniloginUser = ({ ltoken }: { ltoken: string }) => {
  return http("api/GetUserInformationFromUnilogin", {
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`
    },
    params: { token: ltoken },
    method: "GET"
  });
};

export const requestOtp = (email: string) => {
  return http("api/v2/account/recover", {
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`
    },
    params: { recoveryItem: email },
    method: "GET"
  });
};

export const createUserWithParent = (payload: NotaSignupRequestTypes) => {
  return http("api/v2/Users/createWithParent?authorizeParent=false", {
    body: JSON.stringify(payload),
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`,
      "Content-Type": "application/json"
    },
    method: "POST"
  });
};

export const getChildren = (parentToken: string) => {
  return http("api/users/children", {
    headers: {
      Authorization: "Bearer " + parentToken
    },
    method: "GET"
  });
};

export const getUserInfoFromToken = ({ userId, token }: { userId: number; token: string }) => {
  return http(`api/users/${userId}`, {
    headers: {
      Authorization: "Bearer " + token
    },
    method: "GET"
  });
};

export const createChildUser = (payload: ChilUserRequestTypes) => {
  return http("api/v2/Users", {
    body: JSON.stringify(payload),
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`,
      "Content-Type": "application/json"
    },
    method: "POST"
  });
};

export const assignChildToParent = ({
  parentId,
  parentToken,
  childId
}: {
  parentId: string;
  parentToken: string;
  childId: number;
}) => {
  return http(`api/Users/${parentId}/assignAsSubuser`, {
    headers: {
      Authorization: "Bearer " + parentToken,
      "Content-Type": "application/json"
    },
    params: { userid: childId },
    method: "POST"
  });
};

export const authWithNonce = ({ nonceToken }: { nonceToken: string }) => {
  return http("token", {
    body: `token=${nonceToken}&grant_type=nonce`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    method: "POST"
  });
};

export const updateRefreshToken = ({ refreshToken }: { refreshToken: string | null }) => {
  return http("token", {
    body: `grant_type=refresh_token&refresh_token=${refreshToken}`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    method: "POST"
  });
};

export const getUserInfo = ({ userId, refresh = true }: { userId: number; refresh?: boolean }) => {
  return http(`api/users/${userId}`, {
    method: "GET",
    params: { refresh }
  });
};

export const getLanguageList = (values: GetLanguageListRequestTypes) => {
  return http("api/languages", {
    params: values,
    method: "GET"
  });
};

export const sso = ({ token, grant_type, deviceId }: { token: string; grant_type: string; deviceId: string }) => {
  return http("token", {
    body: `token=${encodeURIComponent(token)}&grant_type=${grant_type}`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    params: { DeviceId: deviceId },
    method: "POST"
  });
};

export const authUsingOtp = ({ otp, email, deviceId }: { otp: string; email: string; deviceId: string }) => {
  const timeStamp = Math.floor(Date.now() / 1000);
  return http("token", {
    body: `token=${timeStamp}:${otp}:${encodeURIComponent(email)}&grant_type=OTP`,
    headers: {
      Authorization: `${process.env.REACT_APP_AUTHORIZATION_KEY}`
    },
    params: { DeviceId: deviceId },
    method: "POST"
  });
};

export const getNonceToken = (token: string) => {
  return http("api/users/getNonce", {
    headers: {
      Authorization: "Bearer " + token
    },
    method: "GET"
  });
};
