import { useEffect, useRef } from "react";

const useClickOutside = (closeAction: () => void) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const handleClickOutside = (event: MouseEvent) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
      closeAction();
    }
  };

  return [wrapperRef];
};

export default useClickOutside;
