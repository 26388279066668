import React from "react";
import { IconPropsType } from "./IconTypes";

const CrossIcon = ({ color = "#165B95", width = 14, height = 14 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 14 14">
      <path
        fill={color}
        fillRule="evenodd"
        d="M2.7.95A1.25 1.25 0 10.936 2.725l4.18 4.153-4.179 4.151A1.25 1.25 0 002.7 12.802l4.191-4.164 4.192 4.164a1.25 1.25 0 001.762-1.774l-4.18-4.151 4.18-4.153A1.25 1.25 0 1011.085.951L6.89 5.115 2.7.95z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default CrossIcon;
