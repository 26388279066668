import React from "react";

// assets
import { ArrowRightIcon } from "src/assets/icons";

// types
import { ButtonPrimaryPropsType } from "./ButtonPrimaryTypes";

// styles
import { StyledButton } from "./ButtonPrimary.styles";

const ButtonPrimary = ({
  title,
  onClick,
  type = "button",
  disabled,
  size = "medium",
  withArrow = false
}: ButtonPrimaryPropsType) => {
  return (
    <StyledButton type={type} disabled={disabled} onClick={onClick} className={size}>
      <span className="btn-title">
        {title}
        {withArrow && (
          <span className="btn-icon">
            <ArrowRightIcon />
          </span>
        )}
      </span>
      <span className="btn-background" />
    </StyledButton>
  );
};

export default ButtonPrimary;
