import React from "react";
import { IconPropsType } from "./IconTypes";

const WrenchIcon = ({ color = "#01324B", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.25583 19.8333C6.57083 20.1483 6.98917 20.3217 7.43417 20.3217C7.87917 20.3217 8.2975 20.1483 8.6125 19.8333L11.7997 16.6462C12.0618 16.384 12.4463 16.2951 12.8094 16.3697C13.2229 16.4546 13.6452 16.4975 14.0733 16.4975C15.7425 16.4975 17.3125 15.8483 18.4917 14.6683C20.28 12.8792 20.8142 10.2108 19.8517 7.87C19.6258 7.31997 18.9129 7.177 18.4924 7.5974L16.5473 9.54206C16.1567 9.93257 15.5234 9.93246 15.133 9.54181L14.7793 9.18794C14.3889 8.79739 14.389 8.16435 14.7794 7.77389L16.7234 5.82994C17.1443 5.40907 17.0013 4.69547 16.4508 4.46917C15.6925 4.1575 14.8917 4 14.07 4C12.4008 4 10.8317 4.65 9.6525 5.83C8.14341 7.33833 7.52764 9.47364 7.95008 11.5108C8.02544 11.8742 7.93656 12.2593 7.67412 12.5217L4.4875 15.7083C3.8375 16.3575 3.8375 17.4158 4.4875 18.065L6.25583 19.8333ZM9.65317 12.9001C9.89092 12.6624 9.96233 12.305 9.83417 11.9942C9.1275 10.2767 9.51917 8.31917 10.8308 7.00833C11.6958 6.14333 12.8458 5.66667 14.07 5.66667C14.2452 5.66667 14.3218 5.87403 14.1979 5.99792L12.4212 7.77463C12.0307 8.16513 12.0307 8.79824 12.4211 9.18877L15.1312 11.8994C15.5217 12.29 16.155 12.29 16.5455 11.8995L17.1363 11.3087C17.7338 10.7112 18.6546 11.1024 18.3517 11.8912C18.1259 12.4791 17.7766 13.0255 17.3117 13.4908C16.02 14.7825 14.0083 15.18 12.325 14.4875C12.0148 14.3589 11.6577 14.4299 11.4203 14.6674L7.43458 18.6546C7.43443 18.6547 7.43454 18.655 7.43476 18.655C7.43489 18.655 7.435 18.6551 7.435 18.6552L7.43417 19.4883V19.0692C7.43417 18.804 7.32881 18.5496 7.14127 18.3621L6.37301 17.5938C5.98246 17.2033 5.98249 16.5701 6.37307 16.1796L9.65317 12.9001Z"
        fill={color}
      />
    </svg>
  );
};

export default WrenchIcon;
