import { useEffect, useState } from "react";

const useScreenHeight = () => {
  const [screenHeight, setScreenHeight] = useState<number>(document.documentElement.clientHeight);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(document.documentElement.clientHeight);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [screenHeight];
};

export default useScreenHeight;
