import styled from "styled-components";

export const StyledCard = styled.div`
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid #f3f1f1;
  box-shadow: 0 13px 16px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;

  .label-wrapper {
    display: flex;
    justify-content: center;
    margin: -53px 0 0;
    padding: 0 3%;
  }

  .title {
    font-size: 22px;
    margin: 16px 0;
  }

  .subtitle {
    border-bottom: 1px dashed ${({ theme }) => theme.colors.blueLight2};
    color: #52525b;
    font-size: 15px;
    margin: 0 0 20px;
    padding: 0 0 20px;
  }

  .progress-wrapper {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  .progress-left {
    flex: 1;
    padding: 0 15px 0 0;
  }

  .progress {
    background-color: ${({ theme }) => theme.colors.blueLight1};
    border-radius: 4px;
    height: 8px;
    width: 100%;
  }

  .progress-bar {
    background-color: ${({ theme }) => theme.colors.blueLight};
    border-radius: 4px;
    display: flex;
    height: 8px;
  }

  .progress-amount {
    color: ${({ theme }) => theme.colors.blueLight};
    display: block;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    font-size: 17px;
    margin: 0 0 8px;
  }

  .progress-right {
    color: ${({ theme }) => theme.colors.blueLight};
    display: block;
    font-weight: ${({ theme }) => theme.fonts.fontWeightBold};
    font-size: 17px;
  }

  .action {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin: auto 0 0;
    padding: 20px 0 0;
  }

  .image-wrapper {
    margin: 0 auto;
    padding: 5px 0;
    min-width: 150px;
    max-width: 150px;
    position: relative;
    width: 150px;

    img {
      max-width: 130px;
      height: auto;
      object-fit: contain;
      position: relative;
      box-shadow: 0 8px 28px rgba(15, 15, 15, 0.26);
      border-radius: 6px;
      overflow: hidden;
      transform: rotate(-5deg);
      z-index: 99;
    }
  }

  .quiz-image-wrapper {
    display: flex;
    justify-content: center;
    padding: 22px 0 0;

    img {
      max-width: 120px;
      width: 90%;
    }
  }
`;
