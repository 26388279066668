import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  .main-layout {
    display: flex;
    position: relative;
  }

  .layout-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    height: calc(100vh - 80px);
    overflow: auto;

    @media ${device.tabletL} {
      height: calc(100vh - 46px);
    }

    @media ${device.tablet} {
      height: auto;
      padding-bottom: 58px;
      width: 100%;
    }

    @media (max-width: 575px) {
      bottom: 68px;
    }
  }

  .layout-sidebar {
    height: calc(100vh - 80px);
    position: relative;
    z-index: 99;

    @media ${device.tabletL} {
      height: calc(100vh - 46px);
    }

    @media ${device.tablet} {
      bottom: 0;
      height: fit-content;
      position: fixed;
      top: initial;
      width: 100%;
    }
  }
`;
