import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  position: relative;
  border-bottom: 1px solid #c2e0ef;

  @media ${device.mobileL} {
    padding: 5px 30px;
  }

  h2 {
    line-height: 0;

    @media ${device.tabletL} {
      font-size: 19px;
    }
  }

  &:after {
    background: #edf5fe;
    bottom: 0;
    display: block;
    content: "";
    height: 1px;
    opacity: 0.2;
    backdrop-filter: blur(7px);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .header-left {
    align-items: center;
    display: flex;
  }

  .header-center {
    align-items: center;
    display: flex;
    gap: 20px;
  }

  .earnings-list {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 18px;
  }

  .earning-item {
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeS};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  }

  .header-right {
    @media ${device.mobileL} {
      display: flex;
      align-items: center;
    }
  }

  .earning-image {
    display: flex;
    margin: 0 9px 0 0;

    img {
      width: 38px;

      @media ${device.tabletXL} {
        width: 14px;
      }
    }
  }

  .earning-value {
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.colors.textPrimary};

    @media ${device.tablet} {
      font-size: 12px;
    }
  }

  .earning-coefficient {
    display: block;
    margin-left: 5px;
    font-size: ${({ theme }) => theme.fonts.fontSizeBase};

    @media ${device.tablet} {
      font-size: 12px;
    }
  }

  .mobile-nav-toggle {
    align-items: center;
    ${({ theme }) => theme.colors.blueLight2};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 0 0 0 20px;
    width: 30px;
  }

  .user-greeting {
    color: ${({ theme }) => theme.colors.textPrimary};
  }
`;
