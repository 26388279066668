import React from "react";

// styles
import { StyledRating } from "./Rating.styles";
import { StarIcon } from "../../assets/icons";
import { times } from "lodash";

const Rating = ({ value }: { value: number }) => {
  const rating = Math.round(value);
  return (
    <StyledRating className="rating-bar">
      {times(5, (i) => (
        <StarIcon key={i} width={15} height={13} color={i < rating ? "#EFD43D" : "#345B6F"} />
      ))}
    </StyledRating>
  );
};

export default Rating;
