import React, { ReactNode, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

// components
import { ButtonPrimary, HeadingH2, MoneyCountIcon } from "src/components";
import Timer from "../timer";
import QuizFeedback from "src/components/QuizFeedback/QuizFeedback";

// assets
import dragonImg from "src/assets/images/quiz-dragon.png";
import dialogImg from "src/assets/images/quiz-comment.svg";
import { CheckIcon, FalseIcon } from "src/assets/icons";

// styles
import { StyledQuestion } from "./Question.styles";

// types
import { QuestionItemTypes } from "./QuestionTypes";
import { RenderTimeTypes } from "../timer/TimerTypes";
import { useScreenWidth } from "src/helpers";
import { ACTIONS, logAction } from "../../../../services/analytics/ManenoAnalytics";

// helpers
import { useAppFlags } from "../../../../helpers/useAppFlags";

const Question = ({
  questionText,
  hint,
  correctAnswer,
  wrongAnswers,
  reward,
  timeSeconds,
  handleNextQuestion,
  handleQuizFeedback,
  isLastQuestion,
  setGameStatistic,
  bookQuestionId,
  setIsCompleted,
  isPlaying,
  setIsPlaying
}: QuestionItemTypes) => {
  const [t] = useTranslation();
  const [selectedAnswer, setAnswer] = useState<string | null>(null);
  const [remainingTimeSeconds, setRemainingTimeSeconds] = useState<number>(timeSeconds);
  const [isTimerRestarting, setIsTimerRestarting] = useState<boolean>(false);
  const [allAnswers, setAllAnswers] = useState<any[]>([]);
  const [screenWidth] = useScreenWidth();

  const isTabletXL = screenWidth <= 1200;

  const shuffleArray = (array: any[]): any[] => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      const temp = array[i];
      array[i] = array[j];
      array[j] = temp;
    }
    return array;
  };

  const { quizFeedbackEnabled } = useAppFlags();

  useEffect(() => {
    setAllAnswers(shuffleArray([...wrongAnswers, correctAnswer]));
  }, [wrongAnswers, correctAnswer]);

  const handleSelectAnswer = (answer?: string) => {
    if (!selectedAnswer) {
      answer && setAnswer(answer);
      setIsPlaying(false);
      setGameStatistic((prevState: any) => ({
        ...prevState,
        reward: prevState.reward + (answer === correctAnswer ? reward : 0),
        responseLog: [
          ...prevState.responseLog,
          {
            bookQuestionId,
            answer: answer || "",
            correct: answer === correctAnswer,
            reward: answer === correctAnswer ? reward : 0,
            timeSeconds: timeSeconds - remainingTimeSeconds
          }
        ]
      }));

      //Analytics
      if (answer === correctAnswer) {
        logAction(ACTIONS.QUIZ_ANSWER_CORRECT);
      } else {
        logAction(ACTIONS.QUIZ_ANSWER_WRONG);
      }
    }
  };

  const reloadTimer = () => {
    setIsTimerRestarting(true);
    setRemainingTimeSeconds(timeSeconds);
    setTimeout(() => setIsTimerRestarting(false), 100);
    setIsPlaying(true);
  };

  const handleNextClick = () => {
    setAnswer(null);
    handleNextQuestion();
    reloadTimer();
  };

  const handleQuizFeedbackSelection = (type: number, feedback: string | null) => {
    handleQuizFeedback(bookQuestionId, type, feedback);
  };

  const handleCompleteQuizClick = () => {
    setIsPlaying(false);
    (!selectedAnswer || !remainingTimeSeconds) && handleSelectAnswer();
    setIsCompleted(true);

    //Analytics
    logAction(ACTIONS.QUIZ_COMPLETE);
  };

  const renderTime = ({ remainingTime }: RenderTimeTypes): ReactNode => {
    useEffect(() => {
      setRemainingTimeSeconds(remainingTime);
    }, [remainingTime]);

    return (
      <div className="timer">
        <div className="text">{t("quiz_timer_label")}</div>
        <div className="value">{remainingTime}</div>
      </div>
    );
  };

  const onTimeComplete = () => {
    setIsPlaying(false);
    !selectedAnswer && handleSelectAnswer();
  };

  return (
    <StyledQuestion>
      <div className="left">
        <div className="title-wrapper">
          <HeadingH2 title={questionText} />
          {isTabletXL && (
            <div className="timer-wrapper">
              {!isTimerRestarting && (
                <Timer
                  duration={timeSeconds}
                  remainingTime={remainingTimeSeconds}
                  isPlaying={isPlaying}
                  renderTime={renderTime}
                  onComplete={onTimeComplete}
                />
              )}
            </div>
          )}
        </div>
        <div className="tooltip hide">{t(hint)}</div>
        <ul className="answers-list">
          {allAnswers &&
            allAnswers.map((item, index: number) => {
              if (item) {
                return (
                  <li
                    key={`answer-${index}`}
                    className={`answer-item ${selectedAnswer && item !== selectedAnswer && "disabled"} ${
                      !remainingTimeSeconds && !selectedAnswer && item !== correctAnswer && "disabled"
                    }`}
                  >
                    <button
                      onClick={() => remainingTimeSeconds && handleSelectAnswer(item)}
                      className={`answer-btn ${
                        item === selectedAnswer ? (item === correctAnswer ? "success" : "error") : ""
                      } ${!remainingTimeSeconds && item === correctAnswer ? "success" : ""}`}
                    >
                      {(item === selectedAnswer || (!remainingTimeSeconds && !isTimerRestarting)) && (
                        <span className="icon-wrapper">{item === correctAnswer ? <CheckIcon /> : <FalseIcon />}</span>
                      )}
                      {item}
                    </button>
                  </li>
                );
              }
            })}
        </ul>
        <div className="btn-wrapper">
          {(selectedAnswer || (!selectedAnswer && !remainingTimeSeconds)) && !isLastQuestion && (
            <ButtonPrimary title={t("label_next_question")} onClick={handleNextClick} />
          )}
          {isLastQuestion && <ButtonPrimary title={t("SKIP TO TASKS")} onClick={handleCompleteQuizClick} />}
        </div>
        {quizFeedbackEnabled && (selectedAnswer || (!selectedAnswer && !remainingTimeSeconds)) && !isLastQuestion && (
          <QuizFeedback handleFeedback={handleQuizFeedbackSelection} />
        )}
      </div>
      <div className="right">
        <div className="comment-container">
          <div className="comment-wrapper">
            {selectedAnswer === correctAnswer && (
              <>
                <div className="comment">
                  <span className="text">{t("quiz_animation_success_text", { 0: String(reward) })}</span>
                  <MoneyCountIcon count={reward} />
                </div>
                <Img src={dialogImg} alt="comment" />
              </>
            )}
          </div>
        </div>
        <div className="image-container">
          <div className="image-wrapper">
            <div className="image-overlay"></div>
            <Img src={dragonImg} alt="dragon" />
          </div>
        </div>
        {!isTabletXL && (
          <div className="timer-wrapper">
            {!isTimerRestarting && (
              <Timer
                duration={timeSeconds}
                remainingTime={remainingTimeSeconds}
                isPlaying={isPlaying}
                renderTime={renderTime}
                onComplete={onTimeComplete}
              />
            )}
          </div>
        )}
      </div>
    </StyledQuestion>
  );
};

export default Question;
