import {
  GET_BOOK_QUIZ,
  SAVE_BOOK_QUIZ,
  GET_STUDENT_ASSIGNMENTS,
  GET_QUIZ_DETAILS,
  GET_BOOK_GAMES,
  RESET_BOOK_GAMES,
  GET_PENDING_ASSIGNMENTS_COUNT,
  SET_NEW_ASSIGNMENTS,
  SET_OVERDUE_ASSIGNMENTS
} from "src/redux/actions/quiz";
import { ACCEPT_ASSIGNMENT } from "src/redux/actions/assignments";
import {
  GetBookGamesResponseTypes,
  GetBookQuizResponseTypes,
  GetBookQuizResultsTypes,
  GetPendingAssignmentsCountResponseTypes,
  GetStudentAssignmentResponseTypes
} from "src/redux/actions/quiz/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: QuizState = {
  isLoading: false,
  isLoadingAssignments: false,
  bookQuiz: undefined,
  bookQuizResults: undefined,
  pendingAssignmentsCount: 0,
  studentAssignments: undefined,
  newAssignments: undefined,
  overdueAssignments: undefined,
  bookGames: [],
  error: undefined
};

export interface QuizState {
  isLoading: boolean;
  isLoadingAssignments: boolean;
  bookQuiz: GetBookQuizResponseTypes | undefined;
  bookQuizResults: GetBookQuizResultsTypes | undefined;
  pendingAssignmentsCount: number;
  studentAssignments: GetStudentAssignmentResponseTypes | undefined;
  newAssignments: GetStudentAssignmentResponseTypes | undefined;
  overdueAssignments: GetStudentAssignmentResponseTypes | undefined;
  bookGames: GetBookGamesResponseTypes | undefined;
  error: any;
}

const quiz = (
  state = initialState,
  action: IActionType<
    | GetBookQuizResponseTypes
    | GetBookQuizResultsTypes
    | GetStudentAssignmentResponseTypes
    | GetPendingAssignmentsCountResponseTypes
  >
): QuizState => {
  switch (action.type) {
    case GET_BOOK_QUIZ.REQUEST:
    case GET_QUIZ_DETAILS.REQUEST:
    case SAVE_BOOK_QUIZ.REQUEST:
    case ACCEPT_ASSIGNMENT.REQUEST:
    case GET_PENDING_ASSIGNMENTS_COUNT.REQUEST:
    case GET_BOOK_GAMES.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case GET_STUDENT_ASSIGNMENTS.REQUEST:
      return {
        ...state,
        isLoading: true,
        isLoadingAssignments: true,
        error: undefined
      };

    case GET_BOOK_QUIZ.SUCCESS:
    case GET_QUIZ_DETAILS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookQuiz: action.payload as GetBookQuizResponseTypes
      };

    case SAVE_BOOK_QUIZ.SUCCESS:
      return {
        ...state,
        isLoading: false,
        bookQuizResults: action.payload as GetBookQuizResultsTypes
      };

    case GET_STUDENT_ASSIGNMENTS.SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoadingAssignments: false,
        studentAssignments: action.payload as GetStudentAssignmentResponseTypes
      };

    case ACCEPT_ASSIGNMENT.SUCCESS:
      return {
        ...state,
        isLoading: false
      };

    case GET_BOOK_GAMES.SUCCESS:
      return {
        ...state,
        bookGames: action.payload as GetBookGamesResponseTypes,
        isLoading: false
      };

    case GET_PENDING_ASSIGNMENTS_COUNT.SUCCESS:
      return {
        ...state,
        isLoading: false,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        pendingAssignmentsCount: action.payload?.pendingCount
      };

    case RESET_BOOK_GAMES: {
      return {
        ...state,
        bookGames: []
      };
    }

    case SET_NEW_ASSIGNMENTS: {
      return {
        ...state,
        newAssignments: action.payload as GetStudentAssignmentResponseTypes
      };
    }

    case SET_OVERDUE_ASSIGNMENTS: {
      return {
        ...state,
        overdueAssignments: action.payload as GetStudentAssignmentResponseTypes
      };
    }

    case GET_BOOK_QUIZ.ERROR:
    case SAVE_BOOK_QUIZ.ERROR:
    case ACCEPT_ASSIGNMENT.ERROR:
    case GET_QUIZ_DETAILS.ERROR:
    case GET_BOOK_GAMES.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case GET_STUDENT_ASSIGNMENTS.ERROR:
      return {
        ...state,
        isLoading: false,
        isLoadingAssignments: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default quiz;
