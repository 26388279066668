import React from "react";

// types
import { HeadingH4PropsType } from "./HeadingH4Types";

// styles
import { StyledHeading } from "./HeadingH4.styles";

const HeadingH4 = ({ title, align = "left", color = "#165B95" }: HeadingH4PropsType) => {
  return (
    <StyledHeading color={color} align={align}>
      {title}
    </StyledHeading>
  );
};

export default HeadingH4;
