import React from "react";
import { IconPropsType } from "./IconTypes";

const FalseIcon = ({ color = "#fff", width = 17, height = 16 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.8291 10.5344C13.7849 10.6643 13.6591 10.8651 13.4517 11.1367C13.2445 11.3867 13.0321 11.6206 12.8144 11.8383C12.368 12.2846 11.961 12.5525 11.5931 12.6419C11.2146 12.7206 10.7897 12.5243 10.3185 12.0531L8.55145 10.286L6.08576 12.7517C5.89175 12.7101 5.67115 12.6201 5.42395 12.4818C5.18764 12.3326 4.9731 12.1616 4.78033 11.9688C4.39479 11.5833 4.20344 11.2177 4.20626 10.8722C4.19838 10.516 4.3414 10.1909 4.63532 9.89697L6.39886 8.13343L3.70009 5.43466C3.77683 5.29368 3.90799 5.09826 4.09358 4.84841C4.27935 4.57696 4.4811 4.33238 4.69882 4.11466C5.13426 3.67922 5.54125 3.42216 5.91979 3.34348C6.30921 3.25392 6.74488 3.4501 7.22681 3.93203L8.91354 5.61876L11.3302 3.20205C11.5132 3.27614 11.7283 3.38235 11.9755 3.52069C12.2228 3.63743 12.4483 3.79754 12.6517 4.00102C13.0266 4.37585 13.2179 4.74138 13.2258 5.09762C13.223 5.44314 13.08 5.75742 12.797 6.04046L11.0661 7.77134L13.8291 10.5344Z"
        fill={color}
      />
    </svg>
  );
};

export default FalseIcon;
