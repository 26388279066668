import styled from "styled-components";
import { device } from "../../theme/mediaQueries";

export const StyledBugReportButton = styled.div`
  position: fixed;
  right: 16px;
  bottom: 36px;
  z-index: 1000;
  transform: translateZ(0);

  @media ${device.tablet} {
    right: inherit;
    left: 16px;
    bottom: 18px;
  }

  button {
    background-color: ${({ theme }) => theme.colors.blueLight2};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    padding: 5px 16px;

    .icon-wrapper {
      padding: 0;
    }

    svg {
      width: 28px;
      height: 28px;

      @media ${device.tablet} {
        width: 18px;
        height: 18px;
      }

      path {
        fill: ${({ theme }) => theme.colors.red3};
      }
    }
  }
`;
