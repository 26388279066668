import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledButtonPlay = styled.div`
  a {
    align-items: center;
    display: flex;
    padding: 8px 30px;
    text-decoration: none;

    .btn-title {
      overflow: hidden;
      width: auto;
      transition: width 300ms ease-in-out;
    }

    @media ${device.tablet} {
      height: 52px;
      min-width: 73px;
      width: 73px;
      position: relative;
    }

    &.collapsed {
      justify-content: center;
      padding: 8px 0;

      .btn-title {
        padding: 0;
        width: 0;
      }
    }

    &:hover {
      filter: brightness(1.1);
    }
  }

  .icon-wrapper {
    height: 72px;
    min-width: 72px;
    overflow: hidden;
    width: 72px;

    @media ${device.tablet} {
      position: absolute;
      display: flex;
      justify-content: center;
    }

    img {
      object-fit: contain;

      @media ${device.tablet} {
        height: 68px;
        width: 68px;
      }
    }
  }

  .btn-title {
    color: ${({ theme }) => theme.colors.blueLight};
    display: inline-flex;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    padding: 0 0 0 8px;
  }
`;
