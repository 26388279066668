import http from "../http";
import {
  GetBookAdditionalDataRequestTypes,
  GetBookListRequestTypes,
  GetBookURLRequestTypes,
  GetReadingRewardsRequestTypes,
  GetRelatedBooksRequestTypes,
  GetTagListRequestTypes,
  UpdateBookStatisticsExtendedRequestTypes
} from "../../redux/actions/book/types";

export const getBookList = (values: GetBookListRequestTypes) => {
  return http("api/books/MetadataSearchExtended", {
    params: values,
    method: "GET"
  });
};

export const getRelatedBooks = (values: GetRelatedBooksRequestTypes) => {
  return http("api/books/relatedBooksRecommendation", {
    params: values,
    method: "GET"
  });
};

export const getTagList = ({ id, languageId = 2 }: GetTagListRequestTypes) => {
  return http(`api/tagtype/${id}/tags`, {
    params: languageId,
    method: "GET"
  });
};

export const getBookURL = (values: GetBookURLRequestTypes) => {
  return http("api/books/directZipped", {
    params: values,
    method: "GET"
  });
};

export const updateBookStatistics = ({ bookId, statistic }: UpdateBookStatisticsExtendedRequestTypes) => {
  return http(`api/book/${bookId}/readingOrListeningStatistics`, {
    method: "POST",
    body: JSON.stringify({ ...statistic })
  });
};

export const getBookAdditionalData = (values: GetBookAdditionalDataRequestTypes) => {
  return http("api/books/GetBookAdditionalData", {
    params: values,
    method: "GET"
  });
};

export const getReadingRewards = (values: GetReadingRewardsRequestTypes) => {
  return http("api/books/GetRewardsForReadingHistory", {
    params: values,
    method: "GET"
  });
};
