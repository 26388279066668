import { GET_DRAGONS_LIST, UPDATE_DRAGON, GET_SKINS_LIST } from "src/redux/actions/dragon/index";
import { GetDragonsListResponseTypes, GetSkinsListResponseTypes } from "src/redux/actions/dragon/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: DragonState = {
  isLoading: false,
  dragonsList: undefined,
  skinsList: undefined,
  error: undefined
};

export interface DragonState {
  isLoading: boolean;
  dragonsList: GetDragonsListResponseTypes | undefined;
  skinsList: GetSkinsListResponseTypes | undefined;
  error: any;
}

const dragon = (
  state = initialState,
  action: IActionType<GetDragonsListResponseTypes | GetSkinsListResponseTypes>
): DragonState => {
  switch (action.type) {
    case GET_DRAGONS_LIST.REQUEST:
    case UPDATE_DRAGON.REQUEST:
    case GET_SKINS_LIST.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case UPDATE_DRAGON.SUCCESS:
      return {
        ...state,
        isLoading: false,
        error: undefined
      };

    case GET_DRAGONS_LIST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        dragonsList: action.payload as GetDragonsListResponseTypes
      };

    case GET_SKINS_LIST.SUCCESS:
      return {
        ...state,
        isLoading: false,
        skinsList: action.payload as GetSkinsListResponseTypes
      };

    case GET_DRAGONS_LIST.ERROR:
    case UPDATE_DRAGON.ERROR:
    case GET_SKINS_LIST.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default dragon;
