import React from "react";
import { IconPropsType } from "./IconTypes";

const ArrowBottomIcon = ({ color = "#fff", width = 15, height = 10 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.4082 0.113409C11.5042 0.0814085 11.6162 0.0574083 11.7442 0.0414085C11.8722 0.0414085 11.9922 0.0414085 12.1042 0.0414085C12.3922 0.0414085 12.6642 0.0734091 12.9202 0.137408C13.1762 0.201408 13.4002 0.305408 13.5922 0.449409C13.7842 0.593409 13.9362 0.777409 14.0482 1.00141C14.1762 1.24141 14.2402 1.53741 14.2402 1.88941C14.2402 2.22541 14.1682 2.55341 14.0242 2.87341C13.8802 3.20941 13.6722 3.61741 13.4002 4.09741L9.42423 9.25741C8.88023 9.67341 8.16023 9.88141 7.26423 9.88141C6.59223 9.88141 6.07223 9.78541 5.70423 9.59341C5.33623 9.40141 5.04823 9.12141 4.84023 8.75341L0.000234696 2.15341C0.208235 1.36941 0.488235 0.833408 0.840235 0.545408C1.20823 0.273408 1.60023 0.137408 2.01623 0.137408C2.57623 0.137408 3.00824 0.273408 3.31223 0.545408C3.63223 0.833408 3.92823 1.24941 4.20023 1.79341L7.26423 6.38125L11.4082 0.113409Z"
        fill={color}
      />
    </svg>
  );
};

export default ArrowBottomIcon;
