import React from "react";
import { IconPropsType } from "./IconTypes";

const ArrowBackIcon = ({ color = "#fff", width = 16, height = 13 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 16 13">
      <path
        fill={color}
        fillRule="evenodd"
        d="M9.84 2.537c-.016.128-.04.24-.072.336L6.284 4.621c3.128-.468 7.543-.994 8.804-.46 1.407.597.896 3.5-.112 3.5-.944 0-5.382-.195-8.58-.341l1.692.761c.544.272.96.568 1.248.888.272.304.408.736.408 1.296 0 .416-.136.808-.408 1.176-.288.352-.824.632-1.608.84l-6.6-3.84a2.08 2.08 0 01-.84-.864C.096 7.21 0 6.69 0 6.017c0-.896.208-1.616.624-2.16L5.784.881c.48-.272.888-.48 1.224-.624.32-.144.648-.216.984-.216.352 0 .648.064.888.192.224.112.408.264.552.456.144.192.248.416.312.672.064.256.096.528.096.816v.36z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export default ArrowBackIcon;
