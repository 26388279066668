import styled from "styled-components";

export const StyledRating = styled.div`
  display: flex;
  margin-bottom: 13px;

  & svg {
    margin-right: 6px;
  }
`;
