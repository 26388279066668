import { useEffect, useState } from "react";

const useScreenWidth = () => {
  const [screenWidth, setScreenWidth] = useState<number>(document.documentElement.clientWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(document.documentElement.clientWidth);
    };
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return [screenWidth];
};

export default useScreenWidth;
