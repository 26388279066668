import React from "react";

// components
import Sidebar from "../Sidebar";
import Header from "../Header";
import ErrorBoundary from "../ErrorBoundary";
import { HeaderQuiz } from "src/modules/quiz/partials";
import HeaderReading from "src/modules/bookReading/partials/header";
import Error404 from "../Error404/Error404";
// import BugReport from "../BugReport/BugReport";

// types
import { LayoutPropsType } from "./LayoutTypes";

// styles
import { StyledLayout } from "./Layout.styles";
import BugReport from "../BugReport/BugReport";

const Layout = ({
  children,
  withHeader,
  headerNav,
  headerType = "general",
  className,
  withSidebar = true,
  rewardAmount,
  setIsPlaying,
  is404,
  readingAction
}: LayoutPropsType) => {
  const getHeader = () => {
    switch (headerType) {
      case "quiz":
        return (
          <HeaderQuiz rewardAmount={rewardAmount} setIsPlaying={setIsPlaying}>
            {headerNav}
          </HeaderQuiz>
        );
      case "reading":
        return <HeaderReading action={readingAction}>{headerNav}</HeaderReading>;
      default:
        return <Header>{headerNav}</Header>;
    }
  };

  return (
    <StyledLayout className={className}>
      {withHeader && <ErrorBoundary>{getHeader()}</ErrorBoundary>}
      <div className="main-layout">
        {withSidebar && (
          <div className="layout-sidebar">
            <ErrorBoundary>
              <Sidebar />
            </ErrorBoundary>
          </div>
        )}
        <div className="layout-content" id="layoutContent">
          {is404 ? <Error404 /> : children}
        </div>
      </div>
      {headerType != "quiz" && <BugReport />}
    </StyledLayout>
  );
};

export default Layout;
