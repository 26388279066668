import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledHeader = styled.div`
  background-color: ${({ theme }) => theme.colors.blueLight};
  display: flex;
  justify-content: space-between;
  padding: 18px 30px;
  position: relative;

  @media ${device.tabletL} {
    padding: 8px 30px;
  }

  @media (max-width: 480px) {
    padding: 8px 20px;
  }

  &:after {
    background: #edf5fe;
    bottom: 0;
    display: block;
    content: "";
    height: 1px;
    opacity: 0.2;
    backdrop-filter: blur(7px);
    left: 0;
    position: absolute;
    width: 100%;
  }

  .header-left {
    align-items: center;
    display: flex;
  }

  .header-center {
    align-items: center;
    display: flex;
    gap: 20px;

    &.visible {
      transform: translateY(0);
    }

    h3 {
      color: ${({ theme }) => theme.colors.white};
      margin: 0;
      padding: 0;
    }

    @media ${device.tabletL} {
      gap: 0;
      padding: 0 0 0 42px;
    }

    @media (max-width: 480px) {
      background-color: ${({ theme }) => theme.colors.blueLight};
      border-top: 1px solid ${({ theme }) => theme.colors.blueLight2};
      border-bottom: 1px solid ${({ theme }) => theme.colors.blueLight2};
      box-shadow: 0 0 18px rgb(0 0 0 / 20%);
      display: flex;
      flex-direction: column;
      position: absolute;
      padding: 15px 20px;
      width: 100%;
      top: 41px;
      left: 0;
      transform: translateY(-150%);
      transition: transform 300ms ease-in-out;
      z-index: 99;
    }
  }

  .header-right {
    @media ${device.tabletL} {
      align-items: center;
      display: flex;
    }
  }

  .earnings-list {
    align-items: center;
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
    gap: 18px;

    @media ${device.tabletL} {
      gap: 8px;
    }
  }

  .earning-item {
    align-items: center;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: ${({ theme }) => theme.fonts.fontSizeS};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};

    @media ${device.tabletL} {
      font-size: 12px;
    }
  }

  .earning-image {
    display: flex;
    margin: 0 11px 0 0;

    @media ${device.tabletL} {
      margin: 0 4px 0 0;
    }

    img {
      width: 38px;

      @media ${device.tabletL} {
        width: 14px;
      }
    }
  }

  .mobile-nav-toggle {
    align-items: center;
    ${({ theme }) => theme.colors.blueLight2};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 0 0 0 20px;
    width: 30px;
  }
`;
