import { take, put } from "redux-saga/effects";
import { GET_DRAGONS_LIST, getDragonsListActions } from "src/redux/actions/dragon";

// services
import { getDragonsList } from "src/services/dragon";

// types
import { GetDragonsListResponseTypes, GetDragonsListRequestTypes } from "src/redux/actions/dragon/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetDragonsListRequest() {
  while (true) {
    const { payload }: IActionType<GetDragonsListRequestTypes> = yield take(GET_DRAGONS_LIST.REQUEST);

    try {
      const result: GetDragonsListResponseTypes = yield call(getDragonsList, payload);
      yield put(getDragonsListActions.success(result));
    } catch (e) {
      yield put(getDragonsListActions.error(e as any));
    }
  }
}

export default watchGetDragonsListRequest;
