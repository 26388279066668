import { requestTypeCreator } from "src/helpers/index";
import {
  GetLeaderboardUsersRequestTypes,
  GetLeaderboardUsersResponseTypes,
  ResetLeaderboardTopUsersTypes
} from "./types";

export const GET_LEADERBOARD_TOP_USERS = requestTypeCreator("GET_LEADERBOARD_TOP_USERS");
export const RESET_LEADERBOARD_TOP_USERS = "RESET_LEADERBOARD_TOP_USERS";

export const resetLeaderboardTopUsersAction = (): ResetLeaderboardTopUsersTypes => {
  return {
    type: RESET_LEADERBOARD_TOP_USERS
  };
};

export const getLeaderboardTopUsersActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_LEADERBOARD_TOP_USERS.ERROR
  }),
  request: (payload: any): IActionType<GetLeaderboardUsersRequestTypes> => {
    return {
      payload,
      type: GET_LEADERBOARD_TOP_USERS.REQUEST
    };
  },
  success: (payload: GetLeaderboardUsersResponseTypes): IActionType<GetLeaderboardUsersResponseTypes> => ({
    payload,
    type: GET_LEADERBOARD_TOP_USERS.SUCCESS
  })
};
