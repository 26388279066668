import { take, put } from "redux-saga/effects";
import { GET_LANGUAGE_LIST, getLanguageListActions } from "src/redux/actions/user";

// services
import { getLanguageList } from "src/services/user";

// types
import { GetLanguageListRequestTypes, GetLanguageListResponseTypes } from "src/redux/actions/user/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetLanguageListRequest() {
  while (true) {
    const { payload }: IActionType<GetLanguageListRequestTypes> = yield take(GET_LANGUAGE_LIST.REQUEST);

    try {
      const result: GetLanguageListResponseTypes = yield call(getLanguageList, payload);
      yield put(getLanguageListActions.success(result));
    } catch (e) {
      yield put(getLanguageListActions.error(e as any));
    }
  }
}

export default watchGetLanguageListRequest;
