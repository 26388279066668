import { take, put } from "redux-saga/effects";
import { GET_BOOK_LIST, getBookListActions } from "src/redux/actions/book";

// services
import { getBookList } from "src/services/book";

// types
import { GetBookListRequestTypes, GetBookListResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetBookListRequest() {
  while (true) {
    const { payload }: IActionType<GetBookListRequestTypes> = yield take(GET_BOOK_LIST.REQUEST);

    try {
      const result: GetBookListResponseTypes = yield call(getBookList, payload);
      yield put(getBookListActions.success({ ...result, clear: !payload.offset }));
    } catch (e) {
      console.log(e, "error");
      yield put(getBookListActions.error(e as any));
    }
  }
}

export default watchGetBookListRequest;
