import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledModal = styled.div`
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  top: -10px;
  transition: opacity 200ms ease-in 0s, top 200ms ease-in 0s, visibility 0s ease-in 200ms;
  pointer-events: none;
  width: 100%;
  right: 0;
  bottom: 0;
  z-index: 1050;
  overflow-x: hidden;
  overflow-y: auto;

  &.visible {
    opacity: 1;
    visibility: visible;
    top: 0;
    transition-duration: 200ms, 200ms, 0s;
    transition-property: opacity, top, visibility;
    transition-delay: 200ms, 200ms, 0s;
    pointer-events: initial;
  }

  .modal-overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    position: fixed;
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    pointer-events: initial;
    z-index: 1040;
  }

  .form-row-center {
    margin: 0 0 17px;
    text-align: center;

    @media ${device.tabletXL} {
      margin: 0 0 24px;
    }
  }

  .modal {
    background-color: ${({ theme }) => theme.colors.blueLight5};
    border-radius: 20px;
    position: relative;
    height: fit-content;
    min-height: 100px;
    margin: 15vh 0 5vh 0;
    max-width: 550px;
    width: 90%;
    z-index: 1060;

    @media ${device.tablet} {
      border-radius: 24px;
      margin: 19% 0 10% 0;
    }
  }

  .modal-inner {
    padding: 50px 44px;

    @media ${device.tablet} {
      padding: 54px 24px 24px;
    }
  }

  &.successModal {
    .modal-inner {
      padding-top: 90px;

      @media ${device.tablet} {
        padding-top: 50px;
      }
    }
  }

  .circleCheck {
    width: 100%;
    position: absolute;
    top: -75px;
    display: flex;
    justify-content: center;

    &ImageWrapper {
      width: 150px;
      height: 150px;
      background-color: ${({ theme }) => theme.colors.blueLight5};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
    }

    @media ${device.tablet} {
      top: -35px;

      &ImageWrapper {
        width: 75px;
        height: 75px;

        img {
          max-width: 60%;
        }
      }
    }
  }

  .form-input {
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: 1.5px solid ${({ theme }) => theme.colors.blueLight2};
    border-radius: 40px;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-size: ${({ theme }) => theme.fonts.fontSizeXS};
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    height: 72px;
    padding: 0 18px 0 18px;
    width: 100%;

    &:focus,
    &:focus-visible {
      border-color: ${({ theme }) => theme.colors.blueLight};
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus {
      border: 2px solid ${({ theme }) => theme.colors.blueLight};
      font-size: ${({ theme }) => theme.fonts.fontSizeS};
      font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
      -webkit-text-fill-color: ${({ theme }) => theme.colors.textPrimary};
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
    }
  }

  .input-wrapper {
    position: relative;
  }

  .modal-content {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  .btn-modal-close {
    align-items: center;
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 32px;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 32px;
    z-index: 9;

    &:hover {
      filter: brightness(1.2);
    }
  }

  .modal-btn-group {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    margin: 20px 0 0;
  }
`;
