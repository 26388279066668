import React from "react";
import { IconPropsType } from "./IconTypes";

const DragPointerIcon = ({ color = "#ffffff", width = 60, height = 76 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 60 76">
      <rect
        width="11.19"
        height="2.398"
        x="31.169"
        y="1.582"
        fill={color}
        rx="1.199"
        transform="rotate(131.294 31.17 1.582)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="35.277"
        y="10.104"
        fill={color}
        rx="1.199"
        transform="rotate(-130.11 35.277 10.104)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="28.468"
        y="14.072"
        fill={color}
        rx="1.199"
        transform="rotate(-90.005 28.468 14.072)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="1.582"
        y="27.599"
        fill={color}
        rx="1.199"
        transform="rotate(41.294 1.582 27.6)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="10.104"
        y="23.492"
        fill={color}
        rx="1.199"
        transform="rotate(139.89 10.104 23.492)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="14.072"
        y="30.3"
        fill={color}
        rx="1.199"
        transform="rotate(179.995 14.072 30.3)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="58.188"
        y="30.841"
        fill={color}
        rx="1.199"
        transform="rotate(-138.706 58.188 30.841)"
      ></rect>
      <rect
        width="11.19"
        height="2.398"
        x="49.667"
        y="34.949"
        fill={color}
        rx="1.199"
        transform="rotate(-40.11 49.667 34.949)"
      ></rect>
      <rect width="11.19" height="2.398" x="45.699" y="28.139" fill={color} rx="1.199"></rect>
      <path
        fill={color}
        d="M22.011 49.325c-.8-1.825-3.144-2.661-5.205-2.653-.66.003-1.28.096-1.802.274-.425.145-.761.338-.957.574-.212.255-.22.662.091 1.238l7.475 13.802.035.106c.971 2.986 2.427 5.265 4.167 6.934 2.045 1.96 4.5 3.089 7.026 3.536 2.55.451 5.172.211 7.524-.567 2.172-.718 4.102-1.89 5.52-3.394 1.948-2.066 5.99-7.012 6.82-11.185.548-2.756-.827-4.802-3.415-3.538-.13.064-1.063.25-.29-.942.849-3.248.934-6.872-2.61-7.85a3.225 3.225 0 00-1.229-.096c-1.315.153-3.242 3.403-3.91 6.101-.202.818-.923.32-.72-.298.857-2.601 1.412-6.855-.72-8.615-2.957-2.44-5.122 2.39-5.825 4.548l-.377-5.663.125-6.419c-.112-1.68-.988-2.895-2.128-3.488-.156.529-3.37.43-3.447.059-1.36.778-2.479 2.552-2.713 5.572-.261 3.367.19 7.99.753 11.242.313 1.806 1.107 3.349 1.3 5.09.292 3.459-4.71-2.595-5.488-4.368zm-10.898-2.21a10.765 10.765 0 01.014-1.763c.133-1.473.763-4.938 2.734-4.364.514.15.868.527 1.332 1.019.55.584 1.326 1.41 2.505 1.82l.474.306c1.878.253 3.781 1.04 5.085 2.414-.349-2.878-.643-6.489-.419-9.382.28-3.61 1.651-5.965 3.41-7.26.26-.719.655-1.582 1.245-2.415.603-.853 1.41-1.675 2.485-2.288 1.63-.93 2.374-.845 2.871.043.228.405.223.814.216 1.348-.008.59-.018 1.444.447 2.255l1.141 1.99c.939 1.063 1.574 2.488 1.69 4.205l.329 4.91c.24-.121.483-.216.727-.286 1.622-.466 3.233.173 4.252 1.493.515.667.894 1.52 1.063 2.518a5.244 5.244 0 012.15-.733 5.903 5.903 0 014.205 1.165c2.535 1.57 2.653 4.043 2.845 6.77 2.52-.761 3.98 2.77 3.36 6.673-.05 5.26-5.745 11.58-7.497 13.437-1.718 1.822-4.027 3.233-6.604 4.085-2.743.907-5.802 1.187-8.782.66-3.003-.532-5.928-1.88-8.376-4.228-2.018-1.935-3.7-4.542-4.816-7.918l-7.351-13.573c-.721-1.332-.369-2.24-.735-2.901zm1.984-2.34c.12-.06.48-.222.606-.276.235-.1.481-.19.737-.27-.29-.27-.54-.536-.76-.77-.246-.26-.366-.44-.4-.45-.407.055-.182 1.762-.183 1.767zm18.01-18.12c-.856.246-2.147 2.217-2.088 2.64.393.402 1.598.926 2.115.223-.05-.339-.024-2.576-.028-2.863z"
      ></path>
      <path
        fill={color}
        d="M30.084 18.207c3.277 0 6.246 1.338 8.396 3.502a11.934 11.934 0 013.479 8.437c0 3.292-1.33 6.276-3.479 8.438a11.905 11.905 0 01-3.822 2.582v-2.647a9.555 9.555 0 004.913-8.372 9.555 9.555 0 00-2.78-6.76 9.427 9.427 0 00-6.707-2.792 9.427 9.427 0 00-6.709 2.793 9.555 9.555 0 00-2.778 6.758 9.555 9.555 0 004.486 8.122v2.708a11.914 11.914 0 01-3.396-2.392 11.934 11.934 0 01-3.479-8.438c0-3.292 1.33-6.275 3.479-8.437a11.804 11.804 0 018.396-3.502z"
      ></path>
    </svg>
  );
};

export default DragPointerIcon;
