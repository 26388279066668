import React from "react";
import { IconPropsType } from "./IconTypes";

const PlusIcon = ({ color = "#FFF", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.5457 20.6531C15.3258 20.7559 14.9134 20.8458 14.3086 20.9229C13.7313 20.9743 13.1677 21 12.6179 21C11.4907 21 10.6385 20.833 10.0612 20.499C9.48385 20.1393 9.19519 19.3942 9.19519 18.2637V14.0243H2.96839C2.77595 13.7417 2.611 13.3692 2.47354 12.9067C2.36358 12.4442 2.30859 11.9817 2.30859 11.5193C2.30859 10.5943 2.52853 9.9263 2.96839 9.51521C3.40825 9.07843 3.99932 8.86004 4.74158 8.86004H9.19519V2.3854C9.47011 2.30832 9.88248 2.23124 10.4323 2.15416C11.0096 2.05139 11.5732 2 12.123 2C13.2227 2 14.0612 2.17985 14.6385 2.53955C15.2433 2.89926 15.5457 3.6572 15.5457 4.81339V8.86004H21.6488C21.7863 9.16836 21.9237 9.55375 22.0612 10.0162C22.2261 10.453 22.3086 10.9155 22.3086 11.4036C22.3086 12.3029 22.0887 12.9709 21.6488 13.4077C21.2089 13.8188 20.6316 14.0243 19.9168 14.0243H15.5457V20.6531Z"
        fill={color}
      />
    </svg>
  );
};

export default PlusIcon;
