import { call, fork, put, take } from "redux-saga/effects";
import watchGetInvitesRequest from "./getInvites";
import watchGetPendingInvitesRequest from "src/redux/sagas/friends/getPendingInvites";
import { GET_FRIENDS, getFriendsActions } from "src/redux/actions/friends";
import _ from "lodash";

// services
import { getFriends } from "src/services/friends";

// types
import {
  GetFriendsRequestTypes,
  GetFriendsResponseTypes,
  GetInvitesRequestTypes
} from "src/redux/actions/friends/types";

function* watchGetFriendsRequest() {
  while (true) {
    const { payload }: IActionType<GetFriendsRequestTypes> = yield take(GET_FRIENDS.REQUEST);
    try {
      const result: GetFriendsResponseTypes = yield call(getFriends, payload);
      if (result.rows.length !== 0) {
        yield fork(makeInvitesRequests, payload);
      }
      //TODO remove mapping after fixing isFriend flag on Backend
      const newResult = _.cloneDeep(result);
      newResult.rows = newResult.rows.map((friend) => ({ ...friend, isFriend: true }));
      yield put(getFriendsActions.success(newResult));
    } catch (e) {
      yield put(getFriendsActions.error(e as any));
    }
  }
}

function* makeInvitesRequests(payload: GetInvitesRequestTypes) {
  yield call(watchGetInvitesRequest, payload);
  yield call(watchGetPendingInvitesRequest, payload);
}

export default watchGetFriendsRequest;
