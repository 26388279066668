import React, { useState, useEffect } from "react";
import { ButtonPrimary, LoaderSmall, HeadingH1 } from "src/components";

import { StyledForm, StyledInput } from "../../../AuthForm.styles";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { RootReducer } from "../../../../../redux/reducers";
import { notaSignupWithNewParentActions, notaSignupWithExistingParentActions } from "../../../../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface Props {
  onNext: () => void;
}

const ChildNameStep: React.FC<Props> = ({ onNext }) => {
  const [t] = useTranslation();

  // validation schema
  const nameSchema = Yup.object().shape({
    name: Yup.string().required(t("This field is required."))
  });

  const [formError, setFormError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const {
    nota: {
      cprValidationResult,
      parentProfileResult,
      uniloginUserResult,
      finalSignupResult,
      parentAuthResult,
      isLoading
    }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (values, { setSubmitting }) => {
    if (
      !cprValidationResult?.cpr ||
      !parentProfileResult?.email ||
      !parentProfileResult?.password ||
      !uniloginUserResult?.lToken ||
      !uniloginUserResult?.user
    ) {
      // Fallback to existing parent flow
      createUserWithExistingParent(values, { setSubmitting });
      return;
    }
    setSubmitting(true);
    dispatch(
      notaSignupWithNewParentActions.request({
        email: parentProfileResult.email,
        password: parentProfileResult.password,
        uniloginUser: uniloginUserResult.user,
        name: values.name,
        cprNumber: String(cprValidationResult.cpr),
        lToken: uniloginUserResult.lToken
      })
    );
    setSubmitting(false);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const createUserWithExistingParent = async (values, { setSubmitting }) => {
    if (
      !cprValidationResult?.cpr ||
      !parentAuthResult?.parentAuth ||
      !uniloginUserResult?.lToken ||
      !uniloginUserResult?.user
    ) {
      setFormError(t("unilogin_error_dialog_text"));
      return;
    }
    setSubmitting(true);
    dispatch(
      notaSignupWithExistingParentActions.request({
        email: parentAuthResult.parentAuth.email,
        parentAuth: parentAuthResult.parentAuth.auth,
        uniloginUser: uniloginUserResult.user,
        name: values.name,
        cprNumber: String(cprValidationResult.cpr),
        lToken: uniloginUserResult.lToken
      })
    );
    setSubmitting(false);
  };

  useEffect(() => {
    if (finalSignupResult?.authResult) {
      onNext();
    } else if (finalSignupResult?.error) {
      setFormError(finalSignupResult.error);
    }
  }, [finalSignupResult]);

  return (
    <Formik
      initialValues={{ name: uniloginUserResult?.user.name ?? "" }}
      validationSchema={nameSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting }) => {
        return (
          <StyledForm>
            <>
              {(isSubmitting || isLoading) && (
                <div className="loader-wrapper">
                  <LoaderSmall />
                </div>
              )}
              <Form onChange={() => setFormError(null)}>
                <div className="form-row-center">
                  <HeadingH1 align="center" title={t("signup_child_name_step_header")} />
                </div>
                <div className="form-row">
                  <StyledInput>
                    <label htmlFor="name" className="form-label">
                      {t("form_item_textfield_name_title")}
                    </label>
                    <Field type="name" name="name" className="form-input" />
                  </StyledInput>
                  <ErrorMessage name="name" component={StyledError} />
                  {formError && <StyledError>{formError}</StyledError>}
                </div>
                <div className="form-action">
                  <div className="buttons-wrapper">
                    <div className="login-buttons-wrapper">
                      <ButtonPrimary
                        type="submit"
                        title={t("signup_child_name_password_step_button_title")}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              </Form>
            </>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ChildNameStep;
