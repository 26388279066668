import { GET_USER_INFO } from "src/redux/actions/user";
import { GetUserInfoResponseTypes, GetLanguageListResponseTypes } from "src/redux/actions/user/types";
import { GET_LANGUAGE_LIST } from "../actions/user";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: UserState = {
  isLoading: false,
  userData: undefined,
  languageList: undefined,
  error: undefined
};

export interface UserState {
  isLoading: boolean;
  userData: GetUserInfoResponseTypes | undefined;
  languageList: GetLanguageListResponseTypes | undefined;
  error: any;
}

const user = (
  state = initialState,
  action: IActionType<GetUserInfoResponseTypes | GetLanguageListResponseTypes>
): UserState => {
  switch (action.type) {
    case GET_USER_INFO.REQUEST:
    case GET_LANGUAGE_LIST.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_USER_INFO.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userData: action.payload as GetUserInfoResponseTypes,
        error: undefined
      };
    }

    case GET_LANGUAGE_LIST.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        languageList: action.payload as GetLanguageListResponseTypes,
        error: undefined
      };
    }

    case GET_USER_INFO.ERROR:
    case GET_LANGUAGE_LIST.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default user;
