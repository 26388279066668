import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

// assets
import playImg from "src/assets/images/play.png";

// types
import { ButtonPlayPropsType } from "./ButtonPlayTypes";

// styles
import { StyledButtonPlay } from "./ButtonPlay.styles";

const ButtonPlay = ({ isCollapsed, onClick }: ButtonPlayPropsType) => {
  const [t] = useTranslation();

  return (
    <StyledButtonPlay onClick={onClick}>
      <NavLink to="/growing-path" className={`${isCollapsed ? "collapsed" : ""}`}>
        <span className="icon-wrapper">
          <img src={playImg} alt="play icon" />
        </span>
        <span className="btn-title">{t("PLAY")}</span>
      </NavLink>
    </StyledButtonPlay>
  );
};

export default ButtonPlay;
