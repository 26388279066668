import { Component } from "react";

class ErrorBoundary extends Component<any, any> {
  state = {
    error: false
  };

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.log(error, "this is error");
    console.log(errorInfo, "this is errorInfo");
    this.setState({
      error: true
    });
  }

  render() {
    if (this.state.error) {
      return <h4 style={{ color: "red" }}>Something went wrong</h4>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
