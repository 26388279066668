import React from "react";
import ReactDOM from "react-dom/client";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "src/styles/bootstrap/bootstrap-grid.css";
import "src/styles/index.css";
import App from "./App";
import * as Sentry from "@sentry/react";

Sentry.init({
  release: `maneno-react@${process.env.REACT_APP_SENTRY_VERSION}`,
  dsn: "https://c42a6cd48c5de645d09144966b6422c1@o230807.ingest.sentry.io/4505862016073728",
  integrations: [
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["localhost", `${process.env.REACT_APP_API_URL}`]
    }),
    new Sentry.Replay()
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(<App />);
