import styled from "styled-components";

export const StyledError404 = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-height: 100%;
  font-size: 140px;
  font-weight: 900;
`;
