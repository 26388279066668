import { call, put, take } from "redux-saga/effects";
import { DECLINE_FRIEND, declineFriendActions } from "src/redux/actions/friends";

// services
import { declineFriend } from "src/services/friends";

// types
import { DeclineFriendRequestTypes, DeclineFriendResponseTypes } from "src/redux/actions/friends/types";
import { updateInviteStatus } from "../../actions/users";

function* watchDeclineFriendRequest() {
  while (true) {
    const { payload }: IActionType<DeclineFriendRequestTypes> = yield take(DECLINE_FRIEND.REQUEST);
    yield put(
      updateInviteStatus.success({ id: payload.userId, hasInvitation: false, hasPendingInvite: false, isFriend: false })
    );
    try {
      const result: DeclineFriendResponseTypes = yield call(declineFriend, payload);
      if (result) yield put(declineFriendActions.success(payload));
    } catch (e) {
      yield put(declineFriendActions.error(e as any));
    }
  }
}

export default watchDeclineFriendRequest;
