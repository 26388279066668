import React from "react";
import { IconPropsType } from "./IconTypes";

const VolumeIcon = ({ color = "#F2F9FF", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.898 19.5467C15.898 20.2975 16.6765 20.8181 17.3373 20.412C20.1915 18.6578 22.097 15.61 22.097 12C22.097 8.39001 20.1915 5.34221 17.3373 3.58798C16.6765 3.18189 15.898 3.7025 15.898 4.45334C15.898 4.82864 16.1005 5.16716 16.4045 5.37376C18.5081 6.80337 19.897 9.276 19.897 12C19.897 14.724 18.5081 17.1966 16.4045 18.6262C16.1005 18.8328 15.898 19.1714 15.898 19.5467Z"
        fill={color}
        stroke={color}
        strokeWidth="0.2"
      />
      <path
        d="M6.39621 15.9996H4.00195C3.45124 15.9996 3.00195 15.5503 3.00195 14.9996V8.99959C3.00195 8.44888 3.45124 7.99959 4.00195 7.99959H6.39621C6.79102 7.99959 7.17699 7.88274 7.50551 7.66376L13.002 3.99997V19.9992L7.50551 16.3354C7.17699 16.1164 6.79102 15.9996 6.39621 15.9996Z"
        stroke={color}
        strokeWidth="2"
      />
    </svg>
  );
};

export default VolumeIcon;
