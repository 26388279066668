import { put, take } from "redux-saga/effects";
import * as Effects from "redux-saga/effects";

import { GET_PENDING_INVITES, getPendingInvitesActions } from "src/redux/actions/friends";

// services
import { getPendingInvites } from "src/services/friends";

// types
import { GetPendingInvitesRequestTypes, GetPendingInvitesResponseTypes } from "src/redux/actions/friends/types";

const call: any = Effects.call;

function* watchGetPendingInvitesRequest(params?: GetPendingInvitesRequestTypes) {
  while (true) {
    const { payload }: IActionType<GetPendingInvitesRequestTypes> = yield take(GET_PENDING_INVITES.REQUEST);

    try {
      const result: GetPendingInvitesResponseTypes = yield call(getPendingInvites, payload || params);
      yield put(getPendingInvitesActions.success(result));
    } catch (e) {
      yield put(getPendingInvitesActions.error(e as any));
    }
  }
}

export default watchGetPendingInvitesRequest;
