import {createGlobalStyle} from "styled-components";

export const GlobalStyles = createGlobalStyle`
  *,
  *::before,
  *::after {
    box-sizing: border-box;
    min-height: 0;
    min-width: 0;
  }

  html {
    background-color: #fff;
    margin: 0;
    padding: 0;
  }

  body {
    color: ${({theme}) => theme.colors.textPrimary};
    font-family: ${({theme}) => theme.fonts.fontFamilyBase};
    font-weight: ${({theme}) => theme.fonts.fontWeightMedium};
    min-height: 100vh;
    margin: 0;
    padding: 0;
  }

  p {
    font-family: ${({theme}) => theme.fonts.fontFamilyBase};
    font-size: ${({theme}) => theme.fonts.fontSizeBase};
    line-height: 150%;
  }

  img {
    height: auto;
    object-fit: contain;
    max-width: 100%;
  }

  button {
    cursor: pointer;
  }

  .loader-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    position: absolute;
    height: 100%;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9;
  }

  .Toastify__toast-body {
    color: ${({theme}) => theme.colors.textPrimary};
    font-family: ${({theme}) => theme.fonts.fontFamilySecondary};
  }

  .Toastify__close-button--light {
    min-width: 16px;
  }

  img:-webkit-any-link {
    pointer-events: none;
  }

  .hide {
    display: none !important;
  }

  .text-center {
    text-align: center;
  }
`;
