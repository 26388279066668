import React from "react";
import { IconPropsType } from "./IconTypes";

const NightIcon = ({ color = "#01324B", width = 25, height = 25 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M18.8458 16.3184L18.8457 16.3184C17.5021 16.2427 16.1962 15.853 15.0223 15.1771C13.8483 14.5011 12.8351 13.5556 12.0562 12.4082L12.0557 12.4076C10.404 10.0001 9.98855 7.02401 10.6689 4.32804C10.7866 3.86467 10.5692 3.35583 10.2379 3.0239C9.906 2.69142 9.40784 2.48896 8.94175 2.73386L8.94135 2.73407C8.47116 2.98294 8.02341 3.27536 7.5889 3.60936C3.33787 6.86856 2.34269 13.1069 5.36859 17.527C8.38423 21.9799 14.2849 22.9213 18.5456 19.6302C19.0017 19.2967 19.385 18.9324 19.7518 18.5387L19.752 18.5384C20.1091 18.1529 20.0858 17.6002 19.8925 17.1642C19.7005 16.7313 19.3106 16.3447 18.8458 16.3184ZM10.2832 13.7654L10.2841 13.7667C11.8335 16.0086 14.085 17.5936 16.6471 18.2521C13.4399 20.234 9.31717 19.3924 7.12729 16.1967C4.95036 12.9969 5.47375 8.579 8.23429 5.95478C8.03598 8.6293 8.68807 11.406 10.2832 13.7654Z"
        fill={color}
        stroke={color}
        strokeWidth="0.3"
      />
      <path
        d="M19.669 8.05034L21.3647 6.75012C21.5888 6.57825 21.4729 6.22013 21.1906 6.21217L19.051 6.1518C18.927 6.1483 18.8179 6.06884 18.7766 5.95187L18.068 3.94655C17.9738 3.67981 17.5965 3.67981 17.5023 3.94655L16.7937 5.95187C16.7524 6.06884 16.6434 6.1483 16.5193 6.1518L14.3797 6.21217C14.0974 6.22013 13.9815 6.57825 14.2056 6.75012L15.9013 8.05034C16 8.12602 16.0417 8.25473 16.0063 8.37393L15.3973 10.4218C15.3166 10.693 15.6218 10.9148 15.8549 10.7544L17.6151 9.54305C17.7175 9.47255 17.8528 9.47255 17.9552 9.54305L19.7154 10.7544C19.9485 10.9148 20.2537 10.693 20.173 10.4218L19.564 8.37393C19.5286 8.25473 19.5703 8.12602 19.669 8.05034Z"
        fill={color}
      />
    </svg>
  );
};

export default NightIcon;
