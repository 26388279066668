import styled from "styled-components";

export const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: transparent;
  border: none;
  display: inline-flex;
  color: ${({ theme }) => theme.colors.blueDark};
  font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  cursor: pointer;
  padding: 14px 24px;
  white-space: nowrap;
  width: 100%;

  &:hover {
    filter: brightness(1.2);
  }

  .icon-wrapper {
    display: flex;
  }

  .btn-title {
    margin: 4px 0 0 10px;
    overflow: hidden;
    width: auto;
    transition: width 300ms;
  }
`;
