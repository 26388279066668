import React from "react";

// types
import { ButtonBookProgressPropsType } from "./ButtonBookProgressTypes";

// styles
import { StyledButton } from "./ButtonBookProgress.styles";

const ButtonBookProgress = ({ title, imageSrc, count, className }: ButtonBookProgressPropsType) => {
  return (
    <StyledButton className={className}>
      <span className="icon-wrapper">
        <img src={imageSrc} />
        {count && <span className="count">{count}</span>}
      </span>
      <span className="btn-text">{title}</span>
    </StyledButton>
  );
};

export default ButtonBookProgress;
