import { NavigatorNetworkInformation } from "@sentry-internal/tracing/types/browser/web-vitals/types";

const useConnectionInfo = () => {
  const { connection } = navigator as NavigatorNetworkInformation;

  const connectionData = {
    connectionType: connection?.type ? connection?.type : "Unknown",
    connectionEffectiveType: connection?.effectiveType ? connection?.effectiveType : "Unknown",
    connectionDownLink: connection?.downlink ? connection?.downlink : "Unknown",
    connectionRtt: connection?.rtt ? connection?.rtt : "Unknown"
  };

  return {
    ...connectionData,
    connectionDataString: `
    Connection Type: ${connectionData.connectionType}, 
    Effective Connection Type: ${connectionData.connectionEffectiveType}, 
    Downlink Speed: ${
      connectionData.connectionDownLink !== "Unknown"
        ? connectionData.connectionDownLink + "Mb/s"
        : connectionData.connectionDownLink
    }, 
    Round Trip Time: ${
      connectionData.connectionRtt !== "Unknown" ? connectionData.connectionRtt + "ms" : connectionData.connectionRtt
    },
    `
  };
};

export default useConnectionInfo;
