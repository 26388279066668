import flagsmith from "flagsmith";
let postedUserId: number | undefined = undefined;
let postedSchoolId: number | undefined = undefined;

const flagSmithIdentification = () => {
  function identify(userId: number, schoolId?: number) {
    if (postedUserId === userId && postedSchoolId === schoolId) {
      return;
    }
    postedUserId = userId;
    postedSchoolId = schoolId;
    flagsmith.identify(userId.toString(), { schoolId: schoolId ?? null });
  }
  return { identify };
};

export default flagSmithIdentification;
