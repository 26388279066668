import http from "../http";
import { NotaCPRValidationRequest, ParentEmailAvailabilityRequest } from "src/redux/actions/nota/types";

export const validateCpr = ({ cprNumber }: NotaCPRValidationRequest) => {
  return http("api/VerifyNotaMembership", {
    method: "GET",
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`
    },
    params: { ssn: cprNumber }
  });
};

export const getEmailAvailability = ({ email }: ParentEmailAvailabilityRequest) => {
  return http("api/ValidateEmail", {
    method: "GET",
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`
    },
    params: { email: email }
  });
};

export const isUsernameTaken = (username: string) => {
  return http("api/IsUserCreated", {
    method: "GET",
    headers: {
      StaticToken: `${process.env.REACT_APP_STATIC_TOKEN}`
    },
    params: { username: username }
  });
};
