import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import i18n from "./services/translations/i18n";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import { Loader } from "src/components";
import { store } from "src/redux/store";
import AppRouter from "src/router";
import { ToastContainer } from "react-toastify";
import NoInternetConnection from "./components/NoInternetConnection/NoInternetConnection";
import flagsmith from "flagsmith";
import { FlagsmithProvider } from "flagsmith/react";

// styles
import { theme } from "src/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "src/styles/globals";
import "react-toastify/dist/ReactToastify.css";

function App() {
  const [isOnline, setIsOnline] = useState<boolean>(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => setIsOnline(true);
    const handleOffline = () => setIsOnline(false);

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <>
      {isOnline ? (
        <Provider store={store}>
          <FlagsmithProvider
            options={{
              environmentID: "TzELU4nRo7y8GejRjoiqzz"
            }}
            flagsmith={flagsmith}
          >
            <BrowserRouter>
              <I18nextProvider i18n={i18n}>
                <Suspense fallback={<Loader />}>
                  <ThemeProvider theme={theme}>
                    <GlobalStyles />
                    <AppRouter />
                    <ToastContainer newestOnTop={false} />
                  </ThemeProvider>
                </Suspense>
              </I18nextProvider>
            </BrowserRouter>
          </FlagsmithProvider>
        </Provider>
      ) : (
        <NoInternetConnection />
      )}
    </>
  );
}

export default App;
