import { requestTypeCreator } from "src/helpers/index";
import {
  GetFriendsRequestTypes,
  GetFriendsResponseTypes,
  GetInvitesRequestTypes,
  GetInvitesResponseTypes,
  GetPendingInvitesRequestTypes,
  GetPendingInvitesResponseTypes,
  InviteFriendRequestTypes,
  InviteFriendResponseTypes,
  AcceptFriendRequestTypes,
  AcceptFriendResponseTypes,
  DeclineFriendRequestTypes,
  DeclineFriendResponseTypes,
  RemoveFriendRequestTypes,
  RemoveFriendResponseTypes
} from "./types";

export const GET_FRIENDS = requestTypeCreator("GET_FRIENDS");
export const GET_INVITES = requestTypeCreator("GET_INVITES");
export const GET_PENDING_INVITES = requestTypeCreator("GET_PENDING_INVITES");
export const INVITE_FRIEND = requestTypeCreator("INVITE_FRIEND");
export const ACCEPT_FRIEND = requestTypeCreator("ACCEPT_FRIEND");
export const DECLINE_FRIEND = requestTypeCreator("DECLINE_FRIEND");
export const REMOVE_FRIEND = requestTypeCreator("REMOVE_FRIEND");

export const getFriendsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_FRIENDS.ERROR
  }),
  request: (payload?: GetFriendsRequestTypes) => {
    return {
      payload,
      type: GET_FRIENDS.REQUEST
    };
  },
  success: (payload: GetFriendsResponseTypes): IActionType<GetFriendsResponseTypes> => ({
    payload,
    type: GET_FRIENDS.SUCCESS
  })
};

export const getInvitesActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_INVITES.ERROR
  }),
  request: (payload: GetInvitesRequestTypes) => {
    return {
      payload,
      type: GET_INVITES.REQUEST
    };
  },
  success: (payload: GetInvitesResponseTypes): IActionType<GetInvitesResponseTypes> => ({
    payload,
    type: GET_INVITES.SUCCESS
  })
};

export const getPendingInvitesActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_PENDING_INVITES.ERROR
  }),
  request: (payload: GetPendingInvitesRequestTypes) => {
    return {
      payload,
      type: GET_PENDING_INVITES.REQUEST
    };
  },
  success: (payload: GetPendingInvitesResponseTypes): IActionType<GetPendingInvitesResponseTypes> => ({
    payload,
    type: GET_PENDING_INVITES.SUCCESS
  })
};

export const inviteFriendActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: INVITE_FRIEND.ERROR
  }),
  request: (payload: InviteFriendRequestTypes): IActionType<InviteFriendRequestTypes> => ({
    payload,
    type: INVITE_FRIEND.REQUEST
  }),
  success: (payload: InviteFriendResponseTypes): IActionType<InviteFriendResponseTypes> => ({
    payload,
    type: INVITE_FRIEND.SUCCESS
  })
};

export const acceptFriendActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: ACCEPT_FRIEND.ERROR
  }),
  request: (payload: AcceptFriendRequestTypes): IActionType<AcceptFriendRequestTypes> => ({
    payload,
    type: ACCEPT_FRIEND.REQUEST
  }),
  success: (payload: AcceptFriendResponseTypes): IActionType<AcceptFriendResponseTypes> => ({
    payload,
    type: ACCEPT_FRIEND.SUCCESS
  })
};

export const declineFriendActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: DECLINE_FRIEND.ERROR
  }),
  request: (payload: DeclineFriendRequestTypes): IActionType<DeclineFriendRequestTypes> => ({
    payload,
    type: DECLINE_FRIEND.REQUEST
  }),
  success: (payload: DeclineFriendResponseTypes): IActionType<DeclineFriendResponseTypes> => ({
    payload,
    type: DECLINE_FRIEND.SUCCESS
  })
};

export const removeFriendActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: REMOVE_FRIEND.ERROR
  }),
  request: (payload: RemoveFriendRequestTypes): IActionType<RemoveFriendRequestTypes> => ({
    payload,
    type: REMOVE_FRIEND.REQUEST
  }),
  success: (payload: RemoveFriendResponseTypes): IActionType<RemoveFriendResponseTypes> => ({
    payload,
    type: REMOVE_FRIEND.SUCCESS
  })
};
