import styled from "styled-components";
import { device } from "../../theme/mediaQueries";

export const StyledBugReportButton = styled.div`
  transform: translateZ(0);
  display: flex;
  justify-content: center; /* Horizontally center */
  align-items: center; /* Vertically center */

  button {
    background-color: white};
    padding: 5px 16px;

    .icon-wrapper {
      padding: 0;
    }
    
    .report-label {
      color: ${({ theme }) => theme.colors.blueLight2};
    }

    svg {
      width: 28px;
      height: 28px;

      @media ${device.tablet} {
        width: 18px;
        height: 18px;
      }

      path {
        fill: ${({ theme }) => theme.colors.blueLight2};
      }
    }
  }
`;
