import React from "react";
import { useTranslation } from "react-i18next";

// assets
import { UserIcon } from "src/assets/icons";

// styles
import { StyledHeaderProfile } from "./HeaderProfile.styles";

const HeaderProfile = ({ name, userImageUrl }: { name?: string; userImageUrl?: string }) => {
  const [t] = useTranslation();

  return (
    <StyledHeaderProfile>
      <span className="user-image">{userImageUrl ? <img src={userImageUrl} alt="user photo" /> : <UserIcon />}</span>
      <span className="user-greeting">
        {t("Hello,")} <span className="user-name">{name && name}!</span>
      </span>
    </StyledHeaderProfile>
  );
};

export default HeaderProfile;
