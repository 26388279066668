import React from "react";
import { IconPropsType } from "./IconTypes";

const LibraryIcon = ({ color = "#9AB3C2", width = 31, height = 33 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 38 44">
      <path
        fill={color}
        d="M36.414 39.962H5.585A5.59 5.59 0 010 34.377V7.797C0 3.5 3.5 0 7.798 0h27.388c1.18 0 2.145.955 2.145 2.144v27.77c0 .623-.498 1.12-1.121 1.12H5.585a3.349 3.349 0 00-3.343 3.343 3.349 3.349 0 003.343 3.343h30.83c.623 0 1.12.498 1.12 1.121 0 .624-.497 1.121-1.12 1.121zM7.798 2.242a5.566 5.566 0 00-5.556 5.556v22.106a5.555 5.555 0 013.343-1.112h29.504V2.242H7.798z"
      ></path>
      <path fill="#F2F9FF" d="M7.826 34.806v8.07l3.606-2.154 3.597 2.154v-8.07H7.826z"></path>
      <path
        fill={color}
        d="M15.029 43.998c-.195 0-.4-.049-.575-.156l-3.031-1.803-3.032 1.803c-.35.205-.78.214-1.13.01a1.118 1.118 0 01-.566-.975v-8.07c0-.624.497-1.122 1.121-1.122h7.203c.624 0 1.121.498 1.121 1.121v8.07a1.118 1.118 0 01-1.111 1.122zm-6.082-8.07v4.97l1.91-1.14c.351-.215.8-.215 1.15 0l1.91 1.14v-4.97h-4.97zM18.665 21.005c-2.485 0-4.834-.634-6.618-1.794-1.93-1.257-3.002-2.973-3.002-4.834 0-1.862 1.062-3.577 3.002-4.835 1.784-1.16 4.133-1.793 6.618-1.793 2.486 0 4.835.633 6.618 1.793 1.93 1.258 3.002 2.973 3.002 4.835 0 1.861-1.062 3.577-3.002 4.834-1.783 1.16-4.132 1.794-6.618 1.794zm0-11.005c-3.996 0-7.368 2.008-7.368 4.386 0 2.379 3.372 4.387 7.368 4.387 3.996 0 7.369-2.008 7.369-4.387 0-2.378-3.373-4.386-7.369-4.386zM34.806 39.962c-.623 0-1.12-.497-1.12-1.12v-8.939c0-.623.497-1.12 1.12-1.12.624 0 1.121.497 1.121 1.12v8.938c0 .624-.507 1.121-1.12 1.121z"
      ></path>
    </svg>
  );
};

export default LibraryIcon;
