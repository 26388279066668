import React from "react";
import { Img } from "react-image";

// types
import { MoneyCountIconPropsType } from "./MoneyCountIconTypes";

// styles
import { StyledIcon } from "./MoneyCountIcon.styles";

import moneyIcon from "src/assets/images/money.svg";

const MoneyCountIcon = ({ count }: MoneyCountIconPropsType) => {
  return (
    <StyledIcon className="money-icon">
      <Img src={moneyIcon} />
      <span className="count">{count}</span>
    </StyledIcon>
  );
};

export default MoneyCountIcon;
