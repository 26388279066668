import { take, put } from "redux-saga/effects";
import { GET_PENDING_ASSIGNMENTS_COUNT, getPendingAssignmentsCountActions } from "src/redux/actions/quiz";

// services
import { getPendingAssignmentCount } from "src/services/quiz";

// types
import { GetStudentAssignmentResponseTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchPendingAssignmentsRequest() {
  while (true) {
    yield take(GET_PENDING_ASSIGNMENTS_COUNT.REQUEST);

    try {
      const result: GetStudentAssignmentResponseTypes = yield call(getPendingAssignmentCount);

      yield put(getPendingAssignmentsCountActions.success({ pendingCount: +result || 0 }));
    } catch (e) {
      console.log(e, "error");
      yield put(getPendingAssignmentsCountActions.error(e as any));
    }
  }
}

export default watchPendingAssignmentsRequest;
