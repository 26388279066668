import { take, put } from "redux-saga/effects";
import { UPDATE_DRAGON, updateDragonActions, getDragonsListActions } from "src/redux/actions/dragon/index";

// services
import { getDragonsList, updateDragon } from "src/services/dragon";

// types
import { GetDragonsListResponseTypes, UpdateDragonRequestTypes } from "src/redux/actions/dragon/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* updateDragonRequest() {
  while (true) {
    const { payload }: IActionType<UpdateDragonRequestTypes> = yield take(UPDATE_DRAGON.REQUEST);
    const { onSuccess, userId, ...restPayload } = payload as UpdateDragonRequestTypes;

    try {
      yield call(updateDragon, restPayload);

      if (userId) {
        const result: GetDragonsListResponseTypes = yield call(getDragonsList, { userId });
        yield put(getDragonsListActions.success(result));
      }
      yield put(updateDragonActions.success());
      onSuccess();
    } catch (e) {
      yield put(updateDragonActions.error(e as any));
    }
  }
}

export default updateDragonRequest;
