import React from "react";

// types
import { ButtonSecondaryPropsType } from "./ButtonSecondaryTypes";

// styles
import { StyledButton } from "./ButtonSecondary.styles";

const ButtonSecondary = ({ title, onClick, type = "button", disabled, size = "medium" }: ButtonSecondaryPropsType) => {
  return (
    <StyledButton type={type} disabled={disabled} onClick={onClick} className={size}>
      <span className="btn-title">{title}</span>
      <span className="btn-background" />
    </StyledButton>
  );
};

export default ButtonSecondary;
