import { take, put } from "redux-saga/effects";
import { CREATE_NOTA_USER_WITH_NEW_PARENT, notaSignupWithNewParentActions } from "src/redux/actions/auth";
import { v4 as uuidv4 } from "uuid";

// services
import { login, createUserWithParent } from "src/services/user";

// types
import { LoginResponseTypes, NotaSignupNewParentParams, NotaSignupRequestTypes } from "src/redux/actions/auth/types";

// hepers
import { mapNotaSignupNewParentParamsToRequest } from "../../helpers/mapNotaSignupParamsToRequestTypes";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchCreateUniloginUserWithParentRequest() {
  while (true) {
    const { payload }: IActionType<NotaSignupNewParentParams> = yield take(CREATE_NOTA_USER_WITH_NEW_PARENT.REQUEST);

    try {
      const signupPayload: NotaSignupRequestTypes = mapNotaSignupNewParentParamsToRequest(payload);
      const signupResult: { user: any } = yield call(createUserWithParent, signupPayload);
      if (!signupResult.user) {
        throw new Error("Signup failed");
      }
      const loginResult: LoginResponseTypes = yield call(login, {
        username: signupPayload.subUser.userName,
        password: signupPayload.subUser.password,
        deviceId: uuidv4().toUpperCase()
      });
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(notaSignupWithNewParentActions.success(loginResult));
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(notaSignupWithNewParentActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchCreateUniloginUserWithParentRequest;
