import { logEvent } from "@firebase/analytics";
import { analytics } from "./firebase";

const ManenoAnalytics = {
  PAGES: {
    LOGIN: "login",
    SSO: "sso",
    FORGOT_PASWWORD: "forgot_password",
    ASSIGNMENTS: "assignments",
    BOOK_DETAILS: "book_details",
    BOOK_READING_EPUB: "book_reading_epub",
    BOOK_READING_PDF: "book_reading_pdf",
    DRAGON: "dragon",
    EQUIPMENT_SHOP: "dragon",
    GROWING_PATH: "growing_path",
    FRIENDS: "friends",
    INVITE_FRIENDS: "invite_friends",
    LEADERBOARD: "leaderboard",
    LIBRARY: "library",
    POST_READING: "post_reading",
    QUIZ: "quiz",
    NONCE_ASSIGNMENTS: "nonce_assignments",
    NONCE_READING: "nonce_reading"
  },

  ACTIONS: {
    OPEN_BOOK: "web_open_book",
    PREVIEW_BOOK: "web_preview_book",
    OPEN_ASSIGNMENT: "web_open_assignment",
    READER_FONT_SIZE_FEATURE: "web_reader_font_size_feature",
    READER_FONT_FAMILY_FEATURE: "web_reader_font_family_feature",
    READER_LINE_HEIGHT_FEATURE: "web_reader_line_height_feature",
    READER_LIGHT_MODE_FEATURE: "web_reader_light_mode_feature",
    READER_DARK_MODE_FEATURE: "web_reader_dark_mode_feature",
    READER_DYSLEXIC_MODE_FEATURE: "web_reader_dyslexic_mode_feature",
    READER_VIEW_MODE_DOUBLE_FEATURE: "web_reader_view_mode_double_feature",
    READER_VIEW_MODE_SINGLE_FEATURE: "web_reader_view_mode_single_feature",
    READER_VIEW_MODE_SCROLL_FEATURE: "web_reader_view_mode_scroll_feature",
    READER_NARRATION_TOGGLE_FEATURE: "web_reader_narration_toggle_feature",
    READER_ZOOM_PLUS_FEATURE: "web_reader_zoom_plus_feature",
    READER_ZOOM_MINUS_FEATURE: "web_reader_zoom_minus_feature",
    READER_EPUB_WORD_POPUP_SHOW: "web_reader_epub_word_popup_show",
    READER_PDF_WORD_POPUP_SHOW: "web_reader_pdf_word_popup_show_ver_2",
    DRAGON_FEEDING: "web_dragon_feeding",
    DRAGON_DRESSING: "web_dragon_dressing",
    DRAGON_BUY_FOOD: "web_dragon_buy_food",
    FRIEND_ADD: "web_friend_add",
    FRIEND_REMOVE: "web_friend_remove",
    FRIEND_INVITE: "web_friend_invite",
    FRIEND_ACCEPT: "web_friend_accept",
    FRIEND_DECLINE: "web_friend_decline",
    LIBRARY_SEARCH: "web_library_search",
    LIBRARY_BOOK_TYPE_FILTER: "web_library_book_type_filter",
    LIBRARY_BEST_FOR_FILTER: "web_library_best_for_filter",
    LIBRARY_READING_LVL_FILTER: "web_library_reading_lvl_filter",
    LIBRARY_AGE_FILTER: "web_library_age_filter",
    LIBRARY_OTHER_FILTER: "web_library_other_filter",
    LIBRARY_LENGTH_FILTER: "web_library_length_filter",
    LIBRARY_LANGUAGE_FILTER: "web_library_language_filter",
    QUIZ_START: "web_quiz_start",
    QUIZ_ANSWER_CORRECT: "web_quiz_answer_correct",
    QUIZ_ANSWER_WRONG: "web_quiz_answer_wrong",
    QUIZ_COMPLETE: "web_quiz_complete",
    SSO_OPEN_MOBILE: "web_sso_open_mobile",
    SSO_CONTINUE_IN_BROWSER: "web_sso_continue_in_browser"
  },

  logPageVisit: (pageName: string) => {
    if (pageName) {
      logEvent(analytics, "screen_view", {
        firebase_screen: `web_screen_${pageName}_visit`,
        firebase_screen_class: `web_screen_${pageName}_visit`
      });
    }
  },

  logAction: (actionKey: string, params = {}) => {
    if (actionKey) {
      logEvent(analytics, `${actionKey}`, params);
    }
  }
};

export const { logPageVisit, logAction, PAGES, ACTIONS } = ManenoAnalytics;
