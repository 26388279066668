import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledSidebar = styled.div`
  display: flex;
  justify-content: flex-end;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  position: fixed;
  pointer-events: initial;
  z-index: 99;

  @media ${device.tablet} {
    align-items: flex-end;
  }

  &.collapsed {
    pointer-events: none;

    .sidebar-overlay {
      opacity: 0;
      pointer-events: none;
    }

    .sidebar-pointer {
      opacity: 0;
      pointer-events: none;
    }

    .sidebar {
      transform: translateX(400px);

      @media ${device.tablet} {
        transform: translateX(0) translateY(100%);
      }
    }
  }

  .sidebar {
    background-color: ${({ theme }) => theme.colors.blueLight5};
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    transform: translateX(0);
    padding: 15px 0;
    transition: transform 300ms ease-in-out;
    width: 380px;

    @media ${device.tablet} {
      border-radius: 16px 16px 0 0;
      height: 476px;
      margin: 0 auto;
      position: relative;
      width: calc(100% - 60px);
      transform: translateX(0) translateY(0);
    }

    @media (max-width: 575px) {
      width: calc(100% - 30px);
    }
  }

  .sidebar-overlay {
    background: rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(8px);
    position: absolute;
    opacity: 1;
    height: 100%;
    left: 0;
    top: 0;
    transition: opacity 300ms ease-in-out;
    pointer-events: initial;
    width: 100%;
  }

  .sidebar-action {
    display: flex;
    justify-content: flex-end;
    padding: 0 25px;

    @media ${device.tablet} {
      position: absolute;
      right: 18px;
      padding: 0;
    }
  }

  .sidebar-content {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 15px 0 0;
  }

  .sidebar-pointer {
    align-items: center;
    bottom: 517px;
    color: ${({ theme }) => theme.colors.white};
    display: flex;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 14px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
    flex-direction: column;
    opacity: 1;
    transition: opacity 400ms ease-in-out;
    pointer-events: initial;
    position: absolute;
    width: 100%;

    span {
      line-height: 1.25;
      margin: 10px 0 0;
      max-width: 160px;
      text-transform: capitalize;
      text-align: center;
    }
  }

  .btn-sidebar-close {
    align-items: center;
    appearance: none;
    background-color: ${({ theme }) => theme.colors.white};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 32px;
    width: 32px;

    &:hover {
      filter: brightness(1.2);
    }
  }
`;
