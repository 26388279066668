import React from "react";
import { IconPropsType } from "./IconTypes";

const EditIcon = ({ color = "#165B95", width = 17, height = 18 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 17 18">
      <path
        fill={color}
        stroke={color}
        strokeWidth="0.2"
        d="M11.825.481h0a1.131 1.131 0 011.6 0h0l2.062 2.063h0a1.131 1.131 0 010 1.6h0l-9.28 9.28L11.824.481zm0 0L2.544 9.762l9.281-9.28zm-6.06 13.217h0L2.67 14.73s0 0 0 0h0l-.032-.094 3.126-.937zm0 0a1.13 1.13 0 00.441-.273l-.441.273zM2.27 10.205h0c.056-.167.15-.318.274-.443l-.274.443zm0 0L1.24 13.299s0 0 0 0l1.031-3.094zm1.161.411l9.194-9.194 1.921 1.922-9.194 9.193-2.881.96.96-2.88z"
      ></path>
      <path
        fill={color}
        stroke={color}
        strokeWidth="0.2"
        d="M12.705 5.842l.03.03h.083l.03-.03.728-.73.071-.071-.07-.07-2.579-2.579-.07-.07-.071.07-.73.73-.071.071.07.07 2.579 2.579zm-9.361 3.59h-.1v.615a.615.615 0 00.615.615h.416v.416a.616.616 0 00.616.616h.415v.415a.615.615 0 00.616.616h.616v-1.647a.616.616 0 00-.616-.616h-.416v-.415a.616.616 0 00-.615-.616H3.344z"
      ></path>
      <rect width="15.125" height="1.375" x="0.938" y="15.719" fill={color} rx="0.688"></rect>
    </svg>
  );
};

export default EditIcon;
