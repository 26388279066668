import React from "react";
import { NavLink } from "react-router-dom";

// types
import { SidebarLinkPropsType } from "./SidebarLinkTypes";

// styles
import { StyledLink } from "./SidebarLink.styles";

const SidebarLink = ({ title, isCollapsed, icon, url, extraClassName, badgeValue }: SidebarLinkPropsType) => {
  return (
    <StyledLink className={`${extraClassName} ${isCollapsed ? "collapsed" : ""}`}>
      <NavLink to={url} className="nav-link">
        <span className="icon-wrapper">
          {icon}
          {!!badgeValue && <span className="badge">{badgeValue}</span>}
        </span>
        <span className="link-title-wrapper">{title}</span>
      </NavLink>
    </StyledLink>
  );
};

export default SidebarLink;
