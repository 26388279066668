import React, { useEffect } from "react";

import { AuthLayout } from "src/components";
import NotaFlowContainer from "./partials/NotaFlowContainer";

// components
import { logPageVisit, PAGES } from "../../../services/analytics/ManenoAnalytics";

const Nota: React.FC = () => {
  //Analytics
  useEffect(() => {
    logPageVisit(PAGES.LOGIN);
  }, []);

  return (
    <AuthLayout>
      <NotaFlowContainer />
    </AuthLayout>
  );
};

export default Nota;
