import React from "react";
import { IconPropsType } from "./IconTypes";

const AssignmentIcon = ({ color = "#9AB3C2", width = 58, height = 59 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" viewBox="0 0 58 59">
      <g clipPath="url(#clip0_14468_161532)">
        <path
          fill={color}
          d="M50.592 34.66a.875.875 0 01-.32-1.196l5.285-9.154c.416-.72-.236-2.005-1.536-2.755a.875.875 0 11.875-1.516c2.144 1.238 3.118 3.515 2.176 5.147l-5.284 9.153a.875.875 0 01-1.196.32z"
        ></path>
        <path
          fill={color}
          d="M38.88 5.727H3.21A2.642 2.642 0 00.568 8.368v47.26a2.642 2.642 0 002.642 2.641h35.67a2.642 2.642 0 002.643-2.642V8.368a2.642 2.642 0 00-2.642-2.641z"
        ></path>
        <path
          fill={color}
          d="M4.186 55.627V8.368a2.642 2.642 0 012.642-2.641H3.21A2.642 2.642 0 00.568 8.368v47.26a2.642 2.642 0 002.642 2.641h3.618a2.642 2.642 0 01-2.642-2.642z"
        ></path>
        <path
          fill="#fff"
          d="M37.16 11.031H4.93a.861.861 0 00-.86.862V53.9c0 .476.385.862.86.862h32.23a.861.861 0 00.862-.862V11.893a.862.862 0 00-.862-.862z"
        ></path>
        <path
          fill={color}
          d="M7.578 53.9V11.893c0-.476.385-.862.86-.862H4.932a.861.861 0 00-.862.862V53.9c0 .476.386.862.862.862h3.508a.862.862 0 01-.861-.862zM23.002 4.117V1.666c0-.77-.63-1.4-1.4-1.4H20.49c-.77 0-1.4.63-1.4 1.4v2.45h3.912z"
        ></path>
        <path
          fill={color}
          d="M22.024.331a1.39 1.39 0 00-.422-.065H20.49c-.77 0-1.4.63-1.4 1.4v2.45h1.956v-2.45c0-.623.413-1.154.978-1.335z"
        ></path>
        <path
          fill={color}
          d="M25.807 14.766h-9.523a2.197 2.197 0 01-2.194-2.194V6.86a.875.875 0 111.75 0v5.712c0 .24.203.444.444.444h9.523a.45.45 0 00.444-.444V6.86a.875.875 0 111.75 0v5.712c0 1.21-.984 2.194-2.194 2.194z"
        ></path>
        <path
          fill={color}
          d="M30.296 41.625h-18.5a.875.875 0 110-1.75h18.5a.875.875 0 010 1.75zM30.296 45.211h-18.5a.875.875 0 010-1.75h18.5a.875.875 0 010 1.75zM25.671 48.797h-9.25a.875.875 0 110-1.75h9.25a.875.875 0 110 1.75z"
        ></path>
        <path
          fill={color}
          d="M31.892 3.766H10.2c-.77 0-1.4.63-1.4 1.4v1.112c0 .77.63 1.4 1.4 1.4h21.692c.77 0 1.4-.63 1.4-1.4V5.166c0-.77-.63-1.4-1.4-1.4z"
        ></path>
        <path
          fill={color}
          d="M31.892 5.72H10.2a1.406 1.406 0 01-1.334-.978 1.386 1.386 0 00-.066.423v1.111c0 .77.63 1.4 1.4 1.4h21.692c.77 0 1.4-.63 1.4-1.4V5.165c0-.148-.023-.29-.065-.423-.18.566-.712.978-1.335.978z"
        ></path>
        <path
          fill={color}
          d="M54.99 13.044a1.845 1.845 0 00-2.522.676l-1.24 2.137c.118.023.232.066.337.127l2.582 1.49c.105.061.199.138.278.228l1.24-2.137a1.845 1.845 0 00-.676-2.52z"
        ></path>
        <path
          fill={color}
          d="M53.912 14.887a1.845 1.845 0 011.88-.9 1.845 1.845 0 00-3.323-.267l-1.24 2.137c.117.023.23.066.336.127l1.281.74 1.066-1.837z"
        ></path>
        <path
          fill={color}
          d="M37.342 47.241l-3.14-1.813a.724.724 0 01-.046-.03l-1.616 5.76c-.112.4.381.684.67.388l4.18-4.28a.698.698 0 01-.048-.025z"
        ></path>
        <path
          fill={color}
          d="M37.342 47.241l-3.14-1.813a.724.724 0 01-.046-.03l-1.616 5.76c-.112.4.381.684.67.388l4.18-4.28a.698.698 0 01-.048-.025z"
        ></path>
        <path
          fill={color}
          d="M37.342 47.241l-3.14-1.813a.724.724 0 01-.046-.03l-1.616 5.76c-.112.4.381.684.67.388l.777-.795.75-2.675a.757.757 0 011.109-.451c.376.217.741.428.764.44l.78-.799a.64.64 0 01-.048-.025z"
        ></path>
        <path
          fill={color}
          d="M45.957 33.604l-4.192-2.42-.03-.02-7.802 13.44a.672.672 0 00.246.919l3.09 1.784a.672.672 0 00.919-.246l7.801-13.44c-.01-.006-.021-.01-.032-.017z"
        ></path>
        <path
          fill={color}
          d="M45.957 33.604l-4.192-2.42-.03-.02-7.802 13.44a.672.672 0 00.246.919l3.09 1.784a.672.672 0 00.919-.246l7.801-13.44c-.01-.006-.021-.01-.032-.017z"
        ></path>
        <path
          fill={color}
          d="M35.931 45.82l7.821-13.489-1.988-1.147-.03-.02-7.802 13.44a.672.672 0 00.246.919l1.714.99c-.104-.19-.098-.457.04-.694z"
        ></path>
        <path
          fill={color}
          d="M54.691 17.904a1.068 1.068 0 00-.47-.546l-2.582-1.492a1.067 1.067 0 00-.709-.133c-.97.162-1.862.74-2.392 1.659l-7.474 12.945a.993.993 0 00.363 1.357l4.193 2.42a.993.993 0 001.356-.363l7.474-12.946c.53-.918.585-1.98.241-2.9z"
        ></path>
        <path
          fill={color}
          d="M54.691 17.904a1.068 1.068 0 00-.47-.546l-2.582-1.492a1.067 1.067 0 00-.709-.133c-.97.162-1.862.74-2.392 1.659l-7.474 12.945a.993.993 0 00.363 1.357l4.193 2.42a.993.993 0 001.356-.363l7.474-12.946c.53-.918.585-1.98.241-2.9z"
        ></path>
        <path
          fill={color}
          d="M43.046 31.893l7.79-13.494c.512-.887 1.228-1.538 1.959-1.865l-1.157-.667a1.067 1.067 0 00-.708-.134c-.97.162-1.862.74-2.392 1.659l-7.474 12.945a.993.993 0 00.363 1.357l1.43.825c.007-.199.069-.418.189-.626z"
        ></path>
        <path
          fill={color}
          d="M21.046 36.668c.989 0 1.79-.798 1.79-1.783 0-.985-.801-1.783-1.79-1.783-.988 0-1.79.798-1.79 1.783 0 .985.802 1.783 1.79 1.783zM26.774 22.198c-.113-2.975-2.532-5.393-5.506-5.506a5.687 5.687 0 00-4.198 1.598 5.685 5.685 0 00-1.757 4.13c0 .989.975 1.75 2.016 1.377.575-.206.932-.768.933-1.378 0-.764.303-1.476.853-2.005a2.765 2.765 0 012.04-.776 2.792 2.792 0 012.673 2.672 2.79 2.79 0 01-2.142 2.82 2.732 2.732 0 00-2.114 2.671v1.252c0 .812.635 1.508 1.446 1.523a1.474 1.474 0 001.502-1.474v-.717c0-.248.16-.464.395-.545a5.747 5.747 0 003.859-5.642z"
        ></path>
        <path
          fill={color}
          d="M20.755 34.885a1.794 1.794 0 011.04-1.616 1.77 1.77 0 00-.749-.167c-.982 0-1.785.801-1.79 1.783a1.795 1.795 0 001.79 1.783c.268 0 .52-.061.749-.167a1.794 1.794 0 01-1.04-1.616zM16.811 22.42c0-1.573.624-3.04 1.757-4.13a5.676 5.676 0 013.226-1.553 5.687 5.687 0 00-4.724 1.553 5.684 5.684 0 00-1.757 4.13c0 .989.975 1.75 2.016 1.377.077-.027.15-.062.218-.101a1.473 1.473 0 01-.736-1.276zM21.07 29.058v-1.252c0-1.28.87-2.378 2.114-2.67a2.79 2.79 0 002.142-2.821 2.792 2.792 0 00-3.538-2.57 2.793 2.793 0 012.04 2.57 2.79 2.79 0 01-2.142 2.82 2.732 2.732 0 00-2.114 2.671v1.252c0 .812.635 1.508 1.447 1.523.286.005.554-.073.782-.21a1.542 1.542 0 01-.731-1.313z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_14468_161532">
          <path fill="#fff" d="M0 0H58V58H0z" transform="translate(0 .266)"></path>
        </clipPath>
      </defs>
    </svg>
  );
};

export default AssignmentIcon;
