import React from "react";
import { IconPropsType } from "./IconTypes";

const LockIcon = ({ color = "#38A6DE", width = 14, height = 17 }: IconPropsType) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill={color} viewBox="0 0 14 17">
      <path
        fill={color}
        d="M13.833 8.333c0-.919-.747-1.666-1.666-1.666h-.834v-2.5A4.171 4.171 0 007.167 0 4.171 4.171 0 003 4.167v2.5h-.833C1.247 6.667.5 7.414.5 8.333V15c0 .92.748 1.667 1.667 1.667h10c.919 0 1.666-.748 1.666-1.667V8.333zM4.667 4.167c0-1.379 1.121-2.5 2.5-2.5 1.378 0 2.5 1.121 2.5 2.5v2.5h-5v-2.5z"
      ></path>
    </svg>
  );
};

export default LockIcon;
