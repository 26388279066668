import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledHeaderProfile = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.fontFamilyBase};
  font-size: 14px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightSemiBold};
  display: flex;

  .user-name {
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-size: 16px;
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  }

  .user-image {
    align-items: center;
    background-color: ${({ theme }) => theme.colors.white};
    border: 2px solid #1a1467;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 37px;
    margin: 0 10px 0 0;
    min-width: 37px;
    overflow: hidden;
    width: 37px;

    @media ${device.tabletL} {
      height: 30px;
      min-width: 30px;
      width: 30px;
    }

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  .user-greeting {
    @media ${device.tabletL} {
      display: none;
    }
  }

  .mobile-nav-toggle {
    align-items: center;
    ${({ theme }) => theme.colors.blueLight2};
    border: none;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    height: 30px;
    margin: 0 0 0 20px;
    width: 30px;
  }
`;
