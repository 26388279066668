import { take, put } from "redux-saga/effects";
import { POST_GAME_FEEDBACK, postGameFeedbackActions } from "src/redux/actions/quiz";

// services
import { postGameFeedback } from "src/services/quiz";

// types
import { GameFeedbackType } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchPostGameFeedback() {
  while (true) {
    const { payload }: IActionType<GameFeedbackType> = yield take(POST_GAME_FEEDBACK.REQUEST);
    try {
      yield call(postGameFeedback, payload);
      yield put(postGameFeedbackActions.success());
    } catch (e) {
      console.error("error!!!!", e);
      yield put(postGameFeedbackActions.error(e as any));
    }
  }
}

export default watchPostGameFeedback;
