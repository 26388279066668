import http from "../http";

export const acceptAssignment = (challengeId: number) => {
  return http(`api/Challenge/${challengeId}/AcceptChallenge`, {
    method: "POST"
  });
};

export const getAssignmentDetails = ({ quizId }: { quizId: number }) => {
  return http(`api/Challenge/${quizId}/Details`, {
    method: "GET"
  });
};
