import { call, put, take } from "redux-saga/effects";
import { REMOVE_FRIEND, removeFriendActions } from "src/redux/actions/friends";

// services
import { removeFriend } from "src/services/friends";

// types
import { RemoveFriendRequestTypes, RemoveFriendResponseTypes } from "src/redux/actions/friends/types";
import { updateInviteStatus } from "../../actions/users";

function* watchRemoveFriendRequest() {
  while (true) {
    const { payload }: IActionType<RemoveFriendRequestTypes> = yield take(REMOVE_FRIEND.REQUEST);
    yield put(updateInviteStatus.success({ id: payload.userId, hasPendingInvite: false, isFriend: false }));
    try {
      const result: RemoveFriendResponseTypes = yield call(removeFriend, payload);
      if (result) {
        yield put(removeFriendActions.success(payload));
      }
    } catch (e) {
      yield put(removeFriendActions.error(e as any));
    }
  }
}

export default watchRemoveFriendRequest;
