import { take, put } from "redux-saga/effects";
import { PARENT_EMAIL_AVAILABILITY_CHECK, validateParentEmail } from "src/redux/actions/nota";

// services
import { getEmailAvailability } from "src/services/nota";

// types
import { ParentEmailAvailabilityRequest } from "src/redux/actions/nota/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchValidateParentEmail() {
  while (true) {
    const { payload }: IActionType<ParentEmailAvailabilityRequest> = yield take(
      PARENT_EMAIL_AVAILABILITY_CHECK.REQUEST
    );

    try {
      const result: boolean = yield call(getEmailAvailability, payload);
      yield put(validateParentEmail.success({ email: payload.email, isAvailible: result }));
    } catch (e) {
      yield put(validateParentEmail.error(e as any));
    }
  }
}

export default watchValidateParentEmail;
