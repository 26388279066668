import { take, put } from "redux-saga/effects";
import { GET_RELATED_BOOKS, getRelatedBooksActions } from "src/redux/actions/book";

// services
import { getRelatedBooks } from "src/services/book";

// types
import { GetRelatedBooksRequestTypes, GetRelatedBooksResponseTypes } from "../../actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetRelatedBooksRequest() {
  while (true) {
    const { payload }: IActionType<GetRelatedBooksRequestTypes> = yield take(GET_RELATED_BOOKS.REQUEST);

    try {
      const result: GetRelatedBooksResponseTypes = yield call(getRelatedBooks, payload);
      yield put(getRelatedBooksActions.success(result));
    } catch (e) {
      console.log(e, "error");
      yield put(getRelatedBooksActions.error(e as any));
    }
  }
}

export default watchGetRelatedBooksRequest;
