import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export type StyledButtonPropsType = {
  align?: "center" | "left";
  color?: string;
};

export const StyledHeading = styled.h3<StyledButtonPropsType>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: ${({ theme }) => theme.fonts.fontSizeS};
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  margin: 0;
  text-align: ${({ align }) => align};

  @media ${device.tabletL} {
    font-size: ${({ theme }) => theme.fonts.fontSizeXS};
  }
`;
