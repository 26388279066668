import { take, put } from "redux-saga/effects";
import { GET_LEADERBOARD_TOP_USERS, getLeaderboardTopUsersActions } from "src/redux/actions/leaderboard";

// services
import { getLeaderboardUsersList } from "src/services/leaderboard";

// types
import { GetLeaderboardUsersRequestTypes, GetLeaderboardUsersResponseTypes } from "../../actions/leaderboard/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchGetLeaderboardTopUsersRequest() {
  while (true) {
    const { payload }: IActionType<GetLeaderboardUsersRequestTypes> = yield take(GET_LEADERBOARD_TOP_USERS.REQUEST);
    try {
      const result: GetLeaderboardUsersResponseTypes = yield call(getLeaderboardUsersList, payload);
      yield put(getLeaderboardTopUsersActions.success({ ...result }));
    } catch (e) {
      console.log(e, "error");
      yield put(getLeaderboardTopUsersActions.error(e as any));
    }
  }
}

export default watchGetLeaderboardTopUsersRequest;
