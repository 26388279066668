import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledButton = styled.button`
  align-items: center;
  appearance: none;
  background-color: ${({ theme }) => theme.colors.blueDark};
  border: none;
  border-radius: 22px;
  color: ${({ theme }) => theme.colors.white};
  display: inline-flex;
  justify-content: center;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 19px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  padding: 5px 22px;
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.07);
  }

  @media ${device.tabletXL} {
    font-size: 16px;
  }

  .icon-wrapper {
    display: inline-flex;
    padding: 0 10px 0 0;
  }
`;
