import { requestTypeCreator } from "src/helpers/index";
import {
  NotaCPRValidationRequest,
  NotaCPRValidationResult,
  ParentEmailAvailabilityRequest,
  ParentEmailAvailabilityResult,
  UniloginUserResponse,
  UpdateParentProfileRequest,
  UpdateParentProfileResult
} from "./types";
import { UniloginDataTypes, GetParentTokenRequestTypes, GetParentTokenResponseTypes } from "../auth/types";

export const NOTA_UPDATE_STEP = "NOTA_UPDATE_STEP";
export const VALIDATE_CPR = requestTypeCreator("VALIDATE_CPR");
export const PARENT_EMAIL_AVAILABILITY_CHECK = requestTypeCreator("PARENT_EMAIL_AVAILABILITY_CHECK");
export const PARENT_PROFILE_UPDATE = requestTypeCreator("PARENT_PROFILE_UPDATE");
export const CREATE_PARENT = requestTypeCreator("CREATE_PARENT");
export const GET_UNILOGIN_USER = requestTypeCreator("GET_UNILOGIN_USER");
export const GET_PARENT_AUTH = requestTypeCreator("GET_PARENT_AUTH");

export const updateNotaFlowStep = (payload: number): IActionType<number> => {
  return {
    payload,
    type: NOTA_UPDATE_STEP
  };
};

export const parentProfileUpdate = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: PARENT_PROFILE_UPDATE.ERROR
  }),
  request: (payload?: UpdateParentProfileRequest) => {
    return {
      payload,
      type: PARENT_PROFILE_UPDATE.REQUEST
    };
  },
  success: (payload: UpdateParentProfileResult): IActionType<UpdateParentProfileResult> => ({
    payload,
    type: PARENT_PROFILE_UPDATE.SUCCESS
  })
};

export const validateCprAction = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: VALIDATE_CPR.ERROR
  }),
  request: (payload?: NotaCPRValidationRequest) => {
    return {
      payload,
      type: VALIDATE_CPR.REQUEST
    };
  },
  success: (payload: NotaCPRValidationResult): IActionType<NotaCPRValidationResult> => ({
    payload,
    type: VALIDATE_CPR.SUCCESS
  })
};

export const validateParentEmail = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: PARENT_EMAIL_AVAILABILITY_CHECK.ERROR
  }),
  request: (payload?: ParentEmailAvailabilityRequest) => {
    return {
      payload,
      type: PARENT_EMAIL_AVAILABILITY_CHECK.REQUEST
    };
  },
  success: (payload: ParentEmailAvailabilityResult): IActionType<ParentEmailAvailabilityResult> => ({
    payload,
    type: PARENT_EMAIL_AVAILABILITY_CHECK.SUCCESS
  })
};

export const createParentUser = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: CREATE_PARENT.ERROR
  }),
  request: (payload?: ParentEmailAvailabilityRequest) => {
    return {
      payload,
      type: CREATE_PARENT.REQUEST
    };
  },
  success: (payload: ParentEmailAvailabilityResult): IActionType<ParentEmailAvailabilityResult> => ({
    payload,
    type: CREATE_PARENT.SUCCESS
  })
};

export const getUniloginUserActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: GET_UNILOGIN_USER.ERROR
  }),
  request: (payload: UniloginDataTypes): IActionType<UniloginDataTypes> => ({
    payload,
    type: GET_UNILOGIN_USER.REQUEST
  }),
  success: (payload: UniloginUserResponse): IActionType<UniloginUserResponse> => ({
    payload,
    type: GET_UNILOGIN_USER.SUCCESS
  })
};

export const getParentAuthUserActions = {
  error: (payload: { error: string }): IActionType<{ error: string }> => ({
    payload,
    type: GET_PARENT_AUTH.ERROR
  }),
  request: (payload: GetParentTokenRequestTypes): IActionType<GetParentTokenRequestTypes> => ({
    payload,
    type: GET_PARENT_AUTH.REQUEST
  }),
  success: (payload: GetParentTokenResponseTypes): IActionType<GetParentTokenResponseTypes> => ({
    payload,
    type: GET_PARENT_AUTH.SUCCESS
  })
};
