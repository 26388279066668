import { requestTypeCreator } from "src/helpers/index";
import { GetDragonsListResponseTypes, GetSkinsListResponseTypes, UpdateDragonRequestTypes } from "./types";

export const GET_DRAGONS_LIST = requestTypeCreator("GET_DRAGONS_LIST");
export const GET_SKINS_LIST = requestTypeCreator("GET_SKINS_LIST");
export const UPDATE_DRAGON = requestTypeCreator("UPDATE_DRAGON");

export const getDragonsListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_DRAGONS_LIST.ERROR
  }),
  request: (payload: { userId: number }): IActionType<{ userId: number }> => ({
    payload,
    type: GET_DRAGONS_LIST.REQUEST
  }),
  success: (payload: GetDragonsListResponseTypes): IActionType<GetDragonsListResponseTypes> => ({
    payload,
    type: GET_DRAGONS_LIST.SUCCESS
  })
};

export const getSkinsListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SKINS_LIST.ERROR
  }),
  request: () => ({
    type: GET_SKINS_LIST.REQUEST
  }),
  success: (payload: GetSkinsListResponseTypes): IActionType<GetSkinsListResponseTypes> => ({
    payload,
    type: GET_SKINS_LIST.SUCCESS
  })
};

export const updateDragonActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UPDATE_DRAGON.ERROR
  }),
  request: (payload: UpdateDragonRequestTypes): IActionType<UpdateDragonRequestTypes> => ({
    payload,
    type: UPDATE_DRAGON.REQUEST
  }),
  success: () => ({
    type: UPDATE_DRAGON.SUCCESS
  })
};
