import React from "react";

// styles
import { StyledError404 } from "./Error404.styles";

const Error404 = () => {
  return <StyledError404 className="container">404</StyledError404>;
};

export default Error404;
