import React from "react";

// types
import { CheckboxPropsType } from "./CheckboxTypes";

// styles
import { StyledCheckbox } from "./Checkbox.styles";

const Checkbox = ({ title, name, onChange, checked }: CheckboxPropsType) => {
  return (
    <StyledCheckbox className="checkbox-wrapper">
      <label className="checkbox-container">
        {title}
        <input type="checkbox" name={name} onChange={onChange} checked={checked} />
        <span className="checkmark" />
      </label>
    </StyledCheckbox>
  );
};

export default Checkbox;
