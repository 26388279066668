import React, { useState, useEffect, useRef } from "react";
import { ButtonSecondary, ButtonPrimary, LoaderSmall, HeadingH1 } from "src/components";

import { StyledForm, StyledInput } from "../../../AuthForm.styles";
import { StyledModal } from "src/components/ConfirmModal/ConfirmModal.styles";
import { CrossIcon } from "src/assets/icons";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";
import { HeadingH3 } from "src/components";

import { RootReducer } from "../../../../../redux/reducers";
import { validateParentEmail, getParentAuthUserActions } from "../../../../../redux/actions/nota";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface Props {
  onNext: () => void;
  onUnilogin: () => void;
  onPasswordRecovery: (email: string) => void;
}

const ParentEmailStep: React.FC<Props> = ({ onNext, onUnilogin, onPasswordRecovery }) => {
  const [t] = useTranslation();
  const emailIsNotValid = t("email_validation_error");
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const lastEmail = useRef("");

  // validation schema
  const emailSchema = Yup.object().shape({
    email: Yup.string().email(emailIsNotValid).required(t("This field is required."))
  });
  const passwordScheme = Yup.object().shape({
    password: Yup.string().required(t("This field is required."))
  });

  const dispatch = useDispatch();
  const {
    nota: { emailAvilibilityResult, isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const [formError, setFormError] = useState<string | null>(null);
  const [modalError, setModalError] = useState<string | null>(null);

  const validateEmail = async (email: string) => {
    setFormError("");
    lastEmail.current = email;
    dispatch(validateParentEmail.request({ email: email }));
  };

  useEffect(() => {
    if (emailAvilibilityResult?.email && emailAvilibilityResult?.isAvailabile) {
      onNext();
    }
  }, [emailAvilibilityResult?.email]);

  useEffect(() => {
    if (emailAvilibilityResult && emailAvilibilityResult?.isAvailabile === false) {
      setIsPasswordVisible(true);
    }
  }, [emailAvilibilityResult?.isAvailabile]);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const email = values.email;
    await validateEmail(email);
    setSubmitting(false);
  };

  const handleToggleModal = () => {
    setIsPasswordVisible(false);
  };

  const handlePasswordRecover = () => {
    onPasswordRecovery(lastEmail.current);
  };

  const handleParentAuth = () => {
    setIsPasswordVisible(false);
    onUnilogin();
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmitPassword = async (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(
      getParentAuthUserActions.request({
        username: lastEmail.current,
        password: values.password,
        onSuccess: handleParentAuth,
        onError: setModalError
      })
    );
    setSubmitting(false);
  };

  return (
    <>
      <StyledModal className={`${isPasswordVisible ? "visible" : ""}`}>
        <div className="modal-overlay" onClick={handleToggleModal} />
        <div className="modal">
          <div className="modal-inner">
            <div className="modal-action">
              <button type="button" className="btn-modal-close" onClick={handleToggleModal}>
                <CrossIcon />
              </button>
            </div>
            <div className="modal-content">
              <HeadingH3 title={t("password_gate_title")} />
              <div className="text-center">
                <p>{t("password_gate_body", { 0: lastEmail.current })}</p>
              </div>
              {isLoading && (
                <div className="loader-wrapper">
                  <LoaderSmall />
                </div>
              )}
              <Formik
                initialValues={{}}
                validationSchema={passwordScheme}
                onSubmit={handleSubmitPassword}
                enableReinitialize
              >
                {({ isSubmitting }) => {
                  return (
                    <>
                      {(isSubmitting || isLoading) && (
                        <div className="loader-wrapper">
                          <LoaderSmall />
                        </div>
                      )}
                      <Form onChange={() => setModalError(null)}>
                        <div className="form-row">
                          <div className="input-wrapper">
                            <Field
                              type="password"
                              name="password"
                              className="form-input"
                              placeholder={t("login_parent_password_placeholder")}
                            />
                          </div>
                          <ErrorMessage name="password" component={StyledError} />
                          {modalError && <StyledError>{modalError}</StyledError>}
                          <br></br>
                        </div>
                        <div className="form-row-center">
                          {modalError && (
                            <ButtonSecondary title={t("Password recovery")} onClick={handlePasswordRecover} />
                          )}
                          <div className="form-action">
                            <ButtonPrimary
                              title={t("password_gate_button_title")}
                              size="medium"
                              type="submit"
                              disabled={isSubmitting}
                            />
                          </div>
                        </div>
                      </Form>
                    </>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </StyledModal>
      <Formik initialValues={{ email: "" }} validationSchema={emailSchema} onSubmit={handleSubmit}>
        {({ isSubmitting }) => {
          return (
            <StyledForm>
              <>
                {(isSubmitting || isLoading) && (
                  <div className="loader-wrapper">
                    <LoaderSmall />
                  </div>
                )}
                <Form onChange={() => setFormError(null)}>
                  <div className="form-row-center">
                    <HeadingH1 align="center" title={t("signup_email_nota_step_header")} />
                  </div>
                  <div className="form-row">
                    <StyledInput>
                      <label htmlFor="email" className="form-label">
                        {t("Email")}
                      </label>
                      <Field type="email" name="email" className="form-input" />
                    </StyledInput>
                    <ErrorMessage name="email" component={StyledError} />
                    {formError && <StyledError>{formError}</StyledError>}
                  </div>
                  <div className="form-action">
                    <div className="buttons-wrapper">
                      <div className="login-buttons-wrapper">
                        <ButtonPrimary
                          type="submit"
                          title={t("signup_email_nota_step_button_title")}
                          disabled={isLoading}
                        />
                      </div>
                    </div>
                  </div>
                </Form>
              </>
            </StyledForm>
          );
        }}
      </Formik>
    </>
  );
};

export default ParentEmailStep;
