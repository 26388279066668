import React, { useState, useEffect } from "react";
import { ButtonPrimary, LoaderSmall, HeadingH1 } from "src/components";
import { RootReducer } from "../../../../../redux/reducers";
import { validateCprAction } from "../../../../../redux/actions/nota";
import { useSelector, useDispatch } from "react-redux";

import { StyledForm, StyledInput } from "../../../AuthForm.styles";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { Formik, Form, Field, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

interface Props {
  onNext: () => void;
}

const CprValidationStep: React.FC<Props> = ({ onNext }) => {
  const [t] = useTranslation();
  const lenghtCprError = t("Cpr number length is not correct");

  // validation schema
  const cprSchema = Yup.object().shape({
    cpr: Yup.string().min(10, lenghtCprError).max(10, lenghtCprError).required(t("Cpr number is required"))
  });

  const dispatch = useDispatch();
  const {
    nota: { cprValidationResult, isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const [formError, setFormError] = useState<string | null>(null);

  const validateCprNumber = async (cpr: string) => {
    setFormError("");
    const cprNumber = parseInt(cpr);
    dispatch(validateCprAction.request({ cprNumber: cprNumber }));
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const cprNumber = values.cpr;
    await validateCprNumber(cprNumber);
    setSubmitting(false);
  };

  useEffect(() => {
    if (cprValidationResult?.cpr && cprValidationResult?.isValid) {
      onNext();
    }
  }, [cprValidationResult?.cpr]);

  useEffect(() => {
    if (cprValidationResult && cprValidationResult?.isValid === false) {
      setFormError(t("invalid_cpr_number_message"));
    }
  }, [cprValidationResult?.isValid]);

  return (
    <Formik initialValues={{ cpr: "" }} validationSchema={cprSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        return (
          <StyledForm>
            <>
              {(isSubmitting || isLoading) && (
                <div className="loader-wrapper">
                  <LoaderSmall />
                </div>
              )}
              <Form onChange={() => setFormError(null)}>
                <div className="form-row-center">
                  <HeadingH1 align="center" title={t("Nota Member Signup")} />
                </div>
                <div className="form-row-center">
                  <p>{t("To validate Nota membership,\\nwe should ask for the child SSN:")}</p>
                </div>
                <div className="form-row">
                  <StyledInput>
                    <label htmlFor="cprNumber" className="form-label">
                      {t("CPR-number")}
                    </label>
                    <Field type="text" name="cpr" className="form-input" />
                  </StyledInput>
                  <ErrorMessage name="cpr" component={StyledError} />
                  {formError && <StyledError>{formError}</StyledError>}
                </div>
                <div className="form-action">
                  <div className="buttons-wrapper">
                    <div className="login-buttons-wrapper">
                      <ButtonPrimary type="submit" title={t("VALIDATE")} disabled={isLoading} />
                    </div>
                  </div>
                </div>
                <div className="form-row-center">
                  <p>{t("nota_signup_info")}</p>
                </div>
              </Form>
            </>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default CprValidationStep;
