import { take, put } from "redux-saga/effects";
import { GET_NONCE_TOKEN, getNonceTokenActions } from "src/redux/actions/auth";

// services
import { getNonceToken } from "src/services/user";

// types
import { GetNonceTokenRequestTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetNonceTokenRequest() {
  while (true) {
    const { payload }: IActionType<GetNonceTokenRequestTypes> = yield take(GET_NONCE_TOKEN.REQUEST);

    try {
      const tokenResult: string = yield call(getNonceToken, payload.userToken);
      if (!tokenResult) {
        throw new Error("Invalid Token");
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      yield put(getNonceTokenActions.success());
      payload.onSuccess(tokenResult);
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      payload.onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(getNonceTokenActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchGetNonceTokenRequest;
