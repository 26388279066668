import { GET_USERS, UPDATE_INVITE_STATUS } from "src/redux/actions/users";
import { GetUsersResponseTypes } from "src/redux/actions/users/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: UsersState = {
  isLoading: false,
  users: [],
  error: undefined
};

export interface UsersState {
  isLoading: boolean;
  users: GetUsersResponseTypes;
  error: any;
}

const users = (state = initialState, action: IActionType<GetUsersResponseTypes | any>): UsersState => {
  let updatedUsers = state.users;
  switch (action.type) {
    case GET_USERS.REQUEST: {
      return {
        ...state,
        isLoading: true,
        error: undefined
      };
    }

    case GET_USERS.SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: action.payload as GetUsersResponseTypes,
        error: undefined
      };
    }

    case GET_USERS.ERROR: {
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    }

    case UPDATE_INVITE_STATUS.SUCCESS:
      updatedUsers = state.users.map((invite) =>
        invite.id === action.payload.id ? { ...invite, ...action.payload } : invite
      );
      return {
        ...state,
        users: updatedUsers
      };

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default users;
