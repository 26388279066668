import { take, put } from "redux-saga/effects";
import { RESET_PASSWORD, resetPasswordActions } from "src/redux/actions/auth";

// services
import { requestOtp } from "src/services/user";

// types
import { ResetPasswordRequestTypes } from "src/redux/actions/auth/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchRequestOtpRequest() {
  while (true) {
    const { payload }: IActionType<ResetPasswordRequestTypes> = yield take(RESET_PASSWORD.REQUEST);
    const { onError, onSuccess, ...restPayload } = payload as ResetPasswordRequestTypes;

    try {
      const result: string = yield call(requestOtp, restPayload.email);

      if (!result) {
        throw new Error("Something went wrong, try again later");
      }
      yield put(resetPasswordActions.success());
      onSuccess(restPayload.email);
    } catch (e) {
      // @ts-expect-error 'e' is of type 'unknown'
      onError(e.message || "Something went wrong, try again later");
      // @ts-expect-error 'e' is of type 'unknown'
      yield put(resetPasswordActions.error(e || { message: "Something went wrong, try again later" }));
    }
  }
}

export default watchRequestOtpRequest;
