import {
  GET_BOOK_LIST,
  GET_BOOK_ITEM,
  GET_AUTHOR_LIST,
  GET_CATEGORY_LIST,
  GET_LENGTH_LIST,
  GET_SERIES_LIST,
  GET_BOOK_URL,
  GET_SIMILAR_BOOKS,
  GET_RELATED_BOOKS,
  UPDATE_BOOK_STATISTICS,
  GET_BOOK_ADDITIONAL_DATA,
  GET_READING_REWARDS,
  RESET_READING_REWARDS,
  RESET_BOOK_STATISTICS
} from "src/redux/actions/book";
import {
  GetBookListResponseTypes,
  GetBookItemResponseTypes,
  GetCategoryListResponseTypes,
  GetDataLengthListResponseTypes,
  GetTagListResponseTypes,
  GetRelatedBooksResponseTypes,
  UpdateBookStatisticsResponseTypes,
  GetBookAdditionalDataResponseTypes,
  GetReadingRewardsResponseTypes,
  ReadingRewardsListTypes
} from "src/redux/actions/book/types";
import { AUTH_RESET_ACTION_TYPE } from "./auth";

export const initialState: BookState = {
  isLoading: false,
  bookURL: undefined,
  bookList: {
    books: [],
    totalRows: undefined
  },
  book: undefined,
  similarBooks: {
    books: [],
    totalRows: undefined
  },
  bookStatistics: undefined,
  relatedBooks: undefined,
  categoryList: undefined,
  lengthList: undefined,
  seriesList: undefined,
  authorList: undefined,
  bookAdditionalData: undefined,
  readingRewards: undefined,
  error: undefined
};

export interface BookState {
  isLoading: boolean;
  bookURL: string | undefined;
  bookList: GetBookListResponseTypes | undefined;
  book: GetBookItemResponseTypes | undefined;
  similarBooks: GetBookListResponseTypes | undefined;
  relatedBooks: GetRelatedBooksResponseTypes | undefined;
  categoryList: GetCategoryListResponseTypes | undefined;
  lengthList: GetDataLengthListResponseTypes | undefined;
  seriesList: GetTagListResponseTypes | undefined;
  authorList: GetTagListResponseTypes | undefined;
  bookStatistics: UpdateBookStatisticsResponseTypes | undefined;
  bookAdditionalData: GetBookAdditionalDataResponseTypes | undefined;
  readingRewards: ReadingRewardsListTypes | undefined;
  error: any;
}

const book = (
  state = initialState,
  action: IActionType<
    | GetBookListResponseTypes
    | GetBookItemResponseTypes
    | GetCategoryListResponseTypes
    | GetDataLengthListResponseTypes
    | GetTagListResponseTypes
    | GetRelatedBooksResponseTypes
    | UpdateBookStatisticsResponseTypes
    | GetBookAdditionalDataResponseTypes
    | GetReadingRewardsResponseTypes
    | string
  >
): BookState => {
  switch (action.type) {
    case GET_BOOK_LIST.REQUEST:
    case GET_BOOK_ITEM.REQUEST:
    case GET_SIMILAR_BOOKS.REQUEST:
    case GET_RELATED_BOOKS.REQUEST:
    case GET_CATEGORY_LIST.REQUEST:
    case GET_LENGTH_LIST.REQUEST:
    case GET_SERIES_LIST.REQUEST:
    case GET_AUTHOR_LIST.REQUEST:
    case GET_BOOK_URL.REQUEST:
    case GET_BOOK_ADDITIONAL_DATA.REQUEST:
    case GET_READING_REWARDS.REQUEST:
      return {
        ...state,
        isLoading: true,
        error: undefined
      };

    case GET_BOOK_LIST.SUCCESS:
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      return {
        ...state,
        isLoading: false,
        bookList: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          totalRows: action.payload?.totalRows,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          books: action.payload.clear ? action.payload.books : state.bookList?.books?.concat(action.payload.books)
        },
        error: undefined
      };

    case GET_BOOK_ITEM.SUCCESS:
      return {
        ...state,
        book: action.payload as GetBookItemResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_SIMILAR_BOOKS.SUCCESS:
      return {
        ...state,
        similarBooks: {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          totalRows: action.payload?.totalRows,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          books: action.payload.clear ? action.payload.books : state.similarBooks?.books?.concat(action.payload.books)
        },
        isLoading: false,
        error: undefined
      };

    case GET_RELATED_BOOKS.SUCCESS:
      return {
        ...state,
        relatedBooks: action.payload as GetRelatedBooksResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_CATEGORY_LIST.SUCCESS:
      return {
        ...state,
        categoryList: action.payload as GetCategoryListResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_LENGTH_LIST.SUCCESS:
      return {
        ...state,
        lengthList: action.payload as GetDataLengthListResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_SERIES_LIST.SUCCESS:
      return {
        ...state,
        seriesList: action.payload as GetTagListResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_AUTHOR_LIST.SUCCESS:
      return {
        ...state,
        authorList: action.payload as GetTagListResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_BOOK_URL.SUCCESS:
      return {
        ...state,
        bookURL: action.payload as string,
        isLoading: false,
        error: undefined
      };

    case UPDATE_BOOK_STATISTICS.SUCCESS:
      return {
        ...state,
        bookStatistics: action.payload as UpdateBookStatisticsResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_BOOK_ADDITIONAL_DATA.SUCCESS:
      return {
        ...state,
        bookAdditionalData: action.payload as GetBookAdditionalDataResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_READING_REWARDS.SUCCESS:
      return {
        ...state,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        readingRewards: action.payload.rewards as GetReadingRewardsResponseTypes,
        isLoading: false,
        error: undefined
      };

    case GET_BOOK_LIST.ERROR:
    case GET_BOOK_ITEM.ERROR:
    case GET_SIMILAR_BOOKS.ERROR:
    case GET_RELATED_BOOKS.ERROR:
    case GET_CATEGORY_LIST.ERROR:
    case GET_LENGTH_LIST.ERROR:
    case GET_SERIES_LIST.ERROR:
    case GET_AUTHOR_LIST.ERROR:
    case GET_BOOK_URL.ERROR:
    case UPDATE_BOOK_STATISTICS.ERROR:
    case GET_BOOK_ADDITIONAL_DATA.ERROR:
    case GET_READING_REWARDS.ERROR:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };

    case RESET_READING_REWARDS: {
      return {
        ...state,
        readingRewards: initialState.readingRewards
      };
    }
    case RESET_BOOK_STATISTICS: {
      return {
        ...state,
        bookStatistics: initialState.bookStatistics
      };
    }

    case AUTH_RESET_ACTION_TYPE: {
      return initialState;
    }

    default:
      return state;
  }
};

export default book;
