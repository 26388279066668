import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

// types
import { TimerTypes } from "./TimerTypes";

// styles
import { StyledTimer } from "./Timer.styles";

const Timer = ({ duration, remainingTime, isPlaying = true, renderTime, onComplete }: TimerTypes) => {
  const [initTime, setInitTime] = useState(0);

  useEffect(() => {
    !initTime && setInitTime(remainingTime);
  }, [remainingTime]);

  if (!initTime) {
    return <></>;
  }
  return (
    <StyledTimer>
      <CountdownCircleTimer
        isPlaying={isPlaying}
        duration={duration}
        initialRemainingTime={initTime || duration}
        colors={["#CB1E5C", "#CB1E5C"]}
        trailColor="#fff"
        size={72}
        strokeWidth={3}
        colorsTime={[initTime, 0]}
        onComplete={onComplete}
      >
        {renderTime}
      </CountdownCircleTimer>
    </StyledTimer>
  );
};

export default Timer;
