import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Img } from "react-image";

// styles
import { StyledBook } from "./BookItem.styles";

// assets
import { BookIcon, EarphonesIcon, StarIcon } from "../../assets/icons";
import thumbnailDefaultUrl from "src/assets/images/books/default-book-item-bg.jpg";

// import BookmarkIcon from "src/assets/icons/Bookmark";
import { BookItemTypes } from "./BookItemTypes";

//helpers
import { getBookStatisticProgress } from "src/helpers/library";

const BookItem = ({ item, userReadingLevel }: BookItemTypes) => {
  const {
    id,
    readingLevel,
    averageRating,
    thumbnailSmallUrl,
    thumbnailLargeUrl,
    audio,
    book,
    partNumber,
    partsInSeries
  } = item;
  const [t] = useTranslation();

  //Get reading level className
  const getReadingLvlClassName = (bookLevel: number, userLevel: number) => {
    let levelClassName;

    switch (true) {
      case bookLevel < userLevel:
        levelClassName = "easy";
        break;
      case bookLevel === userLevel:
        levelClassName = "appropriate";
        break;
      case bookLevel === userLevel + 1:
        levelClassName = "difficult";
        break;
      case bookLevel > userLevel + 1:
        levelClassName = "hard";
        break;
      default:
        levelClassName = "";
        break;
    }

    return levelClassName;
  };

  return (
    <StyledBook>
      <div className="book-wrapper">
        <div className="top-book-info">
          <div className="rating">
            <StarIcon /> {averageRating}
          </div>
          <div className="progress">
            <div className="progress-bar" style={{ width: `${getBookStatisticProgress(id)}%` }}></div>
          </div>
        </div>
        <div className="main-book-info">
          <Link className="image-wrapper" to={`/book/${id}`}>
            <svg className="book-item-clip-path">
              <clipPath id="book-item-clip-path" clipPathUnits="objectBoundingBox">
                <path d="M0,0.013 c0,-0.002,0.002,-0.004,0.005,-0.004 L0.034,0.004 h0.001 L0.046,0 l0.002,0 L0.97,0.003 a0.023,0.017,0,0,1,0.013,0.003 l0.006,0.003 a0.023,0.017,0,0,1,0.01,0.014 l0.001,0.949 c0,0.004,-0.002,0.009,-0.007,0.012 l-0.005,0.004 c-0.002,0.001,-0.004,0.002,-0.006,0.002 c-0.003,0,-0.006,0.001,-0.007,0.003 l-0.003,0.004 c-0.002,0.003,-0.006,0.004,-0.009,0.004 H0.045 c-0.003,0,-0.006,0,-0.008,-0.001 l-0.029,-0.008 A0.011,0.008,0,0,1,0,0.983 L0,0.013"></path>
              </clipPath>
            </svg>
            <svg
              width="141"
              height="189"
              viewBox="0 0 141 189"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              className="book-item-clip-path"
            >
              <clipPath id="bookListItemOverlay" transform="rotate(1 4 2.32117)">
                <path
                  opacity="0.2"
                  d="M9.21234 188.27L135.309 185.93C135.632 185.924 135.932 185.763 136.114 185.497L137.175 183.953L3.72656 186.283L8.8418 188.206C8.96016 188.251 9.0859 188.273 9.21234 188.27Z"
                  fill="none"
                />
                <path
                  opacity="0.21"
                  d="M5.72656 186.248L10.192 187.683C10.2492 187.701 10.3096 187.708 10.3694 187.702C10.7815 187.662 10.9173 187.128 10.5742 186.897L9.51594 186.182L5.72656 186.248Z"
                  fill="none"
                />
                <path
                  opacity="0.2"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M133.028 0.068999L6.44922 2.27844L9.62556 184.251C9.64355 185.282 10.4386 186.117 11.4427 186.208C11.2716 185.919 11.1709 185.583 11.1646 185.224L8.00573 4.25156C7.9961 3.69936 8.43593 3.2439 8.98813 3.23426L133.997 1.05222C134.888 1.03667 135.716 1.31379 136.39 1.79501C135.654 0.731975 134.418 0.0447453 133.028 0.068999Z"
                  fill="none"
                />
                <path
                  opacity="0.05"
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M3.17599 184.363C3.19527 185.468 4.10619 186.347 5.2106 186.328L136.191 184.042C138.399 184.003 140.159 182.181 140.12 179.973L137.049 3.99938C137.02 2.37907 136.032 1.00064 134.636 0.396359C134.871 0.893976 135.007 1.44843 135.017 2.03454L138.124 180.007C138.143 181.112 137.263 182.023 136.159 182.042L3.17935 184.363C3.17823 184.363 3.17711 184.363 3.17599 184.363Z"
                  fill="none"
                />
                <path
                  opacity="0.15"
                  d="M5.06963 6.29304C5.04444 4.84965 6.05083 3.59299 7.46485 3.30218L12.9988 2.16409L16.21 186.136L8.21124 186.276L5.06963 6.29304Z"
                  fill="none"
                />
                <path
                  d="M0.0278133 3.98672L4.01502 3.21864L7.20059 185.72L3.1879 185.028L0.0278133 3.98672Z"
                  fill="url(#paint2_linear_13738_13198)"
                />
                <path
                  d="M0.0278133 3.98672L4.01502 3.21864L7.20059 185.72L3.1879 185.028L0.0278133 3.98672Z"
                  fill="url(#paint3_linear_13738_13198)"
                />
                <rect
                  opacity="0.2"
                  x="4"
                  y="2.32117"
                  width="1"
                  height="184"
                  transform="rotate(-1 4 2.32117)"
                  fill="url(#paint1_linear_13738_13198)"
                />
                <rect
                  opacity="0.1"
                  x="134.039"
                  y="2.97473"
                  width="1.1624"
                  height="177.08"
                  transform="rotate(-1 134.039 2.97473)"
                  fill="url(#paint4_linear_13738_13198)"
                />
                <path
                  d="M0.0462062 5.03814C0.0380108 4.56863 0.357609 4.15666 0.814425 4.04789L4.76006 3.10842C4.83129 3.09146 4.90043 3.06673 4.96626 3.03467L6.32101 2.37494C6.45325 2.31054 6.59807 2.27611 6.74515 2.27409L132.734 0.549182C133.52 0.538422 134.291 0.759402 134.952 1.18453L135.225 1.35963C136.348 2.08231 137.037 3.31829 137.06 4.6541L140.133 180.663C140.148 181.548 139.808 182.402 139.189 183.035L138.585 183.652C138.364 183.879 138.062 184.009 137.745 184.015C137.34 184.022 136.965 184.232 136.748 184.575L136.241 185.374C135.974 185.795 135.514 186.054 135.016 186.063L9.51441 188.253C9.05395 188.261 8.59556 188.19 8.15945 188.042L4.5438 186.815C3.74444 186.544 3.20142 185.8 3.18668 184.956L0.0462062 5.03814Z"
                  fill="url(#paint5_linear_13738_13198)"
                  fillOpacity="0.2"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <path
                  d="M0.0462062 5.03814C0.0380108 4.56863 0.357609 4.15666 0.814425 4.04789L4.76006 3.10842C4.83129 3.09146 4.90043 3.06673 4.96626 3.03467L6.32101 2.37494C6.45325 2.31054 6.59807 2.27611 6.74515 2.27409L132.734 0.549182C133.52 0.538422 134.291 0.759402 134.952 1.18453L135.225 1.35963C136.348 2.08231 137.037 3.31829 137.06 4.6541L140.133 180.663C140.148 181.548 139.808 182.402 139.189 183.035L138.585 183.652C138.364 183.879 138.062 184.009 137.745 184.015C137.34 184.022 136.965 184.232 136.748 184.575L136.241 185.374C135.974 185.795 135.514 186.054 135.016 186.063L9.51441 188.253C9.05395 188.261 8.59556 188.19 8.15945 188.042L4.5438 186.815C3.74444 186.544 3.20142 185.8 3.18668 184.956L0.0462062 5.03814Z"
                  fill="url(#paint6_radial_13738_13198)"
                  fillOpacity="0.1"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <path
                  d="M0.0462062 5.03814C0.0380108 4.56863 0.357609 4.15666 0.814425 4.04789L4.76006 3.10842C4.83129 3.09146 4.90043 3.06673 4.96626 3.03467L6.32101 2.37494C6.45325 2.31054 6.59807 2.27611 6.74515 2.27409L132.734 0.549182C133.52 0.538422 134.291 0.759402 134.952 1.18453L135.225 1.35963C136.348 2.08231 137.037 3.31829 137.06 4.6541L140.133 180.663C140.148 181.548 139.808 182.402 139.189 183.035L138.585 183.652C138.364 183.879 138.062 184.009 137.745 184.015C137.34 184.022 136.965 184.232 136.748 184.575L136.241 185.374C135.974 185.795 135.514 186.054 135.016 186.063L9.51441 188.253C9.05395 188.261 8.59556 188.19 8.15945 188.042L4.5438 186.815C3.74444 186.544 3.20142 185.8 3.18668 184.956L0.0462062 5.03814Z"
                  fill="url(#pattern0)"
                  fillOpacity="0.3"
                  style={{ mixBlendMode: "soft-light" }}
                />
                <rect
                  opacity="0.1"
                  x="14.1992"
                  y="182.838"
                  width="122.333"
                  height="1.11373"
                  rx="0.556867"
                  transform="rotate(-1 14.1992 182.838)"
                  fill="url(#paint7_linear_13738_13198)"
                />
                <path
                  d="M9.64844 186.215L137.175 183.989L136.849 184.674L10.7969 186.874L9.64844 186.215Z"
                  fill="#9B9693"
                />
              </clipPath>
            </svg>

            <svg
              width="141"
              height="189"
              viewBox="0 0 141 189"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="book-item-pages-bottom-clip-path"
            >
              <clipPath id="pagesBottomOverlay" transform="rotate(1 4 2.32117)">
                <path
                  d="M9.64844 186.215L137.175 183.989L136.849 184.674L10.7969 186.874L9.64844 186.215Z"
                  fill="#9B9693"
                />
              </clipPath>
            </svg>
            <div className="image-inner">
              {/*<div className="book-item-overlay"></div>*/}

              {/*<div*/}
              {/*  className="book-img"*/}
              {/*  style={{*/}
              {/*    backgroundImage: `url(${thumbnailSmallUrl || thumbnailLargeUrl || thumbnailDefaultUrl})`,*/}
              {/*    clipPath: 'url("#book-item-clip-path")'*/}
              {/*  }}*/}
              {/*>*/}
              {/*  */}
              {/*</div>*/}
              <div className="book-img">
                <div className="book-item-overlay"></div>
                <div className="book-item-figure-overlay"></div>
                <div className="book-item-darkness-overlay"></div>
                <Img
                  alt=""
                  src={thumbnailSmallUrl || thumbnailLargeUrl || thumbnailDefaultUrl}
                  style={{ clipPath: 'url("#book-item-clip-path")' }}
                  loader={<div className="book-item-loader"></div>}
                  unloader={<div className="book-item-unloader"></div>}
                />
              </div>
              {/*<div className="book-item-figure-pages-bottom" style={{ clipPath: 'url("#pagesBottomOverlay")' }}></div>*/}
              {/*<img src={imageSrc} style={{ clipPath: 'url("#bookListItemOverlay")' }} />*/}
            </div>
          </Link>
          {/*<div className="book-btn-wrapper">*/}
          {/*  <div className="btn-overlay-wrapper">*/}
          {/*    <div className="btn-overlay"></div>*/}
          {/*    <button className={like ? "active" : ""}>*/}
          {/*      <HeartIcon />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*  <div className="btn-overlay-wrapper">*/}
          {/*    <div className="btn-overlay"></div>*/}
          {/*    <button className={bookmark ? "active" : ""}>*/}
          {/*      <BookmarkIcon />*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}
          {partNumber && partsInSeries > 1 ? (
            <div className="volume-overlay-wrapper">
              <div className="volume-overlay"></div>
              <div className="volume-wrapper">
                {partNumber} / {partsInSeries} {t("tag_series_title")}
                {/*{t("XOfYBookSeries").replace("%X%", String(partNumber)).replace("%Y%", String("#"))}*/}
              </div>
            </div>
          ) : null}
        </div>
        <div className={`bottom-book-info ${readingLevel ? "has-level" : ""}`}>
          {readingLevel && (
            <div className="level-wrapper">
              <span></span>
              {t("level", { 0: "" })}
              <div
                className={`level ${
                  readingLevel && userReadingLevel ? getReadingLvlClassName(readingLevel, userReadingLevel) : ""
                }`}
              >
                {readingLevel}
              </div>
            </div>
          )}
          <div className="type">
            {book && <BookIcon width={20} />}
            {audio && <EarphonesIcon height={20} />}
          </div>
        </div>
      </div>
    </StyledBook>
  );
};

export default BookItem;
