import styled from "styled-components";

export const StyledIcon = styled.span`
  position: relative;
  display: flex;
  width: fit-content;
  height: fit-content;
  min-width: fit-content;

  .btn-text {
    text-align: left;
  }
  .count {
    display: flex;
    position: absolute;
    top: -10px;
    right: -10px;
    transform: rotate(-3deg);
    align-items: center;
    text-align: center;
    justify-content: center;
    color: #d75a1a;
    border: 1px solid #751c3a;
    border-radius: 50%;
    font-size: 9px;
    min-width: 27px;
    height: 27px;
    background: ${({ theme }) => theme.bg.white};
  }
`;
