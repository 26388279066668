import styled from "styled-components";

export type StyledButtonPropsType = {
  align?: "center" | "left";
  color?: string;
};

export const StyledHeading = styled.h4<StyledButtonPropsType>`
  color: ${({ color }) => color};
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  margin: 0;
  text-align: ${({ align }) => align};
`;
