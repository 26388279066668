import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonPrimary, LoaderSmall, HeadingH1 } from "src/components";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { RootReducer } from "../../../../redux/reducers";
import { resetPasswordActions } from "../../../../redux/actions/auth";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";

// styles
import { StyledForm, StyledInput } from "../../AuthForm.styles";

interface Props {
  email?: string;
  onNext: (email: string) => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ email, onNext }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();

  const [formError, setFormError] = useState<string | null>(null);
  const {
    auth: { isLoading }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const emailIsNotValid = t("email_validation_error");
  const emailSchema = Yup.object().shape({
    email: Yup.string().email(emailIsNotValid).required(t("This field is required."))
  });

  const onError = (error: string) => {
    setFormError(error);
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const handleSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    dispatch(resetPasswordActions.request({ email: values.email, onSuccess: onNext, onError: onError }));
    setSubmitting(false);
  };

  return (
    <Formik initialValues={{ email: email ?? "" }} validationSchema={emailSchema} onSubmit={handleSubmit}>
      {({ isSubmitting }) => {
        return (
          <StyledForm>
            <>
              {(isSubmitting || isLoading) && (
                <div className="loader-wrapper">
                  <LoaderSmall />
                </div>
              )}
              <Form onChange={() => setFormError(null)}>
                <div className="form-row-center">
                  <HeadingH1 align="center" title={t("Password Recovery")} />
                </div>
                <div className="form-row">
                  <StyledInput>
                    <label htmlFor="email" className="form-label">
                      {t("Email")}
                    </label>
                    <Field type="email" name="email" className="form-input" />
                  </StyledInput>
                  <ErrorMessage name="email" component={StyledError} />
                  {formError && <StyledError>{formError}</StyledError>}
                </div>
                <div className="form-action">
                  <div className="buttons-wrapper">
                    <div className="login-buttons-wrapper">
                      <ButtonPrimary type="submit" title={t("Submit")} disabled={isLoading} />
                    </div>
                  </div>
                </div>
              </Form>
            </>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ForgotPasswordForm;
