import { persistor } from "../../redux/store";

const { localStorage } = window;

export const setToken = (token: string): void => localStorage.setItem("access_token", token);
export const getToken = (): string | null => localStorage.getItem("access_token");

export const setRefreshToken = (token: string): void => localStorage.setItem("refresh_token", token);
export const getRefreshToken = (): string | null => localStorage.getItem("refresh_token");

export const setTokenExpDate = (date: number): void => localStorage.setItem("token_exp_date", String(date));
export const getTokenExpDate = (): string | null => localStorage.getItem("token_exp_date");

export const signOutUser = (): void => {
  localStorage.clear();

  setTimeout(() => {
    persistor.purge();
  }, 500);
};

export const setActiveDragonId = (id: number | undefined): void => localStorage.setItem("active_dragon_id", String(id));

export const getActiveDragonId = () => localStorage.getItem("active_dragon_id");
