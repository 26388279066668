import styled from "styled-components";

export const StyledLogo = styled.span`
  display: flex;
  justify-content: center;
  overflow: hidden;
  width: 164px;
  transition: width 300ms ease-in-out;

  &.collapsed {
    width: 31px;
  }
`;
