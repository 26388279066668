import http from "../http";

export const getDragonsList = ({ userId }: { userId: number }) => {
  return http("api/users/dragons", {
    params: { userId, refresh: true },
    method: "GET"
  });
};

export const updateDragon = ({ name, id }: { name: string; id: number }) => {
  return http(`api/dragon/${id}/update`, {
    method: "POST",
    body: JSON.stringify({ name })
  });
};

export const getSkinsList = () => {
  return http("api/dragon/skins", {
    method: "GET"
  });
};
