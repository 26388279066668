import { take, put } from "redux-saga/effects";
import { SAVE_BOOK_QUIZ, saveBookQuizActions } from "src/redux/actions/quiz";

// services
import { getQuizResultStatistic, saveBookQuiz } from "src/services/quiz";

// types
import { SaveBookQuizRequestTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchSaveBookQuizRequest() {
  while (true) {
    const { payload }: IActionType<SaveBookQuizRequestTypes> = yield take(SAVE_BOOK_QUIZ.REQUEST);
    const { quizId, userId, action, ...restPayload } = payload as SaveBookQuizRequestTypes;

    try {
      if (quizId && userId) {
        yield call(saveBookQuiz, { ...restPayload, quizId, userId });
        const result = getQuizResultStatistic(payload);
        yield put(saveBookQuizActions.success(result));
        action("success");
      } else {
        action("error");
      }
    } catch (e) {
      console.log("error!!!!", e);
      yield put(saveBookQuizActions.error(e as any));
      // action("error");
    }
  }
}

export default watchSaveBookQuizRequest;
