export default {
  colors: {
    textPrimary: "#01324B",
    blue: "#32A3DD",
    blueLight: "#38A6DE",
    blueLight1: "#E2F3FF",
    blueLight2: "#AED5F1",
    blueLight3: "#F2F9FF",
    blueLight4: "#E2F3FF",
    blueLight5: "#F5FAFF",
    blueLight6: "#C2E0EF",
    blueLight7: "#B5DEFB",
    blueLight8: "#DFEFFB",
    blueDark: "#165B95",
    white: "#FFFFFF",
    orange: "#D75A1A",
    pink: "#EECDDC",
    red: "#9c0035",
    red2: "#751C3A",
    red3: "#CB1E5C",
    greyMuted: "#9AB3C2",
    grey: "#ece9e9",
    violetDark: "#1E0D6C",
    green: "#4DB984"
  },
  bg: {
    white: "#FFFFFF",
    blueLight: "#38A6DE",
    blue: "#32A3DD",
    blueLight3: "#F2F9FF",
    gradientLightBlueHorizontal:
      "radial-gradient(54.74% 46.19% at 47.9% 16%, #FFFFFF 0%, #000000 100%), linear-gradient(180deg, #FAFCFF 0%, #FAFCFF 65%, #D9EBFE 125.61%, #D9ECFF 125.62%), #E8F3FE",
    gradientBlue: "linear-gradient(358.51deg, #32A3DD 17.07%, #32A3DD 58.94%, #1180BF 97.37%)",
    gradientLightBlue:
      "linear-gradient(180deg, rgba(234, 244, 255, 0) 0%, rgba(234, 244, 255, 0.8125) 99.99%, rgba(234, 244, 255, 0) 100%)",
    buttonBlueLight1: "#E2F3FF",
    buttonBlueLight2: "#AED5F1",
    buttonBlueDark: "#165B95",
    borderColor: "#AED5F1"
  },
  components: {
    buttonPrimary: {}
  },
  fonts: {
    fontFamilyBase: "Montserrat, sans-serif",
    fontFamilySecondary: "Baloo, sans-serif",
    fontSizeSmall: "14px",
    fontSizeBase: "17px",
    fontWeightRegular: "400",
    fontWeightMedium: "500",
    fontWeightSemiBold: "600",
    fontWeightBold: "bold",
    fontSizeXXL: "64px",
    fontSizeXL: "42px",
    fontSizeM: "33px",
    fontSizeS: "28px",
    fontSizeXS: "24px",
    fontSizeXXS: "22px",
    fontSizeXXXS: "20px"
  },
  indent: {
    indentBase: "20px",
    indentXS: "5px",
    indentS: "10px",
    indentM: "30px",
    indentL: "40px"
  }
};
