import React from "react";
import { IconPropsType } from "./IconTypes";

const BookIcon = ({ color = "#165B95", width = 24, height = 24 }: IconPropsType) => {
  return (
    <svg width={width} height={height} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.91418 6.20218C7.11756 6.0078 7.40281 5.89102 7.70922 5.89102H16.6414V14.708H7.70922C7.32602 14.708 6.94914 14.7953 6.60863 14.9612V6.89663C6.60863 6.64598 6.71238 6.39505 6.91418 6.20218ZM6.60863 17.104C6.60881 17.3545 6.71256 17.6051 6.91418 17.7978C7.11756 17.9922 7.40281 18.109 7.70922 18.109H16.6414V16.099H7.70922C7.40281 16.099 7.11757 16.2158 6.91418 16.4102C6.71256 16.6029 6.60881 16.8536 6.60863 17.104ZM18 15.4035V18.8045C18 19.1886 17.6959 19.5 17.3207 19.5H7.70922C7.0715 19.5 6.4513 19.2583 5.98709 18.8147C5.52129 18.3695 5.25 17.755 5.25 17.1034V6.89663C5.25 6.24495 5.52129 5.63052 5.98709 5.18534C6.4513 4.74167 7.0715 4.5 7.70922 4.5H17.3207C17.6959 4.5 18 4.81139 18 5.19551V15.4035Z"
        fill={color}
      />
    </svg>
  );
};

export default BookIcon;
