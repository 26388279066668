import styled from "styled-components";
import { device } from "src/theme/mediaQueries";

export const StyledTimer = styled.div`
  @media ${device.tabletXL} {
    border-radius: 50%;
    background: #e2f3ff;
    min-width: max-content;
    height: max-content;
  }

  .timer {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
    font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  }

  .text {
    font-size: 16px;
    line-height: 20px;
  }

  .value {
    font-size: ${({ theme }) => theme.fonts.fontSizeXXS};
    line-height: 25px;
  }
`;
