import { requestTypeCreator } from "src/helpers/index";
import {
  GetBookListRequestTypes,
  GetBookListResponseTypes,
  GetBookItemResponseTypes,
  GetCategoryListResponseTypes,
  GetDataLengthListResponseTypes,
  GetTagListRequestTypes,
  GetTagListResponseTypes,
  GetRelatedBooksRequestTypes,
  GetRelatedBooksResponseTypes,
  GetBookURLRequestTypes,
  UpdateBookStatisticsRequestTypes,
  GetBookAdditionalDataResponseTypes,
  GetBookAdditionalDataRequestTypes,
  GetReadingRewardsRequestTypes,
  GetReadingRewardsResponseTypes
} from "./types";

export const GET_BOOK_LIST = requestTypeCreator("GET_BOOK_LIST");
export const GET_BOOK_ITEM = requestTypeCreator("GET_BOOK_ITEM");
export const GET_SIMILAR_BOOKS = requestTypeCreator("GET_SIMILAR_BOOKS");
export const GET_RELATED_BOOKS = requestTypeCreator("GET_RELATED_BOOKS");
export const GET_CATEGORY_LIST = requestTypeCreator("GET_CATEGORY_LIST");
export const GET_LENGTH_LIST = requestTypeCreator("GET_LENGTH_LIST");
export const GET_SERIES_LIST = requestTypeCreator("GET_SERIES_LIST");
export const GET_AUTHOR_LIST = requestTypeCreator("GET_AUTHOR_LIST");
export const GET_BOOK_URL = requestTypeCreator("GET_BOOK_URL");
export const UPDATE_BOOK_STATISTICS = requestTypeCreator("UPDATE_BOOK_STATISTICS");
export const GET_BOOK_ADDITIONAL_DATA = requestTypeCreator("GET_BOOK_ADDITIONAL_DATA");
export const GET_READING_REWARDS = requestTypeCreator("GET_READING_REWARDS");
export const RESET_READING_REWARDS = "RESET_READING_REWARDS";
export const RESET_BOOK_STATISTICS = "RESET_BOOK_STATISTICS";

export const getBookListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetBookListRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_LIST.REQUEST
    };
  },
  success: (payload: GetBookListResponseTypes): IActionType<GetBookListResponseTypes> => ({
    payload,
    type: GET_BOOK_LIST.SUCCESS
  })
};

export const getBookItemActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_ITEM.ERROR
  }),
  request: (payload: any): IActionType<GetBookItemResponseTypes> => {
    return {
      payload,
      type: GET_BOOK_ITEM.REQUEST
    };
  },
  success: (payload: GetBookItemResponseTypes | undefined): IActionType<GetBookItemResponseTypes | undefined> => ({
    payload,
    type: GET_BOOK_ITEM.SUCCESS
  })
};

export const getSimilarBooksActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SIMILAR_BOOKS.ERROR
  }),
  request: (payload: any): IActionType<GetBookListRequestTypes> => {
    return {
      payload,
      type: GET_SIMILAR_BOOKS.REQUEST
    };
  },
  success: (payload: GetBookListResponseTypes): IActionType<GetBookListResponseTypes> => ({
    payload,
    type: GET_SIMILAR_BOOKS.SUCCESS
  })
};

export const getRelatedBooksActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_RELATED_BOOKS.ERROR
  }),
  request: (payload: any): IActionType<GetRelatedBooksRequestTypes> => {
    return {
      payload,
      type: GET_RELATED_BOOKS.REQUEST
    };
  },
  success: (payload: GetRelatedBooksResponseTypes): IActionType<GetRelatedBooksResponseTypes> => ({
    payload,
    type: GET_RELATED_BOOKS.SUCCESS
  })
};

export const getCategoryListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_CATEGORY_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_CATEGORY_LIST.REQUEST
    };
  },
  success: (payload: GetCategoryListResponseTypes): IActionType<GetCategoryListResponseTypes> => ({
    payload,
    type: GET_CATEGORY_LIST.SUCCESS
  })
};

export const getLengthListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_LENGTH_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_LENGTH_LIST.REQUEST
    };
  },
  success: (payload: GetDataLengthListResponseTypes): IActionType<GetDataLengthListResponseTypes> => ({
    payload,
    type: GET_LENGTH_LIST.SUCCESS
  })
};

export const getSeriesListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_SERIES_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_SERIES_LIST.REQUEST
    };
  },
  success: (payload: GetTagListResponseTypes): IActionType<GetTagListResponseTypes> => ({
    payload,
    type: GET_SERIES_LIST.SUCCESS
  })
};

export const getAuthorListActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_AUTHOR_LIST.ERROR
  }),
  request: (payload: any): IActionType<GetTagListRequestTypes> => {
    return {
      payload,
      type: GET_AUTHOR_LIST.REQUEST
    };
  },
  success: (payload: GetTagListResponseTypes): IActionType<GetTagListResponseTypes> => ({
    payload,
    type: GET_AUTHOR_LIST.SUCCESS
  })
};

export const getBookURLActions = {
  error: (payload: string): IActionType<string> => ({
    payload,
    type: GET_BOOK_URL.ERROR
  }),
  request: (payload: any): IActionType<GetBookURLRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_URL.REQUEST
    };
  },
  success: (payload: string): IActionType<string> => ({
    payload,
    type: GET_BOOK_URL.SUCCESS
  })
};

export const updateBookStatisticsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: UPDATE_BOOK_STATISTICS.ERROR
  }),
  request: (payload: any): IActionType<UpdateBookStatisticsRequestTypes> => {
    return {
      payload,
      type: UPDATE_BOOK_STATISTICS.REQUEST
    };
  },
  success: (payload: string): IActionType<string> => ({
    payload,
    type: UPDATE_BOOK_STATISTICS.SUCCESS
  })
};

export const getBookAdditionalDataActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_BOOK_ADDITIONAL_DATA.ERROR
  }),
  request: (payload: any): IActionType<GetBookAdditionalDataRequestTypes> => {
    return {
      payload,
      type: GET_BOOK_ADDITIONAL_DATA.REQUEST
    };
  },
  success: (payload: GetBookAdditionalDataResponseTypes): IActionType<GetBookAdditionalDataResponseTypes> => ({
    payload,
    type: GET_BOOK_ADDITIONAL_DATA.SUCCESS
  })
};

export const getReadingRewardsActions = {
  error: (payload: { error: any }): IActionType<{ error: any }> => ({
    payload,
    type: GET_READING_REWARDS.ERROR
  }),
  request: (payload: any): IActionType<GetReadingRewardsRequestTypes> => {
    return {
      payload,
      type: GET_READING_REWARDS.REQUEST
    };
  },
  success: (payload: GetReadingRewardsResponseTypes): IActionType<GetReadingRewardsResponseTypes> => ({
    payload,
    type: GET_READING_REWARDS.SUCCESS
  })
};

export const resetReadingRewardsAction = {
  type: RESET_READING_REWARDS
};

export const resetBookStatisticsAction = {
  type: RESET_BOOK_STATISTICS
};
