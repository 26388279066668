import {
  NOTA_UPDATE_STEP,
  VALIDATE_CPR,
  PARENT_EMAIL_AVAILABILITY_CHECK,
  PARENT_PROFILE_UPDATE,
  GET_UNILOGIN_USER,
  GET_PARENT_AUTH
} from "../actions/nota";
import { CREATE_NOTA_USER_WITH_EXISTING_PARENT, CREATE_NOTA_USER_WITH_NEW_PARENT } from "../actions/auth";
import {
  NotaCPRValidationRequest,
  NotaCPRValidationResult,
  ParentEmailAvailabilityRequest,
  ParentEmailAvailabilityResult,
  UniloginUserResponse,
  CprValidationStepResult,
  ParentAuthStepResult,
  EmailAvailabilityCheckStepResult,
  ParentProfileStepResult,
  UniloginSignupStepResult,
  FinalSignupStepResult,
  UpdateParentProfileResult
} from "../actions/nota/types";
import {
  UniloginDataTypes,
  LoginResponseTypes,
  NotaSignupNewParentParams,
  GetParentTokenRequestTypes,
  GetParentTokenResponseTypes
} from "src/redux/actions/auth/types";

export const initialState: NotaState = {
  isLoading: false,
  cprValidationResult: undefined,
  emailAvilibilityResult: undefined,
  parentProfileResult: undefined,
  parentAuthResult: undefined,
  uniloginUserResult: undefined,
  finalSignupResult: undefined,
  currentStepIdx: 0
};

export interface NotaState {
  isLoading: boolean;
  cprValidationResult?: CprValidationStepResult;
  emailAvilibilityResult?: EmailAvailabilityCheckStepResult;
  parentProfileResult?: ParentProfileStepResult;
  parentAuthResult?: ParentAuthStepResult;
  uniloginUserResult?: UniloginSignupStepResult;
  finalSignupResult?: FinalSignupStepResult;
  currentStepIdx: number;
}

const nota = (
  state = initialState,
  action: IActionType<
    | number
    | string
    | NotaCPRValidationRequest
    | NotaCPRValidationResult
    | ParentEmailAvailabilityRequest
    | ParentEmailAvailabilityResult
    | UniloginUserResponse
    | UniloginDataTypes
    | NotaSignupNewParentParams
    | LoginResponseTypes
    | UpdateParentProfileResult
    | GetParentTokenRequestTypes
    | Error
  >
): NotaState => {
  switch (action.type) {
    case NOTA_UPDATE_STEP:
      return {
        ...state,
        currentStepIdx: action.payload as number
      };
    case VALIDATE_CPR.REQUEST:
      return {
        ...state,
        cprValidationResult: undefined,
        isLoading: true
      };
    case VALIDATE_CPR.SUCCESS:
      return {
        ...state,
        cprValidationResult: { cpr: (action.payload as NotaCPRValidationResult).cprNumber, isValid: true },
        isLoading: false
      };
    case VALIDATE_CPR.ERROR:
      return {
        ...state,
        cprValidationResult: { cpr: undefined, isValid: false },
        isLoading: false
      };
    case PARENT_EMAIL_AVAILABILITY_CHECK.REQUEST:
      return {
        ...state,
        emailAvilibilityResult: undefined,
        isLoading: true
      };
    case PARENT_EMAIL_AVAILABILITY_CHECK.SUCCESS:
      return {
        ...state,
        emailAvilibilityResult: {
          email: (action.payload as ParentEmailAvailabilityResult).email,
          isAvailabile: (action.payload as ParentEmailAvailabilityResult).isAvailible
        },
        isLoading: false
      };
    case PARENT_EMAIL_AVAILABILITY_CHECK.ERROR:
      return {
        ...state,
        emailAvilibilityResult: undefined,
        isLoading: false
      };
    case PARENT_PROFILE_UPDATE.REQUEST:
      return {
        ...state,
        parentProfileResult: undefined,
        isLoading: true
      };
    case PARENT_PROFILE_UPDATE.SUCCESS:
      return {
        ...state,
        parentProfileResult: {
          email: (action.payload as UpdateParentProfileResult).email,
          password: (action.payload as UpdateParentProfileResult).password,
          isAvailabile: (action.payload as UpdateParentProfileResult).isAvailible
        },
        isLoading: false
      };
    case PARENT_PROFILE_UPDATE.ERROR:
      return {
        ...state,
        parentProfileResult: undefined,
        isLoading: false
      };
    case GET_UNILOGIN_USER.REQUEST:
      return {
        ...state,
        uniloginUserResult: undefined,
        isLoading: true
      };
    case GET_UNILOGIN_USER.SUCCESS:
      return {
        ...state,
        uniloginUserResult: {
          lToken: (action.payload as UniloginUserResponse).lToken,
          user: (action.payload as UniloginUserResponse).user,
          error: undefined
        },
        isLoading: false
      };
    case GET_UNILOGIN_USER.ERROR:
      return {
        ...state,
        uniloginUserResult: {
          lToken: undefined,
          user: undefined,
          error: ((action.payload as Error).message as string) ?? "Something went wrong, try again later"
        },
        isLoading: false
      };
    case CREATE_NOTA_USER_WITH_NEW_PARENT.REQUEST:
      return {
        ...state,
        finalSignupResult: undefined,
        isLoading: true
      };
    case CREATE_NOTA_USER_WITH_NEW_PARENT.SUCCESS:
      return {
        ...state,
        finalSignupResult: { authResult: action.payload as LoginResponseTypes },
        isLoading: false
      };
    case CREATE_NOTA_USER_WITH_NEW_PARENT.ERROR:
      return {
        ...state,
        finalSignupResult: {
          authResult: undefined,
          error: ((action.payload as Error).message as string) ?? "Something went wrong, try again later"
        },
        isLoading: false
      };
    case CREATE_NOTA_USER_WITH_EXISTING_PARENT.REQUEST:
      return {
        ...state,
        finalSignupResult: undefined,
        isLoading: true
      };
    case CREATE_NOTA_USER_WITH_EXISTING_PARENT.SUCCESS:
      return {
        ...state,
        finalSignupResult: { authResult: action.payload as LoginResponseTypes },
        isLoading: false
      };
    case CREATE_NOTA_USER_WITH_EXISTING_PARENT.ERROR:
      return {
        ...state,
        finalSignupResult: {
          authResult: undefined,
          error: ((action.payload as Error).message as string) ?? "Something went wrong, try again later"
        },
        isLoading: false
      };
    case GET_PARENT_AUTH.REQUEST:
      return {
        ...state,
        parentAuthResult: undefined,
        isLoading: true
      };
    case GET_PARENT_AUTH.SUCCESS:
      return {
        ...state,
        parentAuthResult: {
          parentAuth: action.payload as GetParentTokenResponseTypes,
          error: undefined
        },
        isLoading: false
      };
    case GET_PARENT_AUTH.ERROR:
      return {
        ...state,
        parentAuthResult: {
          parentAuth: undefined,
          error: ((action.payload as Error).message as string) ?? "Something went wrong, try again later"
        },
        isLoading: false
      };
    default:
      return state;
  }
};

export default nota;
