import { take, put } from "redux-saga/effects";
import {
  GET_NEW_OVERDUE_ASSIGNMENTS,
  getPendingAssignmentsCountActions,
  setNewAssignmentsAction,
  setOverdueAssignmentsAction
} from "src/redux/actions/quiz";

// services
import { getStudentAssignments } from "src/services/quiz";

// types
import { AssignmentItemType, GetStudentAssignmentResponseTypes } from "src/redux/actions/quiz/types";

import * as Effects from "redux-saga/effects";
import { BookTypes, GetBookListResponseTypes } from "../../actions/book/types";
import { getBookList } from "../../../services/book";

const call: any = Effects.call;

function* watchNewOverdueAssignmentsRequest() {
  function* getNewAndOverdueAssignments(data: GetStudentAssignmentResponseTypes) {
    const acceptedAssignments = data.filter((assignment) => assignment?.accepted);

    const currentDate = new Date();
    const dateString = currentDate.toISOString();
    const lastViewDateString = localStorage.getItem("lastAssignmentsViewDate");
    let lastViewDate: Date;

    if (!lastViewDateString) {
      localStorage.setItem("lastAssignmentsViewDate", dateString);
    }

    if (lastViewDateString) {
      lastViewDate = new Date(lastViewDateString);
    } else {
      lastViewDate = currentDate;
    }

    if (acceptedAssignments?.length) {
      //split new / overdue assignments
      const newAssignments = acceptedAssignments.filter((assignment: AssignmentItemType) => {
        const publishDate = new Date(assignment.publishDate);
        return publishDate && publishDate > lastViewDate && assignment.bookId;
      });

      const overdueAssignments = acceptedAssignments.filter((assignment: AssignmentItemType) => {
        const dueDate = new Date(assignment.dueDate);
        return dueDate && lastViewDate > dueDate && assignment.bookId;
      });

      //fetch books data
      const bookIds: number[] = [...overdueAssignments, ...newAssignments]
        .map((item) => item.bookId)
        .filter((value) => value !== undefined);

      const { books: booksData }: GetBookListResponseTypes = yield call(getBookList, {
        limit: bookIds.length,
        offset: 0,
        ids: bookIds
      });

      //Assignments with books
      const getAssignmentBookData = (booksData: BookTypes[], assignment: AssignmentItemType) => {
        return booksData.find((book) => book.id == assignment.bookId);
      };

      const newAssignmentsWithBooks = newAssignments.map((assignment) => {
        const bookData = getAssignmentBookData(booksData, assignment);
        return {
          ...assignment,
          bookData
        };
      });

      const overdueAssignmentsWithBooks = overdueAssignments.map((assignment) => {
        const bookData = getAssignmentBookData(booksData, assignment);
        return {
          ...assignment,
          bookData
        };
      });

      //Set to store
      if (newAssignmentsWithBooks?.length) {
        yield put(setNewAssignmentsAction(newAssignmentsWithBooks));
      }

      if (overdueAssignmentsWithBooks?.length) {
        yield put(setOverdueAssignmentsAction(overdueAssignmentsWithBooks));
      }
    }
  }

  while (true) {
    const { payload } = yield take(GET_NEW_OVERDUE_ASSIGNMENTS);

    try {
      const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, payload);

      yield* getNewAndOverdueAssignments(result);
    } catch (e) {
      console.log(e, "error");
      yield put(getPendingAssignmentsCountActions.error(e as any));
    }
  }
}

export default watchNewOverdueAssignmentsRequest;
