import styled from "styled-components";

export const StyledLabel = styled.div`
  background: ${({ theme }) => theme.bg.buttonBlueLight1};
  border: none;
  border-radius: 16px;
  color: ${({ theme }) => theme.colors.textPrimary};
  display: flex;
  font-family: ${({ theme }) => theme.fonts.fontFamilySecondary};
  font-size: 16px;
  font-weight: ${({ theme }) => theme.fonts.fontWeightRegular};
  padding: 13px 24px;
  width: 201px;
  margin-bottom: 11px;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  .btn-text-wrapper {
    display: flex;
    align-items: center;
  }

  .btn-text {
    text-align: left;
  }

  .success-icon {
    margin-right: 12px;
  }
`;
