import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

// assets
import dragonImg from "src/assets/images/dragon-reading.png";

// utils
import { RootReducer } from "src/redux/reducers";

// helpers
import { useScreenWidth } from "src/helpers";

// types
import { DragonTimerPropsType } from "./DragonTimerTypes";

// styles
import { StyledTimer } from "./DragonTimer.styles";

const DragonTimer = ({ isCollapsed }: DragonTimerPropsType) => {
  const [screenWidth] = useScreenWidth();
  const [timeTracker, setTimeTracker] = useState<number>(0);
  const [currentAudioIndex, setCurrentAudioIndex] = useState(0);
  const [audioFiles, setAudioFiles] = useState<string[]>([]);

  const {
    user: { userData }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  const combineAudioTracks = (todayReadingTime: number) => {
    setCurrentAudioIndex(0);

    const youShouldRead = "/audio/I dag skal du læse_en.m4a",
      youMustRead = "/audio/Du skal læse_en.m4a",
      youHaveRead = "/audio/Du har læst_en.m4a",
      youHaveReadAllMinutesForDailyGoal = "/audio/Du har læst alt det du skulle i dag. Flot klaret_en.m4a",
      minute = "/audio/minut_en.m4a",
      minutes = "/audio/minutter_en.m4a",
      moreToCompeteYourDailyGoal = "/audio/mere for at nå dit daglige mål_en.m4a";
    // today = "/audio/Idag_en.m4a";

    const arr = [];

    if (userData?.level) {
      if (Math.floor(timeTracker / 60) >= userData?.level.minReadingPerDay) {
        arr.push(youHaveReadAllMinutesForDailyGoal);
      } else {
        arr.push(
          youShouldRead,
          `/audio/${userData?.level.minReadingPerDay}min_en.m4a`,
          `${userData?.level.minReadingPerDay !== 1 ? minutes : minute}`
        );

        if (Math.floor(todayReadingTime / 60) === 0 || Math.floor(todayReadingTime / 60)) {
          arr.push(
            youHaveRead,
            `/audio/${Math.floor(todayReadingTime / 60)}min_en.m4a`,
            Math.floor(todayReadingTime / 60) !== 1 ? minutes : minute,
            youMustRead,
            `/audio/${userData?.level.minReadingPerDay - Math.floor(todayReadingTime / 60)}min_en.m4a`,
            moreToCompeteYourDailyGoal
          );
        }
      }
    }
    setAudioFiles(arr);
  };

  const getTodayReadingTime = () => {
    const today = new Date();
    if (userData?.level) {
      const result = userData?.level.dailyReadings.find(
        (item) => new Date(item.date).toDateString() === today.toDateString()
      );

      if (result) {
        return result.minutes;
      }
    } else return 0;
  };

  useEffect(() => {
    setTimeTracker(getTodayReadingTime() * 60);
  }, [userData?.level]);

  useEffect(() => {
    const audioPlayer = document.getElementsByTagName("audio")[0];
    audioPlayer.addEventListener("ended", () => {
      if (currentAudioIndex < audioFiles.length - 1) {
        setCurrentAudioIndex(currentAudioIndex + 1);
      }
    });
  }, [currentAudioIndex, audioFiles]);

  const playNextAudio = () => {
    const audioPlayer = document.getElementsByTagName("audio")[0];
    audioPlayer.load();

    const handleCanPlay = () => {
      audioPlayer.play();
      audioPlayer.removeEventListener("canplaythrough", handleCanPlay);
    };

    audioPlayer.addEventListener("canplaythrough", handleCanPlay);
  };

  useEffect(() => {
    currentAudioIndex && playNextAudio();
  }, [currentAudioIndex]);

  useEffect(() => {
    if (audioFiles.length > 0 && currentAudioIndex === 0) {
      playNextAudio();
    }
  }, [audioFiles]);

  const convertSeconds = (seconds: number) => {
    if (seconds) {
      return (
        Math.floor(seconds / (60 * 60)) +
        "h : " +
        Math.floor((seconds / 60) % 60) +
        "m : " +
        Math.floor(seconds % 60) +
        "s"
      );
    } else return "0h : 0m : 0s";
  };

  return (
    <StyledTimer
      className={`${isCollapsed && screenWidth > 768 ? "collapsed" : ""}`}
      onClick={() => {
        combineAudioTracks(timeTracker);
      }}
      style={{ cursor: userData?.level ? "pointer" : "default" }}
    >
      <audio src={audioFiles[currentAudioIndex]} style={{ opacity: 0, visibility: "hidden" }} />
      {userData?.level && <span className="count">{userData?.level.minutesRemainingToday}</span>}
      <div className="image-wrapper">
        <img src={dragonImg} alt="dragon reading" />
      </div>
      <span className="timer">{convertSeconds(timeTracker)}</span>
    </StyledTimer>
  );
};

export default DragonTimer;
