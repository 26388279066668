import { all, take, put } from "redux-saga/effects";
import { GET_STUDENT_ASSIGNMENTS, getStudentAssignmentsActions } from "src/redux/actions/quiz";

// services
import { getStudentAssignments, getQuizDetails } from "src/services/quiz";
import { getBookList } from "src/services/book";

// types
import { GetStudentAssignmentResponseTypes, GetQuizDetailsResponseTypes } from "src/redux/actions/quiz/types";
import { GetBookListResponseTypes } from "src/redux/actions/book/types";

import * as Effects from "redux-saga/effects";

const call: any = Effects.call;

function* watchStudentAssignmentsRequest() {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const sortByPublishDateDescending = (data: any) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    data.sort((a, b) => {
      const dateA = new Date(a.publishDate);
      const dateB = new Date(b.publishDate);

      if (dateA > dateB) return -1;
      if (dateA < dateB) return 1;
      return 0;
    });
  };

  function* processItems(items: GetStudentAssignmentResponseTypes, assignmentsList: any) {
    const bookIds: number[] = items.map((item) => item.bookId).filter((value) => value !== undefined);
    const bookData: GetBookListResponseTypes = yield call(getBookList, {
      limit: bookIds.length,
      offset: 0,
      ids: bookIds
    });

    const bookQuizIds: number[] = items
      .map((item) => item.gameId)
      .filter((value): value is number => value !== undefined);

    const fetchQuizPromises: any[] = [];
    const bookQuizDataMapped: { [key: number]: GetQuizDetailsResponseTypes } = {};

    for (const quizId of bookQuizIds) {
      fetchQuizPromises.push(call(getQuizDetails, { quizId: quizId }));
    }

    const quizDataArray: GetQuizDetailsResponseTypes[] = yield all(fetchQuizPromises);
    quizDataArray.forEach((quizData, index) => {
      const quizId = bookQuizIds[index];
      bookQuizDataMapped[quizId] = quizData;
    });

    yield all(fetchQuizPromises);

    for (const item of items) {
      const book = bookData.books.find((book) => book.id === item.bookId);
      if (item.gameId) {
        assignmentsList.push({ ...item, quizData: bookQuizDataMapped[item.gameId], bookData: book });
      } else {
        assignmentsList.push({ ...item, bookData: book });
      }
    }
  }

  while (true) {
    const { payload } = yield take(GET_STUDENT_ASSIGNMENTS.REQUEST);

    try {
      const assignmentsList: any = [];
      const result: GetStudentAssignmentResponseTypes = yield call(getStudentAssignments, payload);

      if (result?.length === 0) {
        yield put(getStudentAssignmentsActions.success([]));
      } else {
        sortByPublishDateDescending(result);
        yield* processItems(result, assignmentsList);

        yield put(getStudentAssignmentsActions.success(assignmentsList));
      }
    } catch (e) {
      console.log(e, "error");
      yield put(getStudentAssignmentsActions.error(e as any));
    }
  }
}

export default watchStudentAssignmentsRequest;
