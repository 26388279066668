import React, { useState, useEffect } from "react";
import { ButtonSecondary, HeadingH1 } from "src/components";

import { StyledForm } from "../../../AuthForm.styles";
import { StyledError } from "src/components/ErrorMessage/ErrorMessage.styles";

import { RootReducer } from "../../../../../redux/reducers";
import { getUniloginUserActions } from "../../../../../redux/actions/nota";
import { useSelector, useDispatch } from "react-redux";

import { Formik, Form, ErrorMessage } from "formik";
import { useTranslation } from "react-i18next";

// helpers
import { monitorPopup, openLoginPopup, UniloginAuthMessageData } from "../../../../../helpers/unilogin";

interface Props {
  onNext: () => void;
}

const NotaUniloginStep: React.FC<Props> = ({ onNext }) => {
  const [t] = useTranslation();
  const [formError, setFormError] = useState<string | null>(null);

  const dispatch = useDispatch();
  const {
    nota: { uniloginUserResult }
  }: RootReducer = useSelector<RootReducer>((state) => state) as RootReducer;

  async function openUnilogin() {
    const authWindow = await openLoginPopup();
    if (!authWindow) {
      setFormError(t("unilogin_error_dialog_text"));
      return;
    }
    monitorPopup(authWindow, (result) => {
      if (result.data) {
        handleUniloginCallback(result.data);
      } else if (result.error) {
        console.error(result.error);
      }
    });
  }

  const handleUniloginCallback = (result: UniloginAuthMessageData) => {
    if (!result.session_state || !result.session_state || !result.session_state) {
      setFormError(t("unilogin_error_dialog_text"));
      return;
    }
    dispatch(
      getUniloginUserActions.request({
        session_state: result.session_state,
        code: result.code ?? "",
        state: result.state ?? ""
      })
    );
  };

  useEffect(() => {
    if (uniloginUserResult && uniloginUserResult.user && uniloginUserResult.lToken) {
      onNext();
    }
  }, [uniloginUserResult]);

  useEffect(() => {
    if (uniloginUserResult?.error) {
      setFormError(t(uniloginUserResult?.error));
    }
  }, [uniloginUserResult?.error]);

  return (
    <Formik initialValues={{}} onSubmit={openUnilogin}>
      <StyledForm>
        <Form onChange={() => setFormError(null)}>
          <div className="form-row-center">
            <HeadingH1 align="center" title={t("unilogin_title_header")} />
          </div>
          <div className="form-row-center">
            <p>{t("unilogin_description")}</p>
          </div>
          <div className="form-action">
            <div className="buttons-wrapper">
              <div className="login-buttons-wrapper">
                <ButtonSecondary type="submit" title={t("unilogin_button_title")} size="small" />
              </div>
              <ErrorMessage name="unilogin-error" component={StyledError} />
              {formError && <StyledError>{formError}</StyledError>}
            </div>
          </div>
        </Form>
      </StyledForm>
    </Formik>
  );
};

export default NotaUniloginStep;
