import { take, put, select } from "redux-saga/effects";
import { GET_BAG_ITEMS, getBagItemsActions } from "src/redux/actions/bag";

// services
import { getBagItems } from "src/services/bag";
import { getDragonsList } from "src/services/dragon";
import { RootReducer } from "src/redux/reducers";

// types
import { BagItemTypes, BagListResponseTypes } from "src/redux/actions/bag/types";
import { ShopItemTypes } from "src/redux/actions/shop/types";
import { DragonItemTypes, GetDragonsListResponseTypes } from "src/redux/actions/dragon/types";

import * as Effects from "redux-saga/effects";
const call: any = Effects.call;

function* watchGetBagItemsRequest() {
  while (true) {
    const { payload }: IActionType<{ userId: string }> = yield take(GET_BAG_ITEMS.REQUEST);

    try {
      const bagItems: BagListResponseTypes = yield call(getBagItems, payload);
      const { shopItems } = yield select((state: RootReducer) => state?.shop);

      const result: BagItemTypes[] = [];

      const getFullBagItems = () => {
        bagItems &&
          Array.isArray(bagItems.items) &&
          bagItems.items.map(({ itemId, transactionId }) => {
            shopItems?.map(function (shopItem: ShopItemTypes) {
              if (shopItem.id === itemId) {
                result.push({ transactionId, ...shopItem });
              }
            });
          });
      };
      getFullBagItems();

      const dressedArr: { id: number; transactionId: string }[] = [];

      // get dragons list to check dressed items
      const dragonList: GetDragonsListResponseTypes = yield call(getDragonsList, {
        userId: Number(payload.userId)
      });
      dragonList?.map(({ dressedItems }: DragonItemTypes) => {
        if (dressedItems.length) {
          dressedArr.push(...dressedItems);
        }
      });

      yield put(getBagItemsActions.success(result));
    } catch (e) {
      yield put(getBagItemsActions.error(e as any));
    }
  }
}

export default watchGetBagItemsRequest;
